//  WE HAVE  Created that File seperate Because in Globally Chat File we have putted many Conditions 
import { useEffect, useRef, useState } from 'react';
import './recruiterchat.scss';
import axios from 'axios';
import { ConnectSocket, SocketURL } from '../../../components/global/SocketNetwork/ConnectSocket';
import environment from '../../../environment';
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import ApiClient from "../../../methods/api/apiClient";

const CompleteChatbox = ({ open, setopen }) => {
  const [Typing, setTyping] = useState(false);
  const [ChatWithUser, setChatWithUser] = useState({})
  const user = useSelector(state => state.user)
  const RoomId = useRef("");
  const [isHelpBoxOpen, setHelpBoxOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const CurrenMessages = useRef([])
  const [newMessage, setNewMessage] = useState('');
  const parsedlocal = JSON.parse(localStorage.getItem("persist:admin-app"));
  const userlocal = JSON.parse(parsedlocal.user);
  const [SearchData, setSearchData] = useState("");
  const [ChatWithEmail, setChatWithEmail] = useState("");
  const [UserOnline, setUserOnline] = useState(false);
  const ChatId = useRef("");
  const ChatWithId = useRef("");




  const toggleHelpBox = () => {
    setHelpBoxOpen(open);
  };
  const bootemel = useRef();
  const scrollToBottom = () => {
    bootemel?.current?.scrollIntoView({ behavior: 'smooth' });

  };
  useEffect(() => {

    toggleHelpBox()
  }, [open])
  const [ChatLoad, setChatLoad] = useState(false);


  const HandleSearchSubmit = (e) => {
    e.preventDefault();
    GetActivePuposals({ search: SearchData });
  };


  const HandleChatSelect = (id, email, onlineStatus) => {
    setChatLoad(true);
    ChatWithId.current = id;
    axios
      .post(SocketURL + "chat/user/join-group", {
        chat_by: user.id,
        chat_with: id,
      })
      .then((newres) => {
        const res = newres.data;
        if (res.success) {
          const data = res.data;
          RoomId.current = data.room_id;
          localStorage.setItem("RoomidWithCall", data.room_id);
          localStorage.setItem("meetingwith", id);
          GetAllMessages(data.room_id);
          setUserOnline(onlineStatus);
          ConnectSocket.emit(`join-room`, {
            room_id: data.room_id,
            user_id: user.id,
          });
        }
      });
    setChatWithEmail(email);
    ChatId.current = id
  };


  const HandleChatIdmatch = (item) => {
    const friendId = item?.friend && item?.friend?._id;
    const newchatid = friendId
    if (ChatId.current == newchatid) {
      return true;
    } else {
      return false;
    }
  };

  const FindUserOnline = (item) => {
    const Response = item?.friend && item.friend?.isOnline;
    return Response;
  };

  const [Activeperson, setActivePerson] = useState([]);

  const GetActivePuposals = (p = {},) => {
    const filter = {
      addedBy: userlocal.id,
    };
    const newfilter = { ...filter, ...p };
    ApiClient.get(`friendslist`, newfilter).then((res) => {
      setActivePerson(res.data);
      // setsort(res?.data?.isSortList)
      // if (type == "comeby") {
      //   try {
      //     document.getElementById(Searchuserid).click();
      //   } catch { }
      // }
      // loader(false);
    });
  };

  useEffect(() => {

    GetActivePuposals({});
  }, []);

  // const CreateRoom = () => {
  //   axios.post(SocketURL + "chat/user/join-group", { "chat_by": user.id, "chat_with": ChatWithId.current }).then(newres => {
  //     const res = newres.data;
  //     if (res.success) {
  //       const data = res.data;
  //       RoomId.current = data.room_id
  //       GetAllMessages(data.room_id)
  //       ConnectSocket.emit(`join-room`, { "room_id": data.room_id, "user_id": user.id })
  //     }
  //   })
  // }
  // useEffect(() => {
  //   CreateRoom();
  // }, [])
  const GetAllMessages = (roomid) => {
    setChatLoad(true);
    axios.get(`${SocketURL}chat/user/message/all?room_id=${roomid}&user_id=${user.id}&login_user_id=${ChatWithId.current}`).then(newres => {
      const res = newres.data
      if (res.success) {
        const data = res.data && res.data.data;
        data.map((item, index) => {
          if (item.isRead == false && item.sender != user.id) {
            ConnectSocket.emit(`read-message`, { "message_id": item._id })
          }
        })
        const MessageData = res.data && res.data.data
        CurrenMessages.current = MessageData
        setMessages(MessageData)
      }
      setChatLoad(false)
    }).catch(err => {
      toast.error("Error Occurred")
    })
  }




  const [EmptyMessageError, setEmptyMessageError] = useState(false)
  const MessageSubmit = (e) => {
    e.preventDefault();
    if (newMessage == "") {
      setEmptyMessageError(true); setTimeout(() => {
        setEmptyMessageError(false)
      }, 2000);
      return false
    }
    const Messages = messages;
    const SendMessagepayload = {
      "room_id": RoomId.current,
      "type": "TEXT",
      sender: user.id,
      "content": newMessage,
    }
    ConnectSocket.emit(`send-message`, SendMessagepayload)
    scrollToBottom()
    setNewMessage('');
  }




  useEffect(() => {
    ConnectSocket.on(`receive-message`, (newdata) => {
      const data = newdata.data;
      if (data?.room_id == RoomId.current) {
        if (data.sender != user.id) {
          ConnectSocket.emit(`read-message`, { "message_id": data._id })
        }

        const payload = { user_id: user.id, room_id: RoomId.current }
        const newmessages = [...CurrenMessages.current, { _id: data._id, content: data.content, sender: data.sender, type: data.type, media: data.media, createdAt: data.createdAt, isRead: false, message_type: data?.message_type, }]
        ConnectSocket.emit(`unread-count`, payload);
        // AllMessages.current=[...AllMessages.current,{_id:data._id,content:data.content,sender:data.sender,type:data.type,media:data.media,createdAt:data.createdAt,isRead:false,message_type:data?.message_type,inviteId:data?.inviteId}]
        CurrenMessages.current = newmessages
        setMessages(previousmessa => ([...previousmessa, { _id: data._id, content: data.content, sender: data.sender, type: data.type, media: data.media, createdAt: data.createdAt, isRead: false, message_type: data?.message_type, }]))
        scrollToBottom()
      }
    })


    ConnectSocket.on(`read-message`, (data) => {
      const newdata = data.data;
      const messageid = newdata.message_id;
      const Array = CurrenMessages.current;
      Array.filter((item, index) => {
        if (item._id == messageid) {
          Array[index]["isRead"] = true
        }
      })
      CurrenMessages.current = Array
      setMessages(Array)

    })

    //  For Typing Listening
    ConnectSocket.on(`typing`, (data) => {
      const newdata = data.data;
      if (newdata.user_id == ChatWithId.current) {
        setTyping(newdata.typing)
      }
    })

    //  Delete Message Listen
    ConnectSocket.on(`delete-message`, (data) => {
      const newdata = data.data;
      const messageid = newdata.message_id;
      const Array = CurrenMessages.current;
      if (newdata.room_id == RoomId.current) {
        CurrenMessages.current = Array.map((item, index) => item._id == messageid ? previous[index]["delete_type"] = newdata.type : previou)

        setMessages(previous => previous.map(item, index => item._id == messageid ? previous[index]["delete_type"] = newdata.type : previous))
        // setMessages(Array)  
      }
    })
  }, [ConnectSocket])

  return (
    <div className="home-page__content messages-page h-auto">
      <div className="container-fluid">

        <div className="row px-0">
          <div className="col-md-4">
            <div className="messages-page__list-scroll">
              <div className="messages-page__search mb-0  pb-3">
                <form onSubmit={(e) => HandleSearchSubmit(e)}>
                  <div className="custom-form__search-wrapper">
                    <input
                      type="text"
                      className="form-control custom-form"
                      id="search"
                      value={SearchData}
                      onChange={(e) => {
                        setSearchData(e.target.value);
                        e.target.value == ""
                          ? GetActivePuposals({ search: "" })
                          : null;
                      }}
                      placeholder="Search..."
                      autoComplete="off"
                    />
                    <button
                      type="submit"
                      className="custom-form__search-submit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon svg-icon--search"
                        viewBox="0 0 46.6 46.6"
                      >
                        <path
                          d="M46.1,43.2l-9-8.9a20.9,20.9,0,1,0-2.8,2.8l8.9,9a1.9,1.9,0,0,0,1.4.5,2,2,0,0,0,1.5-.5A2.3,2.3,0,0,0,46.1,43.2ZM4,21a17,17,0,1,1,33.9,0A17.1,17.1,0,0,1,33,32.9h-.1A17,17,0,0,1,4,21Z"
                          fill="#f68b3c"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>

              <ul className="messages-page__list ">
                {/* //   */}
                {Activeperson?.map((item, index) => (
                  <li
                    className={`messaging-member messaging-member--${FindUserOnline(item) ? "online" : "offline"
                      }
           ${HandleChatIdmatch(item) ? "messaging-member--active" : ""}`}
                    id={
                      item.friend &&
                      item.friend._id
                    }
                    onClick={(e) => {
                      HandleChatSelect(
                        item.friend && item.friend._id,
                        item?.friend?.email,
                        item?.friend?.isOnline,
                      );
                      setChatWithUser(item.friend);

                    }}
                  >
                    <div className="messaging-member__wrapper">
                      <div className="messaging-member__avatar">
                        <img
                          src={methodModel.userImg(
                            item.friend && item.friend.image
                          )}
                          alt="Jenny Smith"
                          loading="lazy"
                        />
                      </div>

                      <span className="messaging-member__name">
                        {item.friend && item?.friend?.fullName || ""}

                      </span>
                    </div>
                  </li>
                ))}
                {Activeperson?.length == 0 ? (
                  <div className="text-center text-danger">
                    <p>No Data</p>
                  </div>
                ) : null}
              </ul>
            </div>
          </div>
          {true ? !ChatLoad ? <>
            <div className="chat  col-md-8  pl-md-1">
              <div className="chat__container">
                <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                  <div className={`chat__messaging messaging-member--${true ? "online" : "offline"} pb-2 pb-md-2 pl-2 pl-md-4 pr-2`}>
                    <div className="chat__previous d-flex d-md-none">
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--previous" viewBox="0 0 45.5 30.4">
                        <path d="M43.5,13.1H7l9.7-9.6A2.1,2.1,0,1,0,13.8.6L.9,13.5h0L.3,14v.6c0,.1-.1.1-.1.2v.4a2,2,0,0,0,.6,1.5l.3.3L13.8,29.8a2.1,2.1,0,1,0,2.9-2.9L7,17.2H43.5a2,2,0,0,0,2-2A2.1,2.1,0,0,0,43.5,13.1Z" fill="#f68b3c" />
                      </svg>
                    </div>

                    <div className="chat__infos pl-2 pl-md-0">
                      <div className="chat-member__wrapper" data-online="true" >
                        <div className="chat-member__avatar" >
                          <img src={methodModel?.userImg(ChatWithUser?.image)} alt="Jenny Smith" loading="lazy" />
                          <div className={`${UserOnline ? "user-status user-status--large" : ""}`}></div>
                        </div>
                        <div className="chat-member__details">
                          <span className="chat-member__name">{ChatWithUser?.fullName}</span>
                          <span className="chat-member__status">{UserOnline ? "Online" : ""}</span>
                        </div>
                      </div>
                    </div>
                    <div className="chat__actions mr-2 ">
                      <ul className="m-0">
                        <li className="chat__details d-flex d-xl-none">
                          <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 42.2 11.1">
                            <g>
                              <circle cx="5.6" cy="5.6" r="5.6" fill="#d87232" />
                              <circle cx="21.1" cy="5.6" r="5.6" fill="#d87232" />
                              <circle cx="36.6" cy="5.6" r="5.6" fill="#d87232" />
                            </g>
                          </svg>
                        </li>
                      </ul>

                    </div>
                  </div>
                  <div className="chat__content pt-4 px-3">
                    <ul className="chat__list-messages">
                      <li>
                        {CurrenMessages.current?.map((item, index) => {
                          if (item.delete_type && item.delete_type == "delete_for_me" && item.sender == user.id) { }
                          else {
                            return item.delete_type && item.delete_type == "delete_for_everyone" ? null : <>
                              <div className={`chat__bubble chat__bubble--${item.sender == user.id ? "me" : "you"}`}>
                                <div className="dropdown deleteIcon">
                                  <button class={`btn btn-secondary dropdown-toggle d-${item.sender == user.id ? "" : "none"} `} type="button" id="chatToogle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {/* <span class='material-icons'>more_vert</span> */}
                                  </button>
                                  <div className="dropdown-menu" aria-labelledby="chatToogle">
                                    {/* <a className="dropdown-item" onClick={e=>HandleDeleteMessage(e,"delete_for_me",item._id)}>Delete For Me</a> */}
                                    <a className="dropdown-item" onClick={e => HandleDeleteMessage(e, "delete_for_everyone", item._id)}>Delete</a>
                                    {/* <a className="dropdown-item" href="#">Something else here</a> */}
                                  </div>
                                </div>
                                {item.type == "IMAGE" ? item.media && item.media.map((item) => (<ImageView width={150} height={100} src={`${environment.api}images/document/${item}`} />)) : item.content}
                              </div>
                            </>
                          }
                        })}

                        {CurrenMessages.current && CurrenMessages.current?.length == 0 ? <div className="text-center justify-content-center justify-content-lg-start space-left2 mt-5"><p>No Messages yet</p></div> : null}

                      </li>
                      <div ref={bootemel} className="container mt-3"></div>
                    </ul>
                  </div>

                  {Typing == true ? <p className="ml-3 text-center typing">Typing <img className='typingGif' src="/assets/img/typing.gif" /></p> : null}
                  {/* Emojis are here  */}
                  {/* <input type="file" accept="image/*" onChange={e => HandleImageChange(e)} ref={OpenFileUpload} className="d-none" /> */}
                  <form onSubmit={e => MessageSubmit(e)}>
                    <div className="chat__send-container px-2 px-md-3 pt-1 pt-md-1">
                      <div className="custom-form__send-wrapper">
                        <input type="text" className={`form-control custom-form  ${EmptyMessageError ? "error" : ""}`} onBlur={e => ConnectSocket.emit(`typing`, { typing: false })} value={newMessage} onChange={e => { setNewMessage(e.target.value); ConnectSocket.emit(`typing`, { typing: true }) }} id="myTextInput" placeholder="Type your message…" autoComplete="off" />
                        {/* <div className="custom-form__send-img" onClick={e => OpenFileUpload.current.click()}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send-img" viewBox="0 0 45.7 45.7">
                                <path d="M6.6,45.7A6.7,6.7,0,0,1,0,39.1V6.6A6.7,6.7,0,0,1,6.6,0H39.1a6.7,6.7,0,0,1,6.6,6.6V39.1h0a6.7,6.7,0,0,1-6.6,6.6ZM39,4H6.6A2.6,2.6,0,0,0,4,6.6V39.1a2.6,2.6,0,0,0,2.6,2.6H39.1a2.6,2.6,0,0,0,2.6-2.6V6.6A2.7,2.7,0,0,0,39,4Zm4.7,35.1Zm-4.6-.4H6.6a2.1,2.1,0,0,1-1.8-1.1,2,2,0,0,1,.3-2.1l8.1-10.4a1.8,1.8,0,0,1,1.5-.8,2.4,2.4,0,0,1,1.6.7l4.2,5.1,6.6-8.5a1.8,1.8,0,0,1,1.6-.8,1.8,1.8,0,0,1,1.5.8L40.7,35.5a2,2,0,0,1,.1,2.1A1.8,1.8,0,0,1,39.1,38.7Zm-17.2-4H35.1l-6.5-8.6-6.5,8.4C22,34.6,22,34.7,21.9,34.7Zm-11.2,0H19l-4.2-5.1Z" fill="#f68b3c" />
                              </svg>
                            </div> */}
                        {/* <div className="custom-form__send-emoji" onClick={e => setShowEmoji(!ShowEmoji)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send-emoji" viewBox="0 0 46.2 46.2">
                                <path d="M23.1,0A23.1,23.1,0,1,0,46.2,23.1,23.1,23.1,0,0,0,23.1,0Zm0,41.6A18.5,18.5,0,1,1,41.6,23.1,18.5,18.5,0,0,1,23.1,41.6Zm8.1-20.8a3.5,3.5,0,0,0,3.5-3.5,3.5,3.5,0,0,0-7,0,3.5,3.5,0,0,0,3.5,3.5ZM15,20.8a3.5,3.5,0,0,0,3.5-3.5A3.5,3.5,0,0,0,15,13.9a3.4,3.4,0,0,0-3.4,3.4A3.5,3.5,0,0,0,15,20.8Zm8.1,15a12.6,12.6,0,0,0,10.5-5.5,1.7,1.7,0,0,0-1.3-2.6H14a1.7,1.7,0,0,0-1.4,2.6A12.9,12.9,0,0,0,23.1,35.8Z" fill="#f68b3c" />
                              </svg>
                            </div> */}
                        {/* {ShowEmoji ?
                              <ul className="dropdown-menu d-block emoji_drop">
                                <li>
                                  <div>
                                    <EmojiPicker
                                      onEmojiClick={(e) => { setMessageText((inputValue) => inputValue + (e.emoji)); document.getElementById("myTextInput").focus(); }}
                                      autoFocusSearch={false}
                                      emojiStyle={EmojiStyle.NATIVE}
                                    />
                                  </div>
                                </li>
                              </ul> : null} */}
                        <button type="submit" className="custom-form__send-submit">
                          <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send" viewBox="0 0 45.6 45.6">
                            <g>
                              <path d="M20.7,26.7a1.4,1.4,0,0,1-1.2-.6,1.6,1.6,0,0,1,0-2.4L42.6.5a1.8,1.8,0,0,1,2.5,0,1.8,1.8,0,0,1,0,2.5L21.9,26.1A1.6,1.6,0,0,1,20.7,26.7Z" fill="#d87232" />
                              <path d="M29.1,45.6a1.8,1.8,0,0,1-1.6-1L19.4,26.2,1,18.1a1.9,1.9,0,0,1-1-1.7,1.8,1.8,0,0,1,1.2-1.6L43.3.1a1.7,1.7,0,0,1,1.8.4,1.7,1.7,0,0,1,.4,1.8L30.8,44.4a1.8,1.8,0,0,1-1.6,1.2ZM6.5,16.7l14.9,6.6a2,2,0,0,1,.9.9l6.6,14.9L41,4.6Z" fill="#d87232" />
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>

            {/* {ProfileView? */}
          </> : <img className="col-md-9  col-xl-9 px-0 " src="assets/img/fakeani.gif" /> : <div className="chat col-12 col-md-8 col-lg-7 col-xl-6 px-0 pl-md-1">
            <div className="chat__container">
              <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                <img src="/assets/img/chat.jpg" className="chat_img" />
              </div>
            </div>
          </div>}
          {/* :null} */}
        </div>
      </div>
    </div>
  );
};

export default CompleteChatbox;
