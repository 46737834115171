import { useRef, useState } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import { login_success } from '../../actions/user';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import methodModel from '../../methods/methods';
import GooglePlaceAutoComplete from '../../components/common/GooglePlaceAutoComplete';
import addressModel from '../../models/address.model';
import moment from 'moment';
import environment from '../../environment';
import CompanyDetail from '.';




const Html = ({

}) => {
  const [submitted, setSubmitted] = useState(false);
  const OpenImage = useRef();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [CompanyMobileNo, setCompanyMobileNO] = useState(user.companyMobileNo)
  const Documentref = useRef()
  const [images, setimages] = useState([]);
  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);



  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images];
    Documentref.current.value = ""
    array.splice(index, 1);
    setimages(array);
  };

  const HandleDocumentSubmit = (e) => {
    e.preventDefault()
    if (images.length != 0) {
      loader(true);
      ApiClient.put('edit/profile', { document: images, id: user.id }).then(res => {
        if (res.success) {
          const data = { ...user, document: images };
          dispatch(login_success(data));
          document.getElementById("closeDocumnet").click()
          toast.success("Documents Submitted Successfully");
        }
        loader(false)
      })
    } else {
      toast.error("Please Upload Documents")
    }
  }

  const imageResult = (e) => {
    if (e.target.files.length > 0) {

      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        setDocumentUploadLoading(true);
        ApiClient.multiImageUpload("single/documents?modelName=users", {
          file: file,
        }).then((res) => {
          if (res.success) {
            setDocumentUploadLoading(false)
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            setdocuments(new Date())
            // if (files.length == images.length) {
            //   setDocumentUploadLoading(false)
            // }
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;

      }
      // setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };

  const uploadImage = (e) => {
    let files = e.target.files
    let file = files.item(0)
    if (file) {
      loader(true)
    }
    ApiClient.postFormData('upload/image?modelName=users', { file: file, modelName: 'users' }).then(res => {

      if (res.success) {
        let image = res.data.fullpath
        ApiClient.put('edit/profile', { companyImage: image, id: user.id }).then(res => {
          if (res.success) {
            toast.success("Image uploaded Successfully");
            const dispatchdata = { ...user, companyImage: image }
            dispatch(login_success(dispatchdata))
          }
        })
      } else {
      }
      loader(false)
    })
  }
  const [AddressSellected, setAddressSellected] = useState(true)
  const addressResult = async (e) => {
    setSubmitted(false)
    let address = {}
    if (e.place) {
      address = addressModel.getAddress(e.place)
      setAddressSellected(true)
    } else {
      setAddressSellected(false)
    }
    setForm({
      ...form,
      companyAddress: e.value,
    })
    if (e.place) {
      const apires = await addressModel.gettimeZone(e.place)
      setForm({
        ...form,
        companyAddress: e.value,
      })
    }
  }
  const [form, setForm] = useState({ companyAddress: user.companyAddress })
  const [AddEditCompanydetail, setAddEditCompanyDetail] = useState(false)

  const AddEditCompanyDetail = () => {
    loader(true)
    setAddEditCompanyDetail(true)
    setTimeout(() => {
      loader(false)
    }, 500);
  }
  return (
    <>


      <div className="right_profile  border-bottom mt-3">
        <div className=" p-3 d-flex justify-content-between">
          <h3 className="mainhedding">Company Details</h3>
        </div>

        {user.companyName && user.companyName == "" ? null : <><div className="img_div p-3">
          <img src={methodModel.userImg(user?.companyImage, "CompanyDetails")} className="companyimg " />
          <div className='companyimg_edit' ><i onClick={e => document.getElementById("OpenFiles").click()} className="material-icons">mode_edit</i></div>


          <input type="file" id='OpenFiles' className="img_choose d-none" accept="image/*" onChange={(e) => { uploadImage(e); }} />


        </div>



          <div className="border-top p-3">
            <div className='float-right'>
              {user.companyName == "" && AddEditCompanydetail === false ? <i className="material-icons new_icons" onClick={e => setAddEditCompanyDetail(true)} title='Add'>add_circle </i> : AddEditCompanydetail === false ? <i className="material-icons new_icons" onClick={e => AddEditCompanyDetail()} title='Edit' >mode_edit</i> : ""}
            </div>

            <div className="">
              <Formik
                enableReinitialize
                initialValues={{
                  companyName: user.companyName,
                  companyWebsite: user.companyWebsite,
                  companyDescription: user.companyDescription,
                  companyStartDate: user.companyStartDate,
                  // companyAddress:form.companyAddress,
                  contactName: user.contactName,
                  contactEmail: user.contactEmail,
                  companyMobileNo: user.companyMobileNo
                }}
                onSubmit={(values) => {
                  setSubmitted(true);
                  if (form.companyAddress == "") return false
                  const payload = { id: user.id, companyName: values.companyName, companyWebsite: values.companyWebsite, companyDescription: values.companyDescription, companyStartDate: values.companyStartDate, companyAddress: form.companyAddress, contactName: values.contactName, contactEmail: values.contactEmail, companyMobileNo: CompanyMobileNo }
                  loader(true);
                  ApiClient.put(`edit/profile`, payload).then(res => {
                    if (res.success) {
                      loader(false)
                      setAddEditCompanyDetail(false)
                      // document.getElementById("closecompanymodal").click();
                      toast.success("Company Details Updated Successfully")
                      const data = { ...user, ...payload }
                      dispatch(login_success(data))
                    }
                  })
                }}
              >
                {({ values, handleBlur, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    {AddEditCompanydetail === true ? <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Company Name<span className='text-danger'>*</span></label>
                        <input name='companyName' type='text' required className='form-control' value={values.companyName} onChange={handleChange} />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Website</label>
                        <input name='companyWebsite' type='text' className='form-control' value={values.companyWebsite} onChange={handleChange} />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Start Date<span className='text-danger'>*</span></label>
                        <input name='companyStartDate' required max={moment(new Date()).format("YYYY-MM-DD")} type='date' className='form-control' value={values.companyStartDate} onChange={handleChange} />
                      </div>

                      <div className='col-md-6 mb-3'>
                        <label className='' >Owner Name<span className='text-danger'>*</span></label>
                        <input name='contactName' className='form-control' required type='text' value={values.contactName} onChange={handleChange} />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Email </label>
                        <input name='contactEmail' type='email' className='form-control' value={values.contactEmail} onChange={handleChange} />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Contact Number </label>
                        <div className='up_mob'>
                          <PhoneInput
                            country={'us'}
                            value={CompanyMobileNo}
                            onChange={e => setCompanyMobileNO(e)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className='col-md-12 mb-3'>
                        <label className='' >Address<span className='text-danger'>*</span></label>
                        <GooglePlaceAutoComplete
                          value={form.companyAddress}
                          result={addressResult}
                          id="address"
                          placeholder=""
                        />
                        {submitted && form.companyAddress == "" ? <p className='text-danger text-center'>Address is Required</p> : null}
                      </div>
                      <div className='col-md-12 mb-3'>
                        <label className='' >Description</label>
                        <textarea name='companyDescription' className='form-control' rows={4} type='text' value={values.companyDescription} onChange={handleChange} />
                      </div>
                    </div> :
                      <div >
                      <div className='row'>
                          <div className='col-md-6 mb-3'>
                            <label className='mainhedding' >Company Name</label>
                            <p>{values.companyName || "---"}</p>
                          </div>
                          <div className='col-md-6 mb-3'>
                            <label className='mainhedding' >Website</label>
                            <p>{values.companyWebsite || "---"}</p>
                          </div>
                          <div className='col-md-6 mb-3'>
                            <label className='mainhedding' >Start Date</label>
                            <p>{values.companyStartDate || "---"}</p>
                          </div>

                          <div className='col-md-6 mb-3'>
                            <label className='mainhedding' >Owner Name</label>
                            <p>{values.contactName || "---"}</p>
                          </div>
                          <div className='col-md-6 mb-3'>
                            <label className='mainhedding' >Email </label>
                            <p>{values.contactEmail || "---"}</p>
                          </div>
                          <div className='col-md-6 mb-3'>
                            <label className='mainhedding' >Contact Number </label>
                            <div className='up_mob'>

                              <p>{CompanyMobileNo || "---"}</p>
                            </div>
                          </div>
                          <div className='col-md-12 mb-3'>
                            <label className='mainhedding' >Address</label>

                            <p>{form.companyAddress || "---"}</p>
                          </div>
                          <div className='col-md-12 mb-3'>
                            <label className='mainhedding' >Description</label>
                            <p>{values.companyDescription || "---"}</p>
                          </div>
                        </div>
                      </div>}

                    {AddEditCompanydetail === true ? <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={e => setAddEditCompanyDetail(false)}>Close</button>
                      <button type="submit" className="btn btn-primary">Save changes</button>
                    </div> : null}
                  </form>
                )}
              </Formik>
            </div>
            {/* {user.companyName == "" ? null : <>   <label className="lablecls">{user?.companyName}</label>
              <p className="greenlink">{user?.companyWebsite}</p></>}

            {user.companyStartDate == "" ? null : <>
              <label className="lablecls">Start Date</label>
              <p className="greenlink">{user.companyStartDate == "" ? "----" : moment(user?.companyStartDate).format("MM/DD/YYYY")}</p></>}

            {user.companyDescription != "" ? <><label className="lablecls">Description</label>
              <p className="greenlink">{user?.companyDescription}</p></> : null} */}

          </div>
        </>}
      </div>


      {user.companyName && user.companyName == "" ? null : <div className="right_profile  border-bottom mt-3">
        <div className=" p-3">
          <div className='border-bottom d-flex justify-content-between'>
            <h3 className="mainhedding"> Upload your ID</h3>
          </div>
          <div className='mt-3'>
            {user.role && user.role.id == environment.EmployerId ? <><div className="education_name">
              <div className="d-flex">
                <div className="mb-2">
                  <h5 className="education_heading">
                    <span className="span_changes">Upload ID</span>
                  </h5>
                </div>
                <div className="d-flex mx-4 ">
                  <div
                    className="add_icon"
                    data-toggle="modal"
                    data-target="#documents12"
                  >
                    <i className="material-icons new_icons">mode_edit</i>
                  </div>
                </div>
              </div>
            </div>
              <div className="d-flex">
                {user.document && user.document.map((item, index) => (
                  <div key={item} className="d-flex">
                    <p className="text-capitalize">
                      <img
                        style={{ cursor: "pointer" }}

                        className="document_image w-100 img-new-u"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                        onClick={(e) =>
                          window.open(
                            `${environment.api}images/users/${item}`
                          )
                        }
                      />

                    </p>
                  </div>
                ))}
              </div></>
              : ""}
          </div>
        </div>

        {/* <div className=" border-bottom p-3">
          {user.contactName == "" ? null : <> <label className="lablecls">Owner</label>
            <p className="greenlink">{user?.contactName != "" ? user?.contactName : "----"}</p></>}

          {user.contactEmail == "" ? null : <> <label className="lablecls">Email</label>
            <p className="greenlink">{user?.contactEmail != "" ? user?.contactEmail : "-----"}</p></>}

          {user.companyMobileNo == "" ? null : <><label className="lablecls">Contact Number</label>
            <p className="greenlink">{user?.companyMobileNo != "" ? user?.companyMobileNo : "------"}</p></>}

          {user.companyAddress == "" ? null : <><label className='lablecls'>Address</label>
            <p className='greenlink'>{user?.companyAddress != "" ? user?.companyAddress : "-----"}</p></>}



        </div> */}
      </div>}

      {/* <div className="modal fade" id="EditComapy" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Company</h5>
              <button type="button" className="close" id='closecompanymodal' data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Formik
                enableReinitialize
                initialValues={{
                  companyName: user.companyName,
                  companyWebsite: user.companyWebsite,
                  companyDescription: user.companyDescription,
                  companyStartDate: user.companyStartDate,
                  // companyAddress:form.companyAddress,
                  contactName: user.contactName,
                  contactEmail: user.contactEmail,
                  companyMobileNo: user.companyMobileNo
                }}
                onSubmit={(values) => {
                  setSubmitted(true);
                  if (form.companyAddress == "") return false
                  const payload = { id: user.id, companyName: values.companyName, companyWebsite: values.companyWebsite, companyDescription: values.companyDescription, companyStartDate: values.companyStartDate, companyAddress: form.companyAddress, contactName: values.contactName, contactEmail: values.contactEmail, companyMobileNo: CompanyMobileNo }
                  loader(true);
                  ApiClient.put(`edit/profile`, payload).then(res => {
                    if (res.success) {
                      document.getElementById("closecompanymodal").click();
                      toast.success("Company Details Updated Successfully")
                      const data = { ...user, ...payload }
                      dispatch(login_success(data))
                      loader(false)
                    }
                  })
                }}
              >
                {({ values, handleBlur, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Company Name<span className='text-danger'>*</span></label>
                        <input name='companyName' type='text' required className='form-control' value={values.companyName} onChange={handleChange} />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Website</label>
                        <input name='companyWebsite' type='text' className='form-control' value={values.companyWebsite} onChange={handleChange} />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Start Date<span className='text-danger'>*</span></label>
                        <input name='companyStartDate' required max={moment(new Date()).format("YYYY-MM-DD")} type='date' className='form-control' value={values.companyStartDate} onChange={handleChange} />
                      </div>

                      <div className='col-md-6 mb-3'>
                        <label className='' >Owner Name<span className='text-danger'>*</span></label>
                        <input name='contactName' className='form-control' required type='text' value={values.contactName} onChange={handleChange} />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Email </label>
                        <input name='contactEmail' type='email' className='form-control' value={values.contactEmail} onChange={handleChange} />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className='' >Contact Number </label>
                        <div className='up_mob'>
                          <PhoneInput
                            country={'us'}
                            value={CompanyMobileNo}
                            onChange={e => setCompanyMobileNO(e)}
                            className='form-control'
                            maxLength="13"
                          />
                        </div>
                      </div>
                      <div className='col-md-12 mb-3'>
                        <label className='' >Address<span className='text-danger'>*</span></label>
                        <GooglePlaceAutoComplete
                          value={form.companyAddress}
                          result={addressResult}
                          id="address"
                          placeholder=""
                        />
                        {submitted && form.companyAddress == "" ? <p className='text-danger text-center'>Address is Required</p> : null}
                      </div>
                      <div className='col-md-12 mb-3'>
                        <label className='' >Description</label>
                        <textarea name='companyDescription' className='form-control' rows={4} type='text' value={values.companyDescription} onChange={handleChange} />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary">Save changes</button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="modal profilefadea fade"
        id="documents12"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ResumemodalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0  d-flex">
              <h5 className="modal-title" id="PortfoliomodalLabel">Upload ID</h5>
              <button
                type="button"
                id="closeDocumnet"
                className="close  "
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="">
                  {/* <label>Company Document <span className="text-danger">*</span></label> */}
                  <div className={`profile_btn_portfolio `}>
                    <label className="add_portfolio edit ml-3">
                      <input
                        ref={Documentref}
                        id="bannerImage"
                        type="file"
                        multiple={true}
                        className="d-none"
                        // accept="image/*"
                        onChange={(e) => imageResult(e)}
                      />
                      <span className="add_portfolio d-flex align-items-center jusity-content-center">
                        <i className="material-icons add_port">add</i>
                      </span>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="mt-1 text-right">
                    <p className='exmp' >Example: Passport, DL, ID proof</p>
                  </div>

                  <div className="imagesRow">
                    {DoumentUploadLoading == true ? (
                      <div className="text-success mx-auto text-center ">
                        Uploading...{" "}
                        <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      images?.map((item, index) => (
                        <div key={index}>
                          <p className="text-capitalize deleteptag position-relative  mb-0 ">
                            <img
                              style={{ cursor: "pointer" }}
                              width={40}
                              className="document_image"
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                              onClick={(e) =>
                                window.open(
                                  `${environment.api}images/users/${item}`
                                )
                              }
                            />
                          

                            <i
                              className="fa fa-times text-danger  shadow-danger delet_icon"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                HanldDocumentDelete(e, index)
                              }
                            ></i>
                          </p>
                        </div>
                      ))
                    )}
                  </div>
                </div>

              </div>
              <div className="text-center mt-4 ">
                <button className="btn btn-primary" onClick={e => HandleDocumentSubmit(e)}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
