import { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./home.scss";
import { useDispatch, useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import Header from "../../components/global/header";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import parse from "html-react-parser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import { login_success } from "../../actions/user";
import environment from "../../environment";
import Slider from "react-slick";
import moment from "moment";
import Footer from "./Footer";
const Home = () => {
  const [ViewProjectData, setViewProjectData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const routes = useLocation();
  const pathname = routes.pathname;
  const user = useSelector((state) => state.user);
  const [JobData, setJobData] = useState([]);

  const [data, setData] = useState([]);
  const [testodata, setTestodata] = useState([]);
  const Listingdata = () => {
    ApiClient.get("userfeatures").then((res) => {
      setData(res.data.map((item) => item?.user_detail));
    });
  };
  const [showCategories, setShowCategories] = useState(5);
  useEffect(() => {
    Listingdata();
  }, []);

  const ListingTesto = () => {
    ApiClient.get("testimonials").then((res) => {
      setTestodata(res.data);
    });
  };
  useEffect(() => {
    ListingTesto();
  }, []);

  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  useEffect(() => {
    if (id) {
      if (user.loggedIn) {
      } else {
        loader(true);
        ApiClient.post("autoLogin", { id: id }).then((response) => {
          if (response.success) {
            dispatch(login_success(response.data));
            localStorage.setItem("token", response.data.access_token);
            toast.success(response.message);
            const newdata = response.data;
            if (
              newdata.role &&
              newdata.role.id == environment.VirtualId &&
              newdata.isVerifiedDocument == "accepted"
            ) {
              history.push("/projectslist");
            } else {
              history.push("/profile");
            }
          }
          loader(false);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (
      user.role &&
      user.role.id == environment.VirtualId &&
      user.isVerifiedDocument != "accepted"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        history.push("/profile");
      }
    }
    if (
      user.role &&
      user.role.id == environment.VirtualId &&
      user.isVerifiedDocument == "rejected"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        history.push("/profile");
      }
    }
    if (
      user.role &&
      user.role.id == environment.VirtualId &&
      user.isVerifiedDocument == "pending"
    ) {
      if (pathname == "/profile/edit") {
      } else {
        history.push("/profile");
      }
    }
  }, []);

  const [CategoryData, setCategoryData] = useState([]);
  const GetCategories = () => {
    ApiClient.get(`skill/types?page=1&count=10&`).then((res) => {
      if (res.success) {
        setCategoryData(res.data);
      }
    });
  };
  useEffect(() => {
    GetCategories();
    GetJobs();
  }, []);

  const GetJobs = () => {
    loader(true);
    ApiClient.get(`posted/projects?page=1&count=50`).then((res) => {
      if (res.success) {
        setJobData(res.data);
      }
      loader(false);
    });
  };
  const flashSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <FlashNextArrow />,
    prevArrow: <FlashPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };

  const flashSettings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <FlashNextArrow />,
    prevArrow: <FlashPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  function FlashNextArrow2(props) {
    const { className, style, onClick } = props;

    return (
      <div className={"material-icons cat_right_arrow m2"} onClick={onClick}>
        {" "}
        <span className="" aria-hidden="true">
          <i className="fa fa-arrow-right cm_ico " aria-hidden="true"></i>
        </span>
      </div>
    );
  }

  function FlashPrevArrow2(props) {
    const { className, style, onClick } = props;
    return (
      <div className={"material-icons cat_left_arrow m2"} onClick={onClick}>
        {" "}
        <span className="" aria-hidden="true">
          {" "}
          <i className="fa fa-arrow-left cm_ico" aria-hidden="true"></i>
        </span>
      </div>
    );
  }
  function FlashNextArrow(props) {
    const { className, style, onClick } = props;

    return (
      <div className={"material-icons cat_right_arrow "} onClick={onClick}>
        {" "}
        <span className=" " aria-hidden="true">
          <i className="fa fa-arrow-right cm_ico " aria-hidden="true"></i>
        </span>
      </div>
    );
  }

  function FlashPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={"material-icons cat_left_arrow"} onClick={onClick}>
        {" "}
        <span className="" aria-hidden="true">
          {" "}
          <i className="fa fa-arrow-left cm_ico" aria-hidden="true"></i>
        </span>
      </div>
    );
  }
  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };
  const ReadChanger = (id) => {
    const previousData = document.getElementById(id).innerText;
    if (previousData == "Read More") {
      document.getElementById(id).innerText = "Read Less";
    } else {
      document.getElementById(id).innerText = "Read More";
    }
  };
  return (
    <>
      <Header />
      <div className="container-fluid px-md-5 px-2 mt-md-5 ">
        {/* <div className="line_bottom mt-md-5 "> */}
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="banner-sect mt-md-5 mt-2">
              <div className="row">
                <div className="col-md-6  ">
                  <img
                    loading="lazy"
                    width={"100%"}
                    height={"100%"}
                    src="assets/img/project.png"
                    className="w-100 banner-img"
                    alt="J1ll"
                  />
                </div>
                <div className="col-md-6  ">
                  <div className="findTheBestVirtualAssistanParent">
                    <div className="findTheBest">
                      Unlock Efficiency: Discover Top Virtual Assistants Here!
                    </div>

                    <div className="loremIpsumDolorContainer">
                      <p className="Assistants">
                        In today's fast-paced world, finding reliable virtual
                        assistants can be the key to maximizing productivity and
                        reclaiming precious time. Look no further – we've
                        curated a selection of the best virtual assistants to
                        suit your needs. Whether it's administrative tasks,
                        scheduling, or specialized projects, our pool of skilled
                        professionals is ready to support you. Say goodbye to
                        overwhelm and hello to seamless efficiency. Find your
                        perfect match today!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="pt-md-5">
              <div className="landingPage2Inner   mt-md-5 mt-4 phonemx">
                <div className="frameParent3">
                  <div
                    onClick={(e) => history.push(`/signup?role=employer`)}
                    className="groupParent1 pointer"
                  >
                    {/* <img
                    loading="lazy"
                    className="frameItem pointer"
                    alt=""
                    src="/assets/img/group-1@2x.png"
                    width={"100%"}
                    height={"100%"}
                  /> */}
                    <span className="bgimgg">
                      <img
                        loading="lazy"
                        className="frameItem pointer"
                        alt=""
                        src="/assets/img/v.png"
                        width={"100%"}
                        height={"100%"}
                      />
                    </span>
                    <div className="createAccountParent">
                      <div className="logoDesign mb-0">
                        Create an Acount Here
                      </div>
                      <div className="firstYouHave">
                        Find a Virtual <br /> Assitant
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => history.push("/signup?role=virtual")}
                    className="groupParent1 pointer"
                  >
                    {/* <img
                    loading="lazy"
                    className="frameItem pointer"
                    alt=""
                    src="/assets/img/group-3@2x.png"
                    width={"100%"}
                    height={"100%"}
                  /> */}
                    <span className="bgimgg">
                      {" "}
                      <img
                        loading="lazy"
                        className="frameItem pointer"
                        alt=""
                        src="/assets/img/s.png"
                        width={"100%"}
                        height={"100%"}
                      />
                    </span>
                    <div className="createAccountParent">
                      <div className="logoDesign mb-0">Search Work Here</div>
                      <div className="searchTheBest">
                        Find a Job as a Virtual Assistant
                      </div>
                    </div>
                  </div>
                  <div
                    className="groupParent1 pointer"
                    onClick={() => history.push("/join-us")}
                  >
                    {/* <img
                    loading="lazy"
                    className="frameItem pointer"
                    alt=""
                    src="/assets/img/group-2@2x.png"
                    width={"100%"}
                    height={"100%"}
                  /> */}
                    <span className="bgimgg">
                      <img
                        loading="lazy"
                        className="frameItem pointer"
                        alt=""
                        src="/assets/img/globe.png"
                        width={"100%"}
                        height={"100%"}
                      />
                    </span>
                    <div className="createAccountParent">
                      <div className="logoDesign mb-0">Referral Program</div>
                      <div className="searchTheBest">
                        Apply or save and start <br /> your work
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12">
            <div className="section-padding pt-md-5 mt-md-5 mt-4">
              <div className="row align-items-center ">
                <div className="col-md-6">
                  <div className="theMostTalentedContainer">
                    <p className="theMostTalented">
                      The most talented remote <br></br> workers from around the
                      world <br></br> are ready to join your company
                    </p>
                  </div>
                  <div className="d-flex mt-5 ph_btn_align" role="button">
                    <div
                      className="scheduleADemoWrapper mr-2"
                      role="button"
                      onClick={(e) => history.push(`/contact-us`)}
                    >
                      <div className="scheduleADemo">Contact us</div>
                    </div>
                    <div
                      className="postAProjectWrapper"
                      role="button"
                      onClick={(e) => history.push(`/signup?role=employer`)}
                    >
                      <div className="scheduleADemo">Post a project</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    loading="lazy"
                    src="assets/img/banner.png"
                    className="w-100"
                    alt="Hello"
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="line_bottom mt-5">
          <div className="row">
            <div className="col-md-6 mt-5 pt-5">
              <img
                loading="lazy"
                width={"100%"}
                height={"100%"}
                src="assets/img/project.png"
                className="w-100"
                alt="J1ll"
              />
            </div>
            <div className="col-md-6 mt-5 pt-5">
              <div className="findTheBestVirtualAssistanParent">
                <div className="findTheBest">
                Unlock Efficiency: Discover Top Virtual Assistants Here!
                </div>
           
                <div className="loremIpsumDolorContainer">

                  <p className="Assistants">
                  In today's fast-paced world, finding reliable virtual assistants can be the key to maximizing productivity and reclaiming precious time. Look no further – we've curated a selection of the best virtual assistants to suit your needs. Whether it's administrative tasks, scheduling, or specialized projects, our pool of skilled professionals is ready to support you. Say goodbye to overwhelm and hello to seamless efficiency. Find your perfect match today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="landingPage2 home_margin mt-md-5 pt-md-5"> */}
        <div className="row">
          <div className="col-md-12">
            <div className="recentlyPosted">
              <div className="frameParent">
                <div className="theLatestProjectsWorkParent">
                  <div className="theLatestProjects">
                    The latest projects work!
                  </div>
                  <div className="recentlyPostedWorksContainer">
                    <span>Recently Posted </span>
                    <span className="works">Works</span>
                  </div>
                </div>
                {/* <a className='prev_btn'></a>
          <a className='newxt_btn'></a> */}
              </div>
              <Slider {...flashSettings}>
                {JobData.map((item) => (
                  <div
                    key={item.name}
                    role="button"
                    disabled={
                      user?.role && user.role.id == environment.EmployerId
                    }
                    className="p-1 fff bg-white"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={(e) => setViewProjectData(item)}
                  >
                    <div className="materialUiParent text-center">
                      <img
                        src="/assets/img/Material Ui.png"
                        loading="lazy"
                        className="img-fluid m-auto"
                        alt=""
                      />
                      <div className="logoDesignParent mt-2">
                        <div className="logoDesign">{item?.name}</div>
                        <div className="needAProfessional">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></div>
                          Need a professional logo with writing underneath for
                          our jewellery company
                        </div>
                      </div>
                      <div className="highestBid500Parent mt-4">
                        <div className="highestBid500Container text-left">
                          <span>Skills</span>
                          <span className="span1 ratethis">
                            {item?.skills?.map((itm) => itm.name).toString()}
                          </span>
                        </div>
                        <br />
                        <p className="text-primary apply_Now text-right ratethis whitespace">
                          Rate- {item?.hourlyratestart}USD-
                          {item?.hourlyrateEnd}USD
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        {/* <div className="form-row home_margin">
            <div className="theLatestProjectsWorkParent WorkParent_m">
              <div className="recentlyPostedWorksContainer WorkParent_m">
                <span>Choose Different </span>
                <span className="works">Category </span>
              </div>
            </div>

 
              {CategoryData.map((item, index) => (
                <div className=" position-relative">
                  <img
                    loading="lazy"
                    className="unsplashv9vii5gv8lwIcon"
                    width={"100%"}
                    height={"100%"}
                    alt=""
                    src={
                      item?.name.includes("Logo")
                        ? "/graphic.jpeg"
                        : item?.name?.includes("Market")
                        ? "/marketing.png"
                        : item?.name.includes("Communication")
                        ? "/communication.jpg"
                        : item?.name.includes("Testing")
                        ? "/testing.png"
                        : item?.name.includes("Developer") ||
                          item?.name.includes("developer")
                        ? "/developer.png"
                        : methodModel.userImg(item.image[0], "category")
                    }
                  />
                  <div className="graphicDesign text-capitalize">
                    {item.name}
                  </div>
                </div>
              )) 
          </div> */}

        <div className="row  ">
          <div className="col-12 text-center">
            <div className="recentlyPostedWorksContainer WorkParent_m mt-md-5 mt-0">
              <span>Choose Different </span>
              <span className="works">Category </span>
            </div>
          </div>

          {CategoryData?.map((item, index) =>
            showCategories > index + 1 ? (
              <div
                className="col-lg-3 col-md-6 col-sm-6 mb-md-5 mb-2"
                key={index}
              >
                <div className="graphicDesign text-capitalize">
                  <img
                    src={
                      item?.name.includes("Logo")
                        ? "/graphic.jpeg"
                        : item?.name?.includes("Market")
                          ? "/marketing.png"
                          : item?.name.includes("Communication")
                            ? "/communication.jpg"
                            : item?.name.includes("Testing")
                              ? "/testing.png"
                              : item?.name.includes("Developer") ||
                                item?.name.includes("developer")
                                ? "/developer.png"
                                : methodModel.userImg(item.image[0], "category")
                    }
                    loading="lazy"
                    className="unsplashv9vii5gv8lwIcon"
                    alt=""
                  />
                  <p className="clls"> {item.name}</p>
                </div>
              </div>
            ) : null
          )}

          <div className="col-12 text-center">
            {showCategories == 5 ? (
              <button
                className="scheduleADemoWrapper b-0"
                onClick={(e) => setShowCategories(100)}
              >
                More Categories
              </button>
            ) : (
              <button
                className="scheduleADemoWrapper b-0"
                onClick={(e) => setShowCategories(5)}
              >
                Less Categories
              </button>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="portfolios  mt-5 pt-md-5 ">
              <h3 className="chooseDifferentCategoryContainer mb-0 mt-0 text-center ">
                <div className="theLatestProjects mb-2 text-center">
                  Logos, websites, book covers & more!
                </div>
                <div className="next_div">
                  {" "}
                  Checkout The Best{" "}
                  <span className="works works_t">Portfolios</span> Here
                </div>
              </h3>
              {/* <div className="groupParent">
              <Slider {...flashSettings2} key="3">
                {data.map((item, index) => (
                  <div
                    className="rectangleParent shadow"
                    onClick={(e) => {
                      user.role && user.role.id == environment.VirtualId
                        ? null
                        : history.push(`/virtualdetails/${item.id}`);
                    }}
                  >
                    <img
                      loading="lazy"
                      className="groupItem"
                      width={"100%"}
                      height={"100%"}
                      name="image"
                      value={item.image}
                      alt=""
                      src={methodModel.userImg(item.image)}
                    />
                    <div className="frameContainer">
                      <div className="theLatestProjectsWorkParent">
                        <div className="ceoCompany">{item.fullName}</div>
                        <div className="jhonSmith">
                          {item?.skills?.map((item) => item.label).toString()}
                        </div>
                      </div>
                      <img
                        loading="lazy"
                        className="biarrowRightIcon"
                        width={"100%"}
                        height={"100%"}
                        alt=""
                        style={{ visibility: "hidden" }}
                        src="/assets/img/biarrowright.svg"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
             */}
              <div className="row homes_row">
                {data?.map((item) => (
                  <div
                    key={item?._id}
                    className="col-lg-4 col-sm-6 mb-md-5 mb-3 phonemx"
                    onClick={(e) => {
                      user?.role && user?.role?.id == environment?.VirtualId
                        ? null
                        : history.push(`/virtualdetails/${item?._id}`);
                    }}
                  >
                    <div className="rectangleParent shadow">
                      <img
                        loading="lazy"
                        className="groupItem"
                        width={"100%"}
                        height={"100%"}
                        name="image"
                        value={item?.image ? item?.image : ""}
                        alt=""
                        src={methodModel.userImg(item?.image)}
                      />
                      <div className="frameContainer">
                        <div className="theLatestProjectsWorkParent pl-1">
                          <div className="ceoCompanyy text-capitalize">
                            {item?.fullName}
                          </div>
                          <div className="jhonSmith pt-0 textlimittest">
                            {item?.skills?.map((item) => item?.label).toString()}
                          </div>
                        </div>
                        <img
                          loading="lazy"
                          className="pr-md-3 arrtoright"
                          alt=""
                          src="/assets/img/biarrowright.svg"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="portfolios home_margin pt-5 mt-5 testimonial-sect">
              <h3 className="chooseDifferentCategoryContainer chooseDifferentCategoryContainer2 mt-0">
                <p className="test_p">Testimonial</p>
                See What Others Have <br></br>
                to Say About Us
              </h3>
              <div className="groupParent ">
                <Slider {...flashSettings2} key="3">
                  {testodata.map((item) => (
                    <div className="" style={{ width: "auto" }} key={item.id}>
                      <div className="">
                        <div className="testimonial3">
                          <div className="text">
                            <img
                              loading="lazy"
                              className="quoteIcon"
                              alt=""
                              width="100%"
                              height="100%"
                              src="/assets/img/quote.svg"
                            />
                            <div className="loremIpsumIs">
                              {parse(item?.description.slice(0, 200))}
                              <span
                                className="collapse"
                                id={`readMoreContent${item.id}`}
                              >
                                {parse(item?.description.slice(200))}
                              </span>
                            </div>
                            {item?.description.length > 200 ? (
                              <a
                                className="btn btn-primary read_m"
                                onClick={(e) => ReadChanger(`read${item.id}`)}
                                id={`read${item.id}`}
                                data-toggle="collapse"
                                href={`#readMoreContent${item.id}`}
                                role="button"
                                aria-expanded="false"
                                aria-controls="readMoreContent"
                              >
                                Read more
                              </a>
                            ) : null}
                          </div>
                          <div className="d-flex  mt-3">
                            <img
                              loading="lazy"
                              className="avatarIcon"
                              alt=""
                              width={"100%"}
                              height={"100%"}
                              src={methodModel.userImg(item?.image)}
                            />
                            <div className="namedesignations ml-2">
                              <div className="ceoCompany">{item?.title}</div>
                              <div className=" jhonSmith">
                                {item?.designation}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="bg-green text-center p-4">
        <h2 className="news_letter">Newsletter Subscription</h2>
        <p>
          Subscribe to our newsletter to get new freelance work and projects{" "}
        </p>
        <input
          className="form-control shadow home_in"
          placeholder="Enter your email address"
        />
        <button className="btn btn-primary Subscribe">Subscribe</button>
      </div>

      <Footer />

      <div
        className="offcanvas offcanvas-end space_canvas"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <i
            className='fa fa-angle-left newback data-bs-dismiss="offcanvas"
            aria-label="Close"'
            type="button"
            id="closeJobDetails"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></i>
        </div>

        <div className="offcanvas-body">
          <div className="card">
            <div className="row">
              <div className="col-lg-8 ">
                <div className="p-3">
                  <h3>{ViewProjectData.name}</h3>
                  <p className="text-primary mt-3">
                    {ViewProjectData.skillType &&
                      ViewProjectData.skillType.name}
                  </p>

                  <p>Posted {GetPostedTime(ViewProjectData.createdAt)}</p>
                </div>
                <div className="col-12 ">
                  <div>
                    {" "}
                    <hr />
                  </div>
                </div>

                <div
                  className="p-3"
                  dangerouslySetInnerHTML={{
                    __html: ViewProjectData?.description,
                  }}
                ></div>

                <div className="col-12 ">
                  <div>
                    {" "}
                    <hr />
                  </div>
                </div>
                <div className="p-3">
                  <div className="d-flex justify-content-between flex-lg-col">
                    <div className="price_pro  d-flex">
                      <span className="material-icons price_local mt-1">
                        local_offer
                      </span>
                      <div className="ml-2">
                        <b>
                          {" "}
                          {ViewProjectData.hourlyratestart} USD -
                          {ViewProjectData.hourlyrateEnd} USD
                        </b>
                        <p>Budget</p>
                      </div>
                    </div>
                    <div className="price_pro d-flex ">
                      <span className="material-icons price_locals">
                        manage_accounts
                      </span>
                      <div className="ml-2">
                        <b>Intermediate</b>
                        <p>
                          I am looking for a mix of <br /> experience and value
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="badges_project mb-2">
                    <p className="text-bold d-inline skills_txt mr-1">
                      Skills:
                    </p>{" "}
                    {ViewProjectData.skills &&
                      ViewProjectData.skills.map((item, index) => (
                        <span>{item.name}</span>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-4  ">


                <div className=" p-4">
                  <h6 className="mb-3">
                    <b>About the client</b>
                  </h6>
                  <h6 className="mb-3">
                    <b>
                      {ViewProjectData.addedByDetail &&
                        ViewProjectData.addedByDetail.companyName}
                    </b>
                  </h6>
                  <br />
                  <p className="mb-3 mt-2">
                    <b>
                      {ViewProjectData.addedByDetail &&
                        ViewProjectData.addedByDetail.city}
                      ,
                      {ViewProjectData.addedByDetail &&
                        ViewProjectData.addedByDetail.country}
                    </b>
                  </p>
                  <p className="mb-3">
                    {ViewProjectData.addedByDetail &&
                      ViewProjectData.addedByDetail.timeZone &&
                      ViewProjectData.addedByDetail.timeZone.label}
                  </p>
                  <b>8 Jobs Posted</b>
                  <p>25% hire rate, 4 open jobs</p>
                  <b>
                    $
                    {ViewProjectData.addedByDetail &&
                      ViewProjectData.addedByDetail.hourlyRate}{" "}
                    /hr avg hourly rate paid
                  </b>
                  <p>56 hours</p>
                </div>
                <div className="p-3 text-center">
                  <button
                    className="btn btn-primary mb-3  appybtn"
                    disabled={
                      ViewProjectData.isJobApplied == true ? true : false
                    }
                    onClick={(e) => {
                      document.getElementById("closeJobDetails").click();
                      if (user.id) {
                        history.push(
                          `/perposal/${ViewProjectData._id}?apply=job`
                        );
                      } else {
                        history.push(`/login`);
                      }
                    }}
                  >
                    {!ViewProjectData.isJobApplied ? "Apply Now" : "Applied"}
                  </button>

                  {/* <p>Send a proposal for:12 Connects</p> */}
                  {/* <p>Available Connects: 60</p> */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
