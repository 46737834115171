
import { useEffect, useState } from 'react';
import './style.scss';
import Header from '../../components/global/header';
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

const Notifications = () => {
  const Navigate = useHistory();
  const [NotificationData, setNotificationData] = useState([]);
  const GetAllNotifications = () => {
    loader(true);
    ApiClient.get(`notifications?status=unread`).then(response => {
      if (response.success) {
        setNotificationData(response.data);
      }
      loader(false);
    })
  }

  useEffect(() => {
    GetAllNotifications();
  }, [])


  const HandleReadNotification = (id) => {
    loader(true);
    ApiClient.put(`notification?id=${id}`, { "notificationStatus": "read" }).then(res => {
      if (res.success) {
        GetAllNotifications();
      }
    })
  }

  const GetPostedTime = (date) => {
    const result = moment(date).fromNow()
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  const [InviteRejectReason, setInviteRejectReason] = useState("");

  const HandleNotificationPush = (e, item) => {
    e.preventDefault();
    if (item.type == "applyjob") {
      Navigate.push(`/details/${item.applyJobId}`)
    } else {
      if (item.type == "find_notice" || item.type == 'notice') {
        Navigate.push('/notice');
      }
      if (item.inviteId_details && item.inviteId_details.status == "rejected") {
        setInviteRejectReason(item.inviteId_details && item.inviteId_details.reason);
        document.getElementById("openreject").click()
      }
      else {
        Navigate.push(`/proposaldetail/${item?.inviteId_details && item.inviteId_details?._id}`)

      };
      if (item.type == "workSchedule" || item.type == "workScheduleAccepted" || item.type == "workScheduleRejected" || item.type == 'timeZone') {
        Navigate.push(`/offer/${item.contractId}`)
      }
      if (item.type == "contract" || item.type == "contractAccepted" || item.type == "contractRejected") {
        Navigate.push(`/offer/${item.contractId}`)
      }
    }
    HandleReadNotification(item._id)
  }
  return (
    <>
      <Header />


      <div className="container mt-5 mb-5">
        <div className="row">
          <h2 className='my-4 text-center'>Notifications</h2>
          <div className='bank_account_card add_account_width'>
            <h4 className='mt-4 mb-4 pb-2'>Most Recent</h4>
            {NotificationData.map((item, index) => (<div className='col-md-12 mt-2 border-bottom mb-4 pb-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center' onClick={e => { HandleNotificationPush(e, item) }}>
                  <img className='notification_userImg' src={methodModel.userImg(item.addedBy_details && item.addedBy_details.image)} />
                  <div className='ml-3'>
                    <p className='noti_list m-0' >{item.title}</p>
                    <b className='blur_date'>{GetPostedTime(item.createdAt)}</b>
                  </div>
                </div>
                <a className='close_noti'><span onClick={e => HandleReadNotification(item._id)} className='material-icons'>close</span></a>
              </div>
            </div>))}

          </div>
          {NotificationData.length > 0 ? null : <div className='text-danger text-center'><p className='pt-4' >No Notification Here </p></div>}
        </div>
        <button type="button" className="btn btn-primary d-none" id='openreject' data-toggle="modal" data-target="#exampleModalCenter">
          Launch demo modal
        </button>

        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Reason of Rejection</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {InviteRejectReason}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                {/* <button type="button"  className="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notifications;