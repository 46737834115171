import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import TrackingTable from "./TrackingData/TrackingTable"
import loader from "../../methods/loader";
import moment from "moment";
import Header from "../../components/global/header";

const TimeClockRequests = () => {
    const [FreelancerDetails, setFreelancerDetails] = useState({});
    const [EmployerDetails, setEmployerDetails] = useState({});
    const [ProjectDetails, setProjectDetails] = useState({});

    const [BillingData, setBillingData] = useState([]);
    const { startDate, endDate } = methodModel.getCurrentWeekDates();
    const today = endDate; // Get today's date
    const tomorrow = startDate; // Get tomorrow's date
    const [BillingFilters, setBillingFilters] = useState({
        addedBy: "",
        contractId: "",
        start: moment(tomorrow).format("MM-DD-YYYY"),
        end: moment(today).format("MM-DD-YYYY"),

    });

    const GetDetails = () => {
        loader(true);
        ApiClient.get(`contract`, { id: id || "" }).then((res) => {
            const data = res.data;
            setFreelancerDetails(data?.contractTo);
            setProjectDetails(data?.projectId);
            setEmployerDetails(data?.contractFrom);
            loader(false);
        });
    };

    const GetBillingData = (p = {}) => {
        loader(true);
        const newfilters = { ...BillingFilters, ...p };
        ApiClient.get(`billing/list`, newfilters).then((res) => {
            if (res.success) {
                setBillingData(res.data);
            }
            loader(false);
        });
    };

    useEffect(() => {
        GetBillingData()
    }, [])

    return (
        <>
            <Header />
            <TrackingTable
                id={""}
                VAData={FreelancerDetails}
                EmployerData={EmployerDetails}
                contractData={ProjectDetails}
                data={BillingData}
                ConfirmFunction={GetDetails}
            />
        </>
    )
}

export default TimeClockRequests