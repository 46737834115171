import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Pagination from "react-js-pagination";
import Header from "../../components/global/header";
import methodModel from "../../methods/methods";
import moment from "moment";
import TrackingTable from "../CreateContractPages/TrackingData/TrackingTable";
import DirectRequestEdtor from "./DirectRequestEdtor";

export default function DMContracts() {
  const [TabSelection, setTabSelection] = useState("request");
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const [ContractsData, setContractsData] = useState([]);
  const [TotalResults, setTotalResults] = useState(0);
  const [filters, setfilters] = useState({
    page: 1,
    count: 50,
    search: "",
    directManangerId: user.id,
  });
  const ChildFunctionCaller = useRef({});
  const GetAllContracts = (p = {}) => {
    const ExactFilters = { ...filters, ...p };
    ApiClient.get(`billing_schedules`, ExactFilters).then((res) => {
      loader(true);
      if (res.success) {
        setTotalResults(res.total);
        setContractsData(res?.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetAllContracts();
  }, []);

  //  FOR PAGINATION PAGE HANDLER
  const pageChange = (e) => {
    setfilters({ ...filters, page: e });
    GetAllContracts({ page: e });
  };
  //    THIS IS FOR HANDLING THE  SEARCH FIELD ----->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>-------------->>>>>>>>>>>>>>
  const [SearchData, setSearchData] = useState("");
  const HandleSearchSubmit = (e) => {
    e.preventDefault();
    if (SearchData == "") {
    } else {
      setfilters({ ...filters, search: SearchData });
      GetAllContracts({ search: SearchData });
    }
  };

  const HandleEmptySearch = (e) => {
    e.preventDefault();
    setSearchData("");
    setfilters({ ...filters, search: "" });
    GetAllContracts({ search: "" });
  };

  //    FOR GETTING THE TIME IN PASSED HOURS & DAYS
  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  const HandleAcceptReject = (e, status, id) => {
    ChildFunctionCaller.current[id].ChildAcceptReject(e, status);
  };

  const GetDetalilsLength = async (id) => {
    await ApiClient.get("billing/list", { contractId: id }).then((res) => {
      return res.data && res.data;
    });
  };

  return (
    <div>
      <Header />
      <div className="container mt-5 card p-3">
        <div className="">
          <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
            <div className="text-left">
              <div>
                <button
                  className={`btn btn-${TabSelection == "request" ? "primary" : "secondary"
                    }`}
                  onClick={(e) => setTabSelection("request")}
                >
                  Requests
                </button>
                <button
                  className={`btn  ml-3 btn-${TabSelection == "clock" ? "primary" : "secondary"
                    }`}
                  onClick={(e) => setTabSelection("clock")}
                >
                  Clock Entries
                </button>
              </div>
            </div>
            {TabSelection == "request" &&
              <div className="d-flex align-items-center">
                <form
                  className="w-100"
                  onSubmit={(e) => HandleSearchSubmit(e)}
                >
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search here"
                      value={SearchData}
                      onChange={(e) => {
                        e.target.value == ""
                          ? HandleEmptySearch(e)
                          : setSearchData(e.target.value);
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-primary pl-3 pr-3"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>
          {TabSelection == "request" ?
            <div>
              <div> <h3 className="mb-0 mt-4">Requests</h3></div>
              <div className="table-responsive mt-4">
                {ContractsData.map((item, index) => (
                  <div className="contract_box border-1 border-grey">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="d-flex align-middle mx-2">
                        <img
                          src={methodModel.userImg(
                            `${item.virtualDetail && item.virtualDetail?.image}`
                          )}
                          className="roundimage"
                        />{" "}
                        <p className="aling-middle mt-2 mx-1">
                          {item.virtualDetail && item.virtualDetail.fullName}
                        </p>{" "}
                      </h5>
                      <div>
                        <h5>
                          {item.project_details && item?.project_details.name}
                        </h5>
                      </div>
                      <div className="d-flex " style={{ float: "right" }}>
                        <button
                          className="btn btn-danger mx-2"
                          onClick={(e) =>
                            HandleAcceptReject(e, "rejected", item?.contractId)
                          }
                        >
                          Reject
                        </button>
                        <button
                          className="btn btn-success mx-2"
                          onClick={(e) =>
                            HandleAcceptReject(e, "accepted", item?.contractId)
                          }
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                    <TrackingTable
                      id={item?.contractId}
                      contractData={item?.contractDetail}
                      data={[item?.billingDetail]}
                      VAData={item?.virtualDetail}
                      EmployerData={item?.employerDetail}
                      ConfirmFunction={GetAllContracts}
                      Hidebuttons={true}
                      childRef={(ref) =>
                        (ChildFunctionCaller.current[item?.contractId] = ref)
                      }
                    />
                  </div>
                ))}
              </div>
              {ContractsData?.length == 0 ? (
                <div className="text-center">
                  {" "}
                  <img
                    src="/assets/img/not-found.jpg"
                    className="not_found_img img-fluid"
                  />
                </div>
              ) : null}
              {TotalResults > filters.count ? (
                <div className="" style={{ float: "right" }}>
                  <Pagination
                    activePage={filters.page}
                    itemClassPrev="back_page"
                    itemClassNext="next_page"
                    itemsCountPerPage={filters.count}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={pageChange}
                  />
                </div>
              ) : null}
            </div>
            :
            <>
              <DirectRequestEdtor />
            </>
          }
        </div>
      </div>
    </div>
  );
}
