import Header from "../../components/global/header";
import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./style.scss";
import { Link } from "react-router-dom";

export default function SavedList() {
  const Navigate = useHistory();
  const [ViewProjectData, setViewProjectData] = useState({});
  const [total, setTotal] = useState(0);

  //   Shortlisted Data
  const [Data, setData] = useState([]);
  console
  const GetData = () => {
    loader(true);
    ApiClient.get(`sortlist/list?type=project`).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetData();
  }, []);

  //   Removing the Shortlisted 
  const HandleRemoveFromShortlist = (e, item) => { 
    e.preventDefault();
    loader(true);
    ApiClient.delete(`sortlist?projectId=${item.projecetDetails&&item.projecetDetails._id}`).then((res) => {
      if (res.success) {
        GetData();
      }
      loader(false);
    });
  };

  useEffect(() => {
    GetData();
  }, []);


  const GetPostedTime = (date) => {
    const result=moment(date).fromNow()  
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  return (
    <>
      <Header />

      <div className="saved-job">
      <div className="container vertual_container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="maindiv_boder">
              <h3 className="p-3 mb-0">Saved Jobs</h3>
              {Data?.map((item, index) => (
                <div className="col-md-12 border-bottom mt-md-3 pt-md-3 mb-3 pb-3 jobsearch_box">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex">
                      <div className="product_details">
                        <p className="mb-4">
                          <b
                            className="text-primary font-weight-bold text-capitalize pointer"
                            onClick={(e) => setViewProjectData(item)}
                          >
                            <a
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                            >
                              {item?.projecetDetails?.name}
                            </a>
                          </b>
                        </p>
               
                        <p className="gay_title">
                          <span className="hourly_rate">
                            Hourly -
                            {item.addedByDetail &&
                              item.addedByDetail.hourlyRate}
                            USD
                          </span>
                          - posted {GetPostedTime(item.createdAt)}
                        </p>

                        <p className=" description_cls  mb-0">
                          {item?.projecetDetails?.description}                          
                        </p>
                   

                        <div className="badges_project">
                          {item.skills &&
                            item.skills.map((item, index) => (
                              <span>{item.name}</span>
                            ))}
                        </div>

                        <h3 className="lessthan_cls mt-3">
                          <span className="Proposals_cls">Proposals: </span>Less
                          than 5
                        </h3>

                        <div>
                        <div>
                        <i  className="fa fa-check-circle" aria-hidden="true"></i>
                          <span className="mr-1 ml-1">Payment verified</span>
                          </div>
                         <div className="my-2">
                         <i
                             className="fa fa-star Rating mr-1"
                            aria-hidden="true"
                          ></i>
                          <i
                             className="fa fa-star Rating mr-1"
                            aria-hidden="true"
                          ></i>
                          <i
                             className="fa fa-star Rating mr-1"
                            aria-hidden="true"
                          ></i>
                          <i
                             className="fa fa-star Rating mr-1"
                            aria-hidden="true"
                          ></i>
                          <i
                             className="fa fa-star Rating mr-1"
                            aria-hidden="true"
                          ></i>
                          </div>
                         <div className="mb-2">
                         <b>
                            
                            {item.addedByDetail &&
                              item.addedByDetail.hourlyRate} USD
                          </b>{" "}
                          <span className="gray_cls">/hrs</span>
                          </div>
                       
                          <span className="gray_cls ">
                            <span  className="material-icons location">
                              location_on
                            </span>{" "}
                            {item.addedByDetail.country}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="post_btn d-flex">
                      <button className="btn btn-outline-primary header_like mr-2">
                        <span  className="material-icons">thumb_down_off_alt</span>
                      </button>
                   
                      <a
                        className="fav_icon_revealeant"
                        onClick={(e) => HandleRemoveFromShortlist(e, item)}
                      >
                        <span  className="material-icons fav_fill" title="unfavorite">favorite</span>
                      </a>
                
                    </div>
                  </div>
                </div>
              ))}
              {Data.length == 0 ? (
                <div className="text-danger text-center  my-3">No Data</div>
              ) : null}
            </div>
          </div>
        </div>
        
      </div>

      </div>
      
      <div
         className="offcanvas offcanvas-end space_canvas"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div  className="offcanvas-header">
          <h5  className="offcanvas-title" id="offcanvasRightLabel">
            Post Detail
          </h5>
          <button
            type="button"
             className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="closethis1"
          ></button>
        </div>
        <div  className="offcanvas-body">
          <div className="card">
            <div className="row">
              <div className="col-md-8 pr-0">
                <div className="p-3">
                  <h3 className="hedingclas mb-5">{ViewProjectData?.projecetDetails?.name}</h3>
                  <p className="text-primary mt-3 skilscls">
                    {ViewProjectData?.projecetDetails_skillType &&
                      ViewProjectData.projecetDetails_skillType?.name}
                  </p>
 

                  <p>Posted {GetPostedTime(ViewProjectData?.projecetDetails?.createdAt)}</p>
                </div>
                <hr />
                <div className="p-3">{ViewProjectData?.projecetDetails?.description}</div>
                <hr />

                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className="price_pro pl-4 d-flex">
                      <span  className="material-icons price_local mt-1">
                        local_offer
                      </span>
                      <div className="ml-2">
                        <b>
                          {" "}
                          {ViewProjectData?.projecetDetails?.hourlyratestart} USD - 
                          {ViewProjectData?.projecetDetails?.hourlyrateEnd} USD
                        </b>
                        <p>Budget</p>
                      </div>
                    </div>
                    <div className="price_pro d-flex pr-4">
                      <span  className="material-icons price_locals">
                        manage_accounts
                      </span>
                      <div className="ml-2">
                        <b>Intermediate</b>
                        <p>
                          I am looking for a mix of <br /> experience and value
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div>
                  {/* <span className="projectype pl-3">Project Type:</span>{" "}
                  One-time project */}
                </div>
                {/* <hr /> */}
                <div>
                  <h3 className="head">Skills and Expertise</h3>

                  <div className="badges_project mb-2 pl-3">
                  {ViewProjectData.projecetDetails&&ViewProjectData.projecetDetails.skills &&
                      ViewProjectData.projecetDetails.skills.map((item, index) => (
                        <span>{item.name}</span>
                      ))}
                  </div>
                </div>
                <hr/>

                <div>
                  <h3  className="head">Activity on this job</h3>
                </div>
              </div>

              <div className="col-md-4  pl-0 border-left">
                <div className="p-3"     data-bs-dismiss="offcanvas"
            aria-label="Close">
                  <Link to={`/perposal/${ViewProjectData.projecetDetails&&ViewProjectData.projecetDetails._id}?apply=job`}     
                      className="btn btn-primary mb-3 w-100">
                    Apply Now
                  </Link>
                  <button  onClick={e=>{HandleRemoveFromShortlist(e,ViewProjectData);document.getElementById("closethis1").click()}} className="btn btn-outline-primary mb-3 w-100 d-flex align-items-center justify-content-center">
                    {" "}
                    {ViewProjectData.isShort==false?<span  className="material-icons mr-2"  title="favorite">
                      favorite_border
                    </span>:<span  className="material-icons mr-2" title="favorite">
                      favorite
                    </span>}
                    Saved Job
                  </button> 
                </div>
                <hr />
                <div className="p-3">
                  <h6 className="mb-3">
                    <b>About the client</b>
                  </h6>
                  <h6 className="mb-3">
                    <b>
                      {ViewProjectData.addedByDetail &&
                        ViewProjectData.addedByDetail.companyName}
                    </b>
                  </h6>
                  <br />
                  <p className="mb-3 mt-2">
                    <b>
                      {ViewProjectData.addedByDetail &&
                        ViewProjectData.addedByDetail.companyAddress} 
                    </b>
                  </p>
                  <p className="mb-3">
                    {ViewProjectData.addedByDetail &&
                      ViewProjectData.addedByDetail.timeZone &&
                      ViewProjectData.addedByDetail.timeZone.label}
                  </p>
                  <b>8 Jobs Posted</b>
                  <p>25% hire rate, 4 open jobs</p>
     
                  <p>40 hours</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
         className="modal fade"
        id="exampleModalLong"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div  className="modal-dialog" role="document">
          <div  className="modal-content">
            <div  className="modal-header">
              <h5  className="modal-title" id="exampleModalLongTitle">
                Add Project
              </h5>
              <button
                type="button"
                 className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div  className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Project Name</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Technology</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Skills</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Start Date</label>
                    <input type="date" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">End Date</label>
                    <input type="date" className="form-control" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="">Description</label>
                    <textarea type="text" className="form-control"></textarea>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="mb-0">Upload Documents</label>
                  <div  className="profile_btn_portfolio ">
                    <label  className="add_portfolio edit ml-3">
                      <input
                        id="bannerImage"
                        type="file"
                        multiple=""
                         className="d-none"
                      />
                      <span  className="add_portfolio">
                        <i  className="material-icons add_port">add</i>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="col-md-12 text-right">
                  <button className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
