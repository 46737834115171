import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useParams } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import Header from "../../components/global/header";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DatePicker } from 'antd';
import moment from 'moment';
import loader from "../../methods/loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { RangePicker } = DatePicker;

const Submithours = () => {
    const calendarRef = useRef(null);
    const history = useHistory();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [filters, setFilters] = useState({ start: '', end: '' });
    const [totalHours, setTotalHours] = useState({ hours: '', minutes: '' });
    const [contractDate, setContractDate] = useState()

    const MapAllData = (data) => {
        return data.map(event => ({
            id: event?.id || event?._id,
            status: event.billingStatus,
            title: `${event.projectDetail?.title},\n\n\n  ${event?.totalDurationOfTime ? event?.totalDurationOfTime : "0.00"} hrs,\n\n\n  ${event?.billingStatus === "notSubmitted" ? "Not Submitted" : event?.billingStatus || ""}`,
            start: new Date(event.startTime),
            end: event.endTime ? new Date(event.endTime) : "",
        }));
    };

    const SubmitBilling = (e) => {
        e.preventDefault();
        let fltr = data?.filter((itm) => itm?.status != 'submitted')
        const payload = fltr.map(itm => itm.id);
        if (payload?.length === 0) {
            return toast.error("Hours already submitted")
        }
        loader(true)
        ApiClient.put(`billing/submit`, { data: payload })
            .then((res) => {
                if (res.success) {
                    loader(false)
                    toast.success(res.message);
                    document.getElementById("closemodal").click()
                    history.push("/trans/virtual");
                } else {
                    console.log('error');
                }
            })
    };


    const GetAllData = (p = {}) => {
        loader(true)
        let filt = { ...filters, ...p, contractId: id }
        ApiClient.get(`billing/list`, filt).then((res) => {
            if (res.success) {
                loader(false)
                const FinalData = MapAllData(res.data);
                setContractDate(res?.data?.find((item) => item?.contractDetail?.startDate)?.contractDetail?.startDate)
                setData(FinalData);
                let hours = res?.total_billingHours;
                let minutes = res?.total_billingMinutes;
                let remainder = minutes / 60;
                let quotient = minutes % 60;
                hours += parseInt(remainder);
                minutes = quotient;
                setTotalHours({ hours: hours || "00", minutes: minutes || "00" });
            }
        })
    }

    useEffect(() => {
        GetAllData()
    }, []);


    const handleDateChange = (dates, dateStrings) => {
        setSelectedDateRange(dates);
        let startDate = dateStrings[0] ? moment(dateStrings[0]).format('YYYY-MM-DD') : '';
        let endDate = dateStrings[1] ? moment(dateStrings[1]).format('YYYY-MM-DD') : '';
        setFilters({
            ...filters,
            start: startDate,
            end: endDate,
            contractId: id,
        });
        GetAllData({
            start: startDate,
            end: endDate,
            contractId: id,
        });
        if (calendarRef.current && dateStrings[0]) {
            calendarRef.current.getApi().gotoDate(moment(dateStrings[0]).format('YYYY-MM-DD'));
        }
    };

    return (
        <>
            <Header />
            <div className="container mt-4">
                {/* <div className='col-md-4 mb-3'>
                    <RangePicker
                        className="dateRangePicker"
                        placeholder={["Start Date", "End Date"]}
                        format="MM-DD-YYYY"
                        onChange={handleDateChange}
                        value={selectedDateRange}
                    />
                </div> */}


                {/* {/ {data.length === 0 ? <h4 className='text-center'>No Data</h4> : /} */}
                <FullCalendar
                    key={contractDate} // Use key to re-render calendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="timeGridWeek"
                    initialDate={moment(contractDate).format("YYYY-MM-DD")}
                    firstDay={moment(contractDate).day()}
                    events={data}
                    weekends={true}
                    headerToolbar={{
                        left: 'prev,next today',
                        right: 'title',
                    }}
                />
                {/* {/ } /} */}

                <div className='float-right mt-3'>
                    {data.length !== 0 &&
                        <button className='btn btn-primary' onClick={e => document.getElementById("SubmitHoursModal").click()}>Review</button>
                    }
                    {/* <button className='btn btn-secondary ml-3' onClick={e => history.push("/trans/virtual")}>Back</button> */}
                </div>
            </div>

            <button type="button" className="btn btn-primary d-none" id='SubmitHoursModal' data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Summary</h1>
                        </div>
                        <div className="modal-body">
                            <h4 className="col-md-12 text-center mb-3 text-capitalize">
                                {filters.start &&
                                    <>{moment(filters.start).format("MMM DD")} - {moment(filters.end).format("DD, YYYY")}</>
                                }
                            </h4>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="weightlabel">Date :</label>
                                </div>
                                <div className="col-md-6 mb-3 text-capitalize">
                                    {moment(contractDate).format('DD/MM/YYYY')} - {moment(contractDate).add(6, 'days').format('DD/MM/YYYY')}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="weightlabel">Total Hours :</label>
                                </div>
                                <div className="col-md-6 mb-3 text-capitalize">
                                    {totalHours.hours}:{totalHours.minutes} hrs
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id='closemodal' data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={e => SubmitBilling(e)}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Submithours;