import React, { useEffect, useState } from "react";
import Header from "../../components/global/header";
import ApiClient from "../../methods/api/apiClient";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import loader from "../../methods/loader";
import moment from "moment";
import { toast } from "react-toastify";
import methodModel from "../../methods/methods";


const Approvehours = () => {
    const [data, setdata] = useState([])
    const { id } = useParams()
    const navigate = useHistory()

    const GetAllBillings = () => {
        loader(true)
        ApiClient.get(`twoWeek/billings?contractId=${id}&billingStatus=submitted`).then((res) => {
            if (res.success) {
                loader(false)
                setdata(res?.data)
            }
        })
    }

    useEffect(() => {
        GetAllBillings()
    }, [])

    const AproveBillingHours = (e, item) => {
        e.preventDefault();
        let fltr = item?.billingData?.filter((itm) => itm?.employerReview != "approved")
        const payload = fltr.map((itm) => itm?._id);
        console.log(payload, "8888888")
        if (payload?.length === 0) {
            return toast.error("Hours already Approved")
        }
        // return
        loader(true)
        ApiClient.put(`employer/billing-review`, { approvedBillingIds: payload })
            .then((res) => {
                if (res.success) {
                    loader(false)
                    toast.success(res.message);
                    navigate.push("/work-diary");
                } else {
                    console.log('Error');
                }
            })
    };

    const Getcalculculateminute = (res) => {
        let hours = parseInt(res?.totalDurationOfTime_hour1);
        let minutes = parseInt(res?.totalDurationOfTime_min1);
        let remainder = minutes / 60;
        let quotient = minutes % 60;
        hours += parseInt(remainder);
        minutes = quotient;
        return `${hours || "00"} : ${minutes < 10 ? "0" + minutes : minutes || "00"}`;
    };

    return (
        // <>
        //     <Header />
        //     <div className='container mt-5'>
        //         <h3> Billings Detail</h3>

        //     </div>
        //     <div className='container'>

        //         <div>
        //             <div className='table-responsive'> <table className='table table-hover'>
        //                 <thead>
        //                     <tr>
        //                         <th>Contract Name</th>
        //                         <th>V.A Name</th>
        //                         <th>V.A Rate</th>
        //                         <th>Time Period	</th>
        //                         <th>Total Hours</th>
        //                         <th>Total Amount</th>
        //                         <th>Status</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {data.map((item, index) => (
        //                         <tr >
        //                             <td>{item?.billingData[0]?.projectDetail?.title || "---"}</td>
        //                             <td>{item?.billingData[0]?.addedByDetail?.fullName || "---"}</td>
        //                             <td>{item?.billingData[0]?.addedByDetail?.hourlyRate}{" "}USD</td>
        //                             <td>{item?.startDate ? <>{moment(item?.startDate).format("DD MMM")} - {moment(item?.endDate).format("DD, YYYY")}</> : "---"}</td>
        //                             <td>{Getcalculculateminute(item)}{" "}hrs</td>
        //                             <td>{parseFloat(item?.totalBillingAmount).toFixed(2) || "00"}{" "}USD</td>
        //                             <td>{item?.employerReview === "approved" ? <button disabled title="Approved" className="btn btn-primary">Approved</button> : <button className="btn btn-primary" onClick={e => AproveBillingHours(e, item)}>Approve</button>}</td>

        //                         </tr>
        //                     ))}
        //                 </tbody>
        //             </table>

        //                 {data.length === 0 ? <div className='text-center text-danger mt-2'>
        //                     <h5>No Billing Data</h5>
        //                 </div> : null}


        //             </div>

        //         </div>
        //     </div>
        // </>
        <>
            <Header />
            <div className='container mt-5 '>
                <div className='flow_flex'>
                    <h3 className='mb-md-4'> Billings</h3>
                    {/* <div> <button className='btn btn-primary' style={{ float: "right" }} onClick={e => DownloadEmployerReport(e)} >Export</button></div> */}
                </div>

                <div className="mt-5">

                    <div className='cmc_table '>

                        <div className='row cms firstcms'>
                            <div className='col-2 '>
                                <label>Contract Name</label>
                            </div>
                            <div className='col-2 '>
                                <label>VA Name</label>
                            </div>
                            <div className='col-1 '>
                                <label>VA Rate</label>
                            </div>
                            <div className='col-2 '>
                                <label>Time Period</label>
                            </div>
                            <div className='col-2 '>
                                <label>Total Hours</label>
                            </div>
                            <div className='col-2 '>
                                <label>Total Amount</label>
                            </div>
                            <div className='col-1 '>
                                <label>Status</label>
                            </div>

                        </div>

                        {data.map((item, index) => (
                            <div className='row cms'>
                                <div className='col-2'>
                                    <p className='pms gms'  >{item?.billingData[0]?.projectDetail?.title || "---"}</p>
                                </div>
                                <div className='col-2'>
                                    <p className='pms gms' >{item?.billingData[0]?.addedByDetail?.fullName || "---"} </p>
                                </div>

                                <div className='col-1'>
                                    <p className='pms gms'>{item?.billingData[0]?.addedByDetail?.hourlyRate}{" "}USD </p>
                                </div>
                                <div className='col-2'>
                                    <p className='pms gms'>{item?.startDate ? <>{moment(item?.startDate).format("DD MMM")} - {moment(item?.endDate).format("DD, YYYY")}</> : "---"}</p>
                                </div>
                                <div className='col-1'>
                                    <p className='pms gms' >{Getcalculculateminute(item)}</p>
                                </div> <div className='col-2'>
                                    <p className='pms gms' >{parseFloat(item?.totalBillingAmount).toFixed(2) || "00"}{" "}USD</p>
                                </div> <div className='col-2  '>
                                    <p className='pms gms' >{item?.employerReview === "approved" ? <button disabled title="Approved" className="btn btn-primary">Approved</button> : <button className="btn btn-primary" onClick={e => AproveBillingHours(e, item)}>Approve</button>}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                    {data.length == 0 ? <div className='text-danger text-center'>No Billing Data</div> : null}

                </div>
            </div>
        </>
    )
}
export default Approvehours;