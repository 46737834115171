import React from "react";
import Header from "../../components/global/header";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Joinus = () => {
    const history = useHistory();

    return (
        <>
            <Header />

            <div className="container mt-5">
                <h4 class="text-capitalize  text-center mt-5 mb-0 newh4">
                    Partners Program
                </h4>
                <h4 class="text-capitalize  text-center mt-2 newgrow">
                    Grow Your Business with Us!
                </h4>

                <p className="missionp mt-md-5 text-center">
                    Join our esteemed Partners Program and unlock exciting opportunities
                    to grow your business while contributing to the evolution of remote
                    work. At [Your Company Name], we're committed to building bridges
                    between highly skilled virtual assistants and employers seeking
                    top-tier support. By becoming a partner, you'll play an integral role
                    in shaping the future of remote work.
                </p>

                <h2 className="text-capitalize mt-5 pt-md-3 missohead text-center mb-0">Benefits of Joining</h2>

                <div className="row missiondiv ">
                    <div className="col-md-6">
                        <div>
                            <img src="/assets/img/network.jpg" className="misimg" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-column justify-content-center h-100">
                            <p className="missionheader mb-1">Expand Your Network </p>
                            <p className="missiondesc m-0">
                                {" "}
                                Gain access to our extensive network of employers seeking virtual
                                assistant services. With our platform, you can connect with
                                clients who are looking for reliable and professional virtual
                                assistants like yourself.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row missiondiv rowreverse">
                    <div className="col-md-6">
                        <div className="d-flex flex-column justify-content-center h-100">
                            <p className="missionheader mb-1">Earn Competitive Commissions</p>
                            <p className="missiondesc m-0">
                                For each successful referral, partners earn competitive commission
                                rates. By bringing virtual assistants and employers together,
                                you'll not only grow your business but also increase your earning
                                potential.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end">
                        <div>
                            <img src="/assets/img/earn.jpg" className="misimg" />
                        </div>
                    </div>
                </div>

                <div className="row missiondiv">
                    <div className="col-md-6">
                        <div>
                            <img src="/assets/img/assis.jpg" className="misimg" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-column justify-content-center h-100"> <p className="missionheader mb-1">Marketing Support</p>
                            <p className="missiondesc m-0">
                                Receive marketing support to promote our services and grow your
                                business. Our dedicated team will provide you with the tools and
                                resources you need to effectively market your services and attract
                                new clients.
                            </p></div>
                    </div>
                </div>

                <div className="row missiondiv rowreverse">
                    <div className="col-md-6">
                        <div className="d-flex flex-column justify-content-center h-100">
                            <p className="missionheader mb-1">Dedicated Assistance</p>
                            <p className="missiondesc m-0">
                                Our team is here to support you every step of the way. From
                                onboarding virtual assistants to assisting employers with their
                                needs, we're committed to ensuring a smooth and seamless
                                experience for all parties involved.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end">
                        <div>
                            <img src="/assets/img/support.jpg" className="misimg" />
                        </div>
                    </div>
                </div>

                <p className="missiondesc  mt-md-5 mb-2">
                    Join us in creating a more connected and inclusive future of work.
                    Your expertise and dedication are invaluable assets to our community,
                    and we're thrilled at the prospect of having you join us on this
                    journey.
                </p>

                <p className="missiondesc m mb-2">
                    To learn more about our Partners Program and how you can get involved,
                    please contact us at mailto:recruiter@myvirtualpal.com We're here to
                    answer any questions you may have and provide further details on how
                    you can benefit from this exciting opportunity.
                </p>

                <p className="missiondesc m mb-2">
                    Thank you for considering joining our Partners Program. We look
                    forward to welcoming you aboard and working together to drive positive
                    change in the remote work landscape.
                </p>

           <div className="bcm-partner">
           <h5 className="mt-5  redcolor">My Virtual Pal</h5>

<p className="mt-1 bluecolor" title="Sign Up" onClick={() => history.push(`/signup?role=recruiter`)}>Become a Partner</p>
           </div>
            </div>
        </>
    );
};
export default Joinus;
