import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import environment from "../../environment";
import "react-phone-input-2/lib/style.css";
import Header from "../../components/global/header";
import loader from "../../methods/loader";
import Multiselect from "multiselect-react-dropdown";
import { Formik } from "formik";
import moment from "moment";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CommonDelete from "../CommonDeleteActiveModal/CommonDelete";
import CommonActiveModal from "../CommonDeleteActiveModal/CommonActive";

const Html = ({
  handleSubmit,
  setForm,
  form,
  getError,
  uploadImage,
  submitted,
  setProtofolioError,
  images,
  setimages,
  ProtofolioError,
}) => {
  const [ShowActiveModal, setShowActiveModal] = useState("none");
  const [ActiveId, setActiveId] = useState("");
  const [ActiveStatus, setActiceStatus] = useState("");
  const [ShowDeleteModal, setShowDeleteModal] = useState("none");
  const [DeleteId, setDeleteId] = useState("");
  const Navigate = useHistory();
  const user = useSelector((state) => state.user);
  const [GoArray, setGoArray] = useState([{ Good: "" }]);
  const [SearchData, setSearchData] = useState("");
  const [Preview, setPreview] = useState(false);
  const [PreviewData, setPreviewData] = useState({});
  const [filters, setfilters] = useState({
    page: 1,
    count: 50,
    search: "",
    // status: "active",
    userId: user.id,
    skills: [],
    technology: [],
  });
  const [total, settotal] = useState(0);
  const [View, setView] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [Editid, setEditid] = useState("");
  const [SelectedSkills, setSelectedSkills] = useState([]);
  const [SkillsData, setSkillsData] = useState([]);
  const [SubmittedError, setSubmittedError] = useState(false);
  const GetAllSkills = () => {
    loader(true);
    ApiClient.get(`skills?status=active`).then((res) => {
      if (res.success) {
        const data = res.data;
        const array = [];
        data.map((item, index) => {
          array.push({ name: item.name, id: item.id });
        });
        setSkillsData(array);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetAllSkills();
  }, []);

  const [ProjectsData, setProjectsData] = useState([]);
  const GetAllProjects = (p = {}) => {
    const newfilters = {
      ...filters,
      ...p,
      skills: p.skills
        ? p.skills.map((item) => item.id).toString()
        : filters.skills.map((item) => item.id).toString(),
      technology: p.technology
        ? p.technology.map((item) => item.id).toString()
        : filters.technology.map((item) => item.id).toString(),
    };
    loader(true);
    ApiClient.get(`projects`, newfilters).then((res) => {
      if (res.success) {
        setProjectsData(res.data);
        settotal(res.total);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetAllProjects();
  }, []);

  //  for Document
  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [images1, setimages1] = useState([]);
  const [ProtofolioError1, setProtofolioError1] = useState(false);
  // const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  //  For Deleting the Document
  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images1];
    array.splice(index, 1);
    setimages1(array);
  };

  const imageResult = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images1;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        setDocumentUploadLoading(true);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          data: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            setdocuments(new Date());
            if (files.length == images1.length) {
              setDocumentUploadLoading(false);
            }
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError1(false);
      setdocuments(newarray);
      setimages1(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  const [EditData, setEditData] = useState({});

  const HandleProjectDelete = () => {
    loader(true);
    ApiClient.delete(`project?id=${DeleteId}`).then((result) => {
      if (result.success) {
        toast.success("Job  deleted Successfully");
        setShowDeleteModal("none");
        GetAllProjects();
      }
      loader(false);
    });
  };
  const pageChange = (e) => {
    setfilters({ ...filters, page: e });
    GetAllProjects({ page: e });
  };
  const HandleSearchSubmit = (e) => {
    e.preventDefault();
    if (SearchData == "") {
    } else {
      setfilters({ ...filters, search: SearchData });
      GetAllProjects({ search: SearchData });
    }
  };

  const HandleSearchFilter = (value) => {
    setfilters({ ...filters, search: "" });
    setSearchData("");
    GetAllProjects({ search: "" });
  };
  const statusChange = () => {
    // if(window.confirm(`Do you want to ${status=='active'?'Activate':'Deactivate'} this Project`)){
    loader(true);
    ApiClient.put(`change/status?model=projects`, {
      status: ActiveStatus,
      id: ActiveId,
    }).then((res) => {
      if (res.success) {
        setShowActiveModal("none");
        toast.success(
          `Job  ${ActiveStatus == "active" ? "Activated" : "Deactivated"
          } Successfully `
        );
        GetAllProjects();
      }
      loader(false);
    });
    // }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [TechnologyData, setTechnologyData] = useState([]);
  const [SelectedTechnology, setSelectedTechnology] = useState([]);
  const GetAllTechnologies = () => {
    loader(true);
    ApiClient.get(`technologies?status=active`).then((res) => {
      if (res.success) {
        const newdata = res.data;
        const array = [];
        newdata.map((item) => {
          array.push({ name: item.name, id: item.id });
        });
        setTechnologyData(array);
      }
    });
  };
  useEffect(() => {
    GetAllTechnologies();
  }, []);

  const HandleEditFunction = () => {
    document.getElementById("closePreviewModal").click();
    setProtofolioError1(false);
    setView(false);
    // setEditid(item.id);
    setEditData(PreviewData);
    setEdit(true);
    setimages1(PreviewData.documents);
    setSelectedSkills(PreviewData.skills);
    setSubmittedError(false);
    setSelectedTechnology(PreviewData.technology);
    document.getElementById("openprojectedit").click();
  };

  //  For Getting the Posted Time like days ago or other
  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    // const data = new Date(date).getMilliseconds();
    // const result = moment.utc(new Date(date)).local().startOf("date").fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };
  return (
    <>
      <Header />
      <CommonActiveModal
        setShow={setShowActiveModal}
        show={ShowActiveModal}
        confirm={statusChange}
        status={ActiveStatus}
      />
      <CommonDelete
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={HandleProjectDelete}
      />
      <section className="faq-section mainfaq job-posts-sect">
        <div className="container">
          <div className="row position-relative mt-5">
            <div className="col-lg-12">
              <div className="text-left">
                <h3 className="font-weight-bold mb-2">Job Posts</h3>
              </div>
              <div className="d-flex align-items-center justify-content-end mb-3 p-0">

                <div className="right main_flexbx d-flex align-items-center flex-wrap gap-2 justify-content-end">
                  <div className="  multiselect-input main_selects ">
                    <Multiselect
                      displayValue="name"
                      className="whiteskill skill_mar w-100"
                      placeholder="All Skills"
                      // autoBlur={true}
                      selectionLimit={3}
                      options={SkillsData}
                      // closeMenuOnSelect={true}
                      closeMenuOnSelect={true}
                      selectedValues={filters.skills}
                      onSelect={(e) => {
                        setfilters({ ...filters, skills: e });
                        GetAllProjects({ skills: e });
                      }}
                      onRemove={(e) => {
                        setfilters({ ...filters, skills: e });
                        GetAllProjects({ skills: e });
                      }}
                    />

                    {filters.skills && filters.skills.length == 0 ? null : (
                      <div className="mx-0 main_bs">
                        <button
                          className="btn btn-primary d-inline ml-2"
                          onClick={(e) => {
                            setfilters({ ...filters, skills: [] });
                            GetAllProjects({ skills: [] });
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    )}

                  </div>
                  <div className="searcbtns" >
                    <form
                      className=""
                      onSubmit={(e) => HandleSearchSubmit(e)}
                    >
                      <div className="input-group">
                        <input
                          type="search"
                          placeholder="Search"
                          onChange={(e) =>
                            e.target.value == ""
                              ? HandleSearchFilter(e.target.value)
                              : setSearchData(e.target.value)
                          }
                          id="form1"
                          className="form-control search_radius set_widthbx"
                        />
                        <div className="input-group-append">
                          <button
                            type="submit"
                            className="btn btn-primary pl-3 pr-3"
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="btn-fi">
                      <button
                        className="btn btn-primary "
                        onClick={(e) => Navigate.push("/addeditproject")}
                      >
                        Add Job Post{" "}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-12">
              {ProjectsData.map((item, index) => (
                <div className="card shadow mb-4">
                  <div className="pt-4 px-4 pb-3">
                    <div className="row ">
                      <div className="col-lg-6 border-right border-n">
                        <h5 className="job_post main-title">{item?.name || item?.title}</h5>

                        <div className="d-flex align-items-center my-2 col-changes">
                          <h5 className="job_post text-primary job-p-new">
                            {" "}
                            <i
                              class="fa fa-arrow-circle-right me-2"
                              aria-hidden="true"
                            ></i>
                            Price:
                          </h5>
                          <p className="type_cls mb-0">
                            {" "}
                            <div className="">
                              {item?.hourlyratestart}-{item?.hourlyrateEnd}{" "}
                              USD/hr
                            </div>
                          </p>
                        </div>

                        <div className="d-flex align-items-center col-changes">
                          <h5 className="job_post text-primary job-p-new">
                            {" "}
                            <i
                              class="fa fa-arrow-circle-right me-2"
                              aria-hidden="true"
                            ></i>
                            Public:
                          </h5>
                          <p className="type_cls mb-0">
                            {" "}
                            <div className="">
                              {GetPostedTime(item.createdAt)}
                            </div>
                          </p>
                        </div>
                        {/* <div className="d-flex align-items-center mt-2 col-changes">
                          <h5 className="job_post text-primary job-p-new">
                            {" "}
                            <i
                              class="fa fa-arrow-circle-right me-2"
                              aria-hidden="true"
                            ></i>
                            Skill Type:
                          </h5>
                          <p className="type_cls mb-0">
                            {" "}
                            <div className="">
                              {item.skillType && item.skillType.name}
                            </div>
                          </p>
                        </div> */}
                        <div className="d-flex align-items-center mt-2 col-changes">
                          <h5 className=" job_post text-primary job-p-new">
                            <i
                              class="fa fa-arrow-circle-right me-2"
                              aria-hidden="true"
                            ></i>
                            Job Description:
                          </h5>
                          <div
                            className=" type_cls  despcard mb-0 parah-ellipses"
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></div>
                        </div>
                      </div>
                    
                      <div className="col-lg-6">
                      <div className="row ">
                      {item.hardSkill_detail?.length != 0 ? 
                      <div className="col-lg-12 skillsbadge mb-3 ">
                        <div className="skills-sets">
                          <h5 className="job_post text-primary mb-3"> Hard Skills</h5>
                          {item.hardSkill_detail &&
                            item.hardSkill_detail.map((item) => (
                              <span className="bg_bages badgey-grey text-capitalize">
                                {item.name}
                              </span>
                            ))}
                        </div>
                      </div> : null}
                      {item?.softSkill_detail?.length != 0 ? 
                      <div className="col-lg-12 skillsbadge mt-2">
                        <div className="skills-sets">
                          <h5 className="job_post text-primary mb-3"> Soft Skills</h5>
                          {item.softSkill_detail &&
                            item.softSkill_detail.map((item) => (
                              <span className="bg_bages badgey-grey text-capitalize">
                                {item.name}
                              </span>
                            ))}
                        </div>
                      </div> : null}
                      </div>
                      </div>
                      
                     

                      <div className=" text-right dropdown-absolute">
                        <div className="dropdown dots_removes ">
                          <a
                            className="dropdown-toggle removeafter"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="material-icons">more_horiz</span>
                          </a>
                          <ul className="dropdown-menu">
                            <li
                              onClick={(e) => {
                                setActiceStatus(
                                  item.status == "active"
                                    ? "deactive"
                                    : "active"
                                );
                                setActiveId(item.id);
                                setShowActiveModal("block");
                              }}

                            >
                              <a className="dropdown-item">{item?.status == "ctive" ? <i class="fa fa-dot-circle-o red-color" aria-hidden="true"></i>
                                : <i class="fa fa-dot-circle-o me-2 capitalize" aria-hidden="true"></i>
                              }{capitalizeFirstLetter(item.status === "active" ? "Deactive" : "Active")}</a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={(e) => {
                                  Navigate.push(`/addeditproject/${item.id}/true`);
                                }}
                              ><i className="fa fa-copy me-2"></i>
                                Duplicate
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={(e) => {
                                  Navigate.push(`/addeditproject/${item.id}/false`);
                                }}
                              ><i className="fa fa-edit me-2"></i>
                                Edit
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={(e) => {
                                  setProtofolioError1(false);
                                  setEditid(item.id);
                                  setView(true);
                                  setEditData(item);
                                  setEdit(true);
                                  setimages1(item.documents);
                                  setSelectedSkills(item?.softSkill_detail?.map((item) => ({ id: item?.id || item?._id, name: item?.name })));
                                  setSubmittedError(false);
                                  setSelectedTechnology(item.technology);
                                }}
                              ><i className="fa fa-eye me-2"></i>
                                View
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={(e) => {
                                  setDeleteId(item.id);
                                  setShowDeleteModal("block");
                                }}
                              ><i className="fa fa-trash me-2"></i>
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end p-3 pt-0">
                    <Link
                      to={`/hire/${item.id}`}
                      className="btn btn-primary anchorbtn"
                    >
                      View Proposal
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {ProjectsData?.length == 0 ? (
              <div className="col-lg-12">
                <div className="text-center text-danger m-5">
                  <div>No Data</div>
                </div>
              </div>

            ) : null}
          </div>
          {total > filters.count ? (
            <div className="" style={{ float: "right" }}>
              <Pagination
                activePage={filters.page}
                itemClassPrev="back_page"
                itemClassNext="next_page"
                itemsCountPerPage={filters.count}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={pageChange}
              />
            </div>
          ) : null}
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {Edit ? (View ? "View" : "Update") : "Add"} Job Post
              </h5>
              <button
                type="button"
                className="close"
                id="closeprojectmodal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                name: Edit ? EditData?.title : "",
                description: Edit ? EditData.description : "",
                skillType: Edit ? EditData?.skillType : [],
                hardSkills: Edit ? EditData?.hardSkills : [],
                softSkills: Edit ? EditData?.softSkills : [],
                documents: [],
                technology: Edit ? EditData.technology : "",
                startDate: Edit ? EditData.startDate : "",
                endDate: Edit ? EditData.endDate : "",
                allowedHours: Edit ? EditData.allowedHours : "",
              }}
              onSubmit={(values, { resetForm }) => {
                setSubmittedError(true);
                const payload = {
                  id: Editid,
                  name: values?.name,
                  description: values.description,
                  technology: SelectedTechnology,
                  startDate: values.startDate,
                  endDate: values.endDate,
                  documents: images1,
                  skills: SelectedSkills,
                  allowedHours: values.allowedHours,
                };
                let method = "post";
                if (Edit) {
                  method = "put";
                } else {
                  delete payload.id;
                }
                if (
                  payload.skills.length == 0 ||
                  payload.technology.length == 0
                )
                  return false;
                if (payload.documents && payload.documents.length == 0) {
                  setProtofolioError1(true);
                  return false;
                } else {
                  setPreviewData({ ...payload });
                  {
                    if (!Edit && !Preview) {
                      setPreview(true);
                    } else {
                      loader(true);
                      ApiClient.allApi("project", payload, method).then(
                        (Res) => {
                          if (Res.success) {
                            document
                              .getElementById("closeprojectmodal")
                              .click();
                            GetAllProjects();
                            if (method == "post") {
                              setPreview(true);
                            } else {
                              setPreview(false);
                            }
                            toast.success(Res.message);
                            resetForm();
                            setPreview(false);
                          }
                          loader(false);
                        }
                      );
                    }
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleBlur }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    {/* {View?<i className="fa fa-edit text-info float-end" onClick={e=>setView(false)}></i>:null} */}
                    <div className="row">
                      {!Preview ? (
                        <>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="">Title</label>
                              <input
                                type="text"
                                required
                                disabled={View}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group mt-3">
                              <label htmlFor="">Hard Skills</label>
                              <p className="skills-set disable_ms">
                                {EditData.hardSkill_detail && EditData.hardSkill_detail.map((item) => {
                                  return <span className="btn btn-primary anchorbtn mr-2">{item?.name}</span>
                                })}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group mt-3">
                              <label htmlFor="">Hourly Rate</label>
                              <p className="skills-set">
                                {EditData.hourlyratestart} USD -{" "}
                                {EditData.hourlyrateEnd} USD
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="">Soft Skills</label>
                              <div className="skills-set">
                                <p className="skills-set disable_ms">
                                  {EditData.softSkill_detail && EditData.softSkill_detail.map((item) => {
                                    return <span className="btn btn-primary anchorbtn mr-2">{item?.name}</span>
                                  })}
                                </p>
                              </div>
                              {SubmittedError && SelectedSkills.length == 0 ? (
                                <p className="text-danger ml-2 mt-1 ">
                                  Skills are Required
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group all_skil">
                              <label htmlFor="">Description</label>
                              {View ? <div className="borderadius" style={{ backgroundColor: "#c4e1f16e" }} dangerouslySetInnerHTML={{ __html: values?.description }}></div> : <textarea
                                type="text"
                                required
                                disabled={View}
                                className="form-control"
                                value={values.description}
                                name="description"
                                onChange={handleChange}
                              ></textarea>}
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <div className="">
                              <label>Upload Documents</label>
                              {View ? null : (
                                <div className={`profile_btn_portfolio `}>
                                  <label className="add_portfolio edit ml-3">
                                    <input
                                      id="bannerImage"
                                      type="file"
                                      multiple={true}
                                      className="d-none"
                                      // accept="image/*"
                                      onChange={(e) => imageResult(e)}
                                    />
                                    <span className="add_portfolio">
                                      <i className="material-icons add_port">
                                        add
                                      </i>
                                    </span>
                                  </label>
                                </div>
                              )}
                            </div>

                            <div>
                              <br />
                              <div className="imagesRow ml-3">
                                {DoumentUploadLoading == true ? (
                                  <div className="text-success">
                                    Uploading...{" "}
                                    <i className="fa fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  images1.map((item, index) => (
                                    <div>
                                      <p className="text-capitalize">
                                        <img
                                          style={{ cursor: "pointer" }}
                                          width={40}
                                          className="document_image"
                                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                          onClick={(e) =>
                                            window.open(
                                              `${environment.api}images/document/${item}`
                                            )
                                          }
                                        />
                                        {View ? null : (
                                          <i
                                            className="fa fa-trash text-danger shadow-danger delet_icon"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              HanldDocumentDelete(e, index)
                                            }
                                          ></i>
                                        )}
                                      </p>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                            {ProtofolioError1 && images1.length == 0 ? (
                              <div className="text-danger text-center mt-3 ml-5">
                                Please Upload Documents{" "}
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="col-md-12 border-top border-bottom mt-3 pt-3 mb-3 pb-3">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <div className="d-flex">
                                {/* <img src={`${!item.addedByDetail&&item.addedByDetail==""?"/assets/img/person.jpg":methodModel.userImg(item.addedByDetail&&item.addedByDetail.image)} `} className='user_img'  /> */}
                                <div className="product_details">
                                  <p className="mb-4">
                                    <b className="text-primary font-weight-bold text-capitalize pointer">
                                      <a>{user.fullName}</a>
                                    </b>
                                  </p>
                                  <p>
                                    Hourly -Intermediate-Est. Time less then 1
                                    month, {values.allowedHours}hrs/week -
                                    posted just now{" "}
                                  </p>

                                  <div className="d-flex">
                                    <b>{user.hourlyRate} USD </b>{" "}
                                    <span className="ml-2 mr-5">/ hrs</span>
                                  </div>
                                  <p className="  mb-0">{values.description}</p>
                                  <div className="badges_project">
                                    {SelectedSkills.map((item) => (
                                      <span>{item.name}</span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className="post_btn d-flex">
                                <button className="btn btn-outline-primary header_like mr-2">
                                  <span className="material-icons">
                                    thumb_down_off_alt
                                  </span>
                                </button>
                                <button className="btn btn-outline-primary header_like">
                                  <span className="material-icons">
                                    favorite_border
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-12 text-right mt-3">
                        {Preview ? (
                          <button
                            type="button"
                            className="mr-2 btn btn-primary"
                            onClick={(e) => {
                              setGoArray([...GoArray, { Godd: "" }]);
                              setPreview(false);
                            }}
                          >
                            Edit
                          </button>
                        ) : null}
                        {View ? null : Edit ? (
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        ) : !Preview ? (
                          <button type="submit" className="btn btn-primary">
                            Preview
                          </button>
                        ) : (
                          <>
                            {" "}
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
