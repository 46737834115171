import { useEffect, useState } from 'react';
import Header from '../../components/global/header'
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';

export default function RecruiterDashboard() {
  const [Data, setData] = useState({});
  const [employerdata, setemployerData] = useState({})
  const GetDashboardData = () => {
    loader(true)
    ApiClient.get(`referal/count`).then(res => {
      if (res.success) {
        setData(res);
      }
      loader(false)
    })
  }


  const Getemployerdata = () => {
    ApiClient.get("virtual-referal/count").then((res) => {
      setemployerData(res)
    })
  }
  useEffect(() => {
    GetDashboardData()
    Getemployerdata()
  }, [])
  return (
    <div>
      <Header />
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-md-12'>
           <div className='mb-3'>
           <h2> Dashboard</h2>
           </div>

          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Total Invited</p>
              <div className=""><h2>{Data?.invitedCount}</h2>
                <p className="mb-0">

                </p></div></div></div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Registered Virtual Assistant</p>
              <div className=""><h2>{Data.registeredUser}</h2>
                <p className="mb-0">
                </p></div></div></div>

          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Onboard Virtual Assistant</p>
              <div className=""><h2>{Data.onboardUsers}</h2>
                <p className="mb-0">

                </p></div></div></div>


          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Total Commission</p>
              <div className=""><h2>{Data?.commissionAmount ? Data?.commissionAmount : 0}USD </h2>
                <p className="mb-0">
                </p></div></div></div>
        </div>
      </div>

      <div className='container mt-4'>
        <div className='row'>

          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Total Invited</p>
              <div className=""><h2>{employerdata?.invitedCount}</h2>
                <p className="mb-0">

                </p></div></div></div>
          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Registered Employer</p>
              <div className=""><h2>{employerdata.registeredUser}</h2>
                <p className="mb-0">
                </p></div></div></div>

          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Onboard Employer</p>
              <div className=""><h2>{employerdata.onboardUsers}</h2>
                <p className="mb-0">

                </p></div></div></div>


          <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="Sales_row card">
              <p>Total Commission</p>
              <div className=""><h2>{employerdata?.commissionAmount ? employerdata?.commissionAmount : 0} USD </h2>
                <p className="mb-0">
                </p></div></div></div>
        </div>
      </div>



    </div>
  )
}
