import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import Header from "../../components/global/header";
import { useSelector } from "react-redux";
import environment from "../../environment";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import methodModel from "../../methods/methods";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import axios from "axios";

export default function VATransactionTable() {
  const navigate = useHistory()
  const [SelectedVA, setSelectedVA] = useState("");
  const [filters, setfilters] = useState({
    search: "",
    status: "accepted",
  });
  const user = useSelector((state) => state.user);
  const [VAList, setVAList] = useState([]);
  const [Data, setData] = useState({
    VARATe: "----",
    RETAINER: "----",
    RETAINERLIMIT: "----",
  });
  const [data, setdata] = useState([]);
  const [TwoWeek, setTwoWeek] = useState(false)

  const [timeLogs, setTimeLogs] = useState([])
  const { startDate, endDate } = methodModel.getCurrentWeekDates();
  const today = endDate; // Get today's date
  const tomorrow = startDate; // Get tomorrow's date
  const [BillingFilters, setBillingFilters] = useState({
    contractId: "",
    start: moment(tomorrow).format("MM-DD-YYYY"),
    end: moment(today).format("MM-DD-YYYY"),
  });
  const [SelectedDateRange, setSelectedDateRange] = useState([dayjs(tomorrow), dayjs(today)]);
  const [ProjectDetails, setProjectDetails] = useState({});
  const [alignRecords, setAlignRecords] = useState([])

  const Getdata = (ID) => {
    if (!ID) {
      return;
    }
    loader(true);
    ApiClient.get(
      `transactions?contractId=${ID}&virtualId=${user?.id}&payment_to=adminTovirtual`
    ).then((res) => {
      if (res?.success) {
        const newdata = res?.data;
        setdata(newdata);
      }
      loader(false);
    });
  };

  function makeArrayUniqueById(array) {
    const uniqueIds = new Set();
    return array.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  const GetVALIST = () => {
    loader(true);
    const addedBy = user.id;
    const newfilters = { ...filters, addedBy: addedBy };
    ApiClient.get(`contracts`, newfilters).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setVAList(newdata);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetVALIST();
    Getdata();
    IsTwoWeek()
  }, []);
  const [contractId, setcontractId] = useState("")
  useEffect(() => {
    if (SelectedVA == "") {
      loader(true);
      setBillingFilters({ ...BillingFilters, contractId: "" })
      getTimeLogs({ contractId: "" })
      setTimeout(() => {
        loader(false);
      }, 300);
    } else {
      VAList.map((item) => {
        if (item?.project_details?._id == SelectedVA) {
          setData({ VARATe: item?.hourlyRate + " USD" });
          Getdata(item?.id);
          IsTwoWeek(item?.id)
          setcontractId(item?.id)
          setBillingFilters({ ...BillingFilters, contractId: item?.id })
          getTimeLogs({ contractId: item?.id })
        }
      });
    }
  }, [SelectedVA]);

  const GetRetainerData = () => {
    const userid =
      user && user.role.id == environment.EmployerId ? user.id : user.addedBy;
    loader(true);
    ApiClient.get(
      `retainer/list?virtualId=${SelectedVA}&employerId=${userid}`
    ).then((res) => {
      if (res.success) {
        const data = res.data;
        setData({
          VARATe: data[0]?.vaRate + " USD",
          RETAINER: data[0]?.retainerAmount + " USD",
          RETAINERLIMIT: data[0]?.retainerLimit + " USD",
        });
      }
      loader(false);
    });
  };
  const Getcalculculateminute = (res) => {
    let hours = parseInt(res?.total_billingHours);
    let minutes = parseInt(res?.total_billingMinutes);
    let remainder = minutes / 60;
    let quotient = minutes % 60;
    hours += parseInt(remainder);
    minutes = quotient;
    return `${hours || "00"} : ${minutes < 10 ? "0" + minutes : minutes || "00"}`;
  };

  const IsTwoWeek = (ID) => {
    if (!ID) {
      return;
    }
    ApiClient.get(`contract?id=${ID}`).then((res) => {
      if (res?.success) {
        setTwoWeek(res?.data?.isTwoWeek)
        setProjectDetails(res?.data)
      }
    })
  }

  const HandleReviewbutton = () => {
    navigate.push("/submit/hours/" + contractId)
  }

  const getTimeLogs = (p = {}) => {
    const newfilters = { ...BillingFilters, ...p, sortBy: "startTime desc" };
    if (newfilters?.contractId) {
      ApiClient.get(`billing/list`, newfilters).then(res => {
        if (res.success) {
          setAlignRecords(res?.data?.map((item, index) => {
            return ({ date: methodModel.convertDate(item?.startTime), hours: item?.totalDurationOfTime_hour || 0, minutes: item?.totalDurationOfTime_min || 0, check: checkLastDate(methodModel.convertDate(item?.startTime), res?.data?.[index + 1]?.startTime ? methodModel.convertDate(res?.data?.[index + 1]?.startTime) : "") })
          }))
          setTimeLogs(res?.data)
        }
      })
    } else {
      setTimeLogs([])
    }
  }

  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    setBillingFilters({
      ...BillingFilters,
      start: dates[0] || "",
      end: dates[1] || "",
    });
    getTimeLogs({ start: dates[0] || "", end: dates[1] || "" });
    // You can perform additional filtering or other actions based on the selected date range
  }

  const exportPdf = async () => {
    loader(true)
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}billing-pdf/frontend?contractId=${BillingFilters?.contractId}&projectId=${ProjectDetails?.projectId?.id || ""}&start=${BillingFilters?.start}&end=${BillingFilters?.end}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const blobUrl = URL.createObjectURL(blob);
    setTimeout(() => {
      fetch(blobUrl)
        .then((res) => res.json())
        .then(async (jsonData) => {
          const pdfUrl = jsonData.path;
          if (pdfUrl) {
            await fetch(`${environment.api}${pdfUrl}`)
              .then((res) => res.blob())
              .then((pdfBlob) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(
                  new Blob([pdfBlob], { type: "application/pdf" })
                );
                a.download = "TimeLogs.pdf";
                a.click();
                loader(false);
              })
          } else {
            loader(false);
          }
        })
    }, 3000);
    loader(false)
  }

  const getTotalHoursForDate = (targetDate) => {
    const totals = { hours: 0, minutes: 0 };

    alignRecords.forEach(record => {
      const { date, hours, minutes } = record;

      // Check if the record's date matches the target date
      if (date === targetDate) {
        // Add hours and minutes
        totals.hours += hours;
        totals.minutes += minutes;

        // Convert overflow minutes to hours
        if (totals.minutes >= 60) {
          const additionalHours = Math.floor(totals.minutes / 60);
          totals.hours += additionalHours;
          totals.minutes = totals.minutes % 60;
        }
      }
    });

    if (totals.hours === 0 && totals.minutes === 0) {
      return `0 hrs 0 mins`;
    }

    return `${totals?.hours} hrs ${totals?.minutes} mins`;
  }

  const checkLastDate = (startDate, endDate) => {
    if (endDate) {
      if (startDate === endDate) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  return (
    <>
      <Header />
      <div className="container mt-4">
        <div className="d-flex  justify-content-between align-items-center mb-5">
          <div className="">
            <label>Contract</label>
            <br />
            <select
              value={SelectedVA}
              className="form-control"
              onChange={(e) => {
                setSelectedVA(e.target.value);
              }}
            >
              <option value="">Select Contract</option>
              {VAList.map((item) => (
                <option
                  value={item?.project_details?._id}
                  className="text-capitalize"
                >
                  {item?.project_details?.name || item?.project_details?.title}
                </option>
              ))}
            </select>
          </div>
          <div className="hourly-rate">
            <label>Hourly Rate</label>
            <p className="">{SelectedVA != "" ? Data.VARATe : "----"}</p>
          </div>
        </div>

        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Transactions</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Time Logs</button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            {!SelectedVA ? null : <div className="float-right mb-2">
              <button className="btn btn-primary " onClick={e => HandleReviewbutton()}>Review Hours</button>
            </div>}
            <div className="table-responsive">
              {" "}
              <table className="table table-bordered table-hover ">
                <thead>
                  <th>Contract Name</th>
                  <th>Virtual Name</th>
                  <th>Period</th>
                  <th>Credit</th>
                  <th>Total Hours</th>
                  <th>Status</th>
                  {/* <th>Document</th> */}
                </thead>
                {!SelectedVA
                  ? null
                  : data.map((itm) => (
                    <tbody>
                      <td>{itm?.contractName}</td>
                      <td>{itm?.virtual_details?.fullName}</td>
                      <td>
                        {itm?.payment_startTime ? <>
                          {moment(itm?.payment_startTime).format("DD MMM")} -{" "}
                          {moment(itm?.payment_endTime).format("DD, YYYY")}
                        </> : "---"}
                      </td>

                      <td>{itm?.total_billingAmount ? parseFloat(itm?.total_billingAmount).toFixed(2) : "00"} USD</td>
                      <td>{Getcalculculateminute(itm) || "---"} hrs</td>
                      <td>Paid</td>
                      {/* <td className="pointer text-primary">
                      <img
                        style={{
                          cursor: "pointer",
                          height: "90px",
                          width: "90px",
                        }}
                        className="document_image"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                        onClick={(e) =>
                          window.open(
                            `${environment.api}images/transactions/${itm?.document}`
                          )
                        }
                      />
                    </td> */}
                    </tbody>
                  ))}
                <td colspan="7" className="border-bottom-main">
                  <div className="d-flex justify-content-center align-items-center mb-0 w-100 py-3 ">
                    {!SelectedVA ? (
                      <p className="text-center">Please select your contract</p>
                    ) : data?.length == 0 ? (
                      <h3 className="text-center">No Data</h3>
                    ) : null}
                  </div>
                </td>
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div className="d-flex justify-content-end">
              <RangePicker placeholder={["Start Date", "End Date"]} format="MM-DD-YYYY" onChange={handleDateChange} value={SelectedDateRange} className="p-2" />
              <button className="btn btn-primary ml-4" onClick={e=>exportPdf()}>Export PDF</button>
            </div>
            <div className='table-responsive mt-4'> <table className='table table-hover'>
              <thead>
                <tr>
                  <th>Memo</th>
                  <th>Start Date & Time</th>
                  <th>End Date & Time</th>
                  <th>Total Hours</th>
                  <th>Total Hours Per Day</th>
                </tr>
              </thead>
              <tbody>
                {timeLogs.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.memo}</td>
                    <td>{methodModel.convertDate(user?.role?.id == environment?.EmployerId ? convertTimezone(item?.startTime, ProjectDetails?.timeZone?.value, user?.globallyTimeZone?.value) : item?.startTime)}{" "}{methodModel.ConvertTime(user?.role?.id == environment?.EmployerId ? convertTimezone(item?.startTime, ProjectDetails?.timeZone?.value, user?.globallyTimeZone?.value) : item?.startTime)}</td>
                    <td>{methodModel.convertDate(user.role?.id == environment?.EmployerId ? convertTimezone(item?.endTime, ProjectDetails?.timeZone?.value, user?.globallyTimeZone?.value) : item?.endTime)}{" "}{methodModel.ConvertTime(user.role?.id == environment?.EmployerId ? convertTimezone(item?.endTime, ProjectDetails?.timeZone?.value, user?.globallyTimeZone?.value) : item?.endTime)}</td>
                    <td>{`${item?.totalDurationOfTime_hour || 0} hr ${item?.totalDurationOfTime_min || 0} min`}</td>
                    <td>{alignRecords?.[index]?.check === true ? <>{getTotalHoursForDate(moment(item?.startTime).format("MM/DD/YYYY"))}</> : null}</td>
                  </tr>
                ))}
              </tbody>
            </table></div>
            {timeLogs.length == 0 ? <div className='text-center text-danger mt-2'>
              <h5>No Time Logs Data</h5>
            </div> : null}
          </div>
        </div>
      </div>
    </>
  );
}
