import { useState } from 'react';
import './style.scss';
import methodModel from '../../methods/methods';

const Budget = ({ setCount, setMyForm, MyForm }) => {

  const [Submitted, setSubmitted] = useState(false);

  const HandleNext = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (MyForm.hourlyratestart == "" || MyForm.hourlyrateEnd == "") return false;
    setCount(3)
  }

  return (
    <>
      <div className="bg-white pt-4 ">
        <div className="container pl-5 pr-5 mt-5">
          <div className="row ">
            <div className="col-lg-6">
              <p>Job Post</p>
              <h2>Tell us about your budget.</h2>
              <p>This will help us match you to talent within your range</p>
            </div>
            <div className="col-lg-6">
              <div className='rate_box pl-4 pr-4 pl-4 pt-3 pb-4'>
                <i className='fa fa-clock'></i>
                <h5 className='mt-3'><b>Hourly rate</b></h5>
              </div>
              <div className='rate_from'>
                <div>
                  <b>From</b>
                  <div className='d-flex align-items-center me-2'>
                    <input type='text' value={MyForm.hourlyratestart} maxLength={12} onChange={e => setMyForm({ ...MyForm, hourlyratestart: methodModel.isNumber(e) })} className="form-control mt-1 mr-2" /> USD/hr
                  </div>
                </div>
                <div className=''>
                  <b>to</b>
                  <div className='d-flex align-items-center'>
                    <input type='text' value={MyForm.hourlyrateEnd} maxLength={12} onChange={e => setMyForm({ ...MyForm, hourlyrateEnd: methodModel.isNumber(e) })} className="form-control mt-1 mr-2" /> USD/hr
                  </div>
                </div>
              </div>
              <p className='mt-3'>This is the average rate for similar projects.</p>
              {Submitted && MyForm.hourlyratestart == "" ? <div className='text-center text-danger'>This Field is Required</div> : null}
              {Submitted && MyForm.hourlyratestart != "" && MyForm.hourlyrateEnd == "" ? <div className='text-center text-danger'>This Field is Required</div> : null}
            </div>
          </div>
        </div>
      </div>
      <div className="footer_btn">
        <button className="btn btn-outline-primary" onClick={e => setCount(1)}>Back</button>
        <button className="btn btn-primary" onClick={e => HandleNext(e)}  >Next: Description</button>
      </div>

    </>
  );
};

export default Budget;