import Header from "../../components/global/header";
import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import Pagination from "react-js-pagination";
import methodModel from "../../methods/methods";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./style.scss";
import Multiselect from "multiselect-react-dropdown";
import SavedJob from "../Savedjob copy/SavedJob";

export default function JobSearch() {
  const Navigate = useHistory();
  const params = new URLSearchParams(window.location.search);
  const Searchdata = params.get("search");
  const [CountrySearch, setCountrySearch] = useState("");
  const [Skills, setSkills] = useState([]);
  const [Options, setOptions] = useState([]);
  const user = useSelector((state) => state.user);
  const [ProjectsData, setProjectsData] = useState([]);
  const [ViewProjectData, setViewProjectData] = useState({});
  const [data, setData] = useState([]);
  const [currentDate, setcurrentDate] = useState(new Date());
  const [Filters, setFilters] = useState({
    search: Searchdata,
    status: "active",
    page: 1,
    count: 50,
    skills: [],
    technology: [],
    country: [],
    numberofPorposal: [],
    status: "active",
    minProposal1: "",
    maxProposal1: "",
    minProposal2: "",
    maxProposal2: "",
    minProposal3: "",
    maxProposal3: "",
    minProposal4: "",
    maxProposal4: "",
  });
  const [total, setTotal] = useState(0);
  const GetAllprojects = (p = {}) => {
    const newfilters = {
      ...Filters,
      ...p,
      // skills: p.skills
      //   ? p.skills.map((item) => item.value).toString()
      //   : Filters.skills.map((item) => item.value).toString(),
      technology: p.technology
        ? p.technology.map((item) => item.id).toString()
        : Filters.technology.map((item) => item.id).toString(),
      country: p.country
        ? p.country.map((item) => item).toString()
        : Filters.country.map((item) => item).toString(),
      numberofPorposal: p.numberofPorposal
        ? p.numberofPorposal.map((item) => item).toString()
        : Filters.numberofPorposal.map((item) => item).toString(),
    };
    loader(true);
    ApiClient.get(`posted/projects`, newfilters).then((result) => {
      if (result.success) {
        const data = result.data;
        setTotal(result.total);
        setProjectsData(result.data);
      }
      loader(false);
    });
  };

  //  For Getting the Regions Listing With Virtual Assistance count
  const [RegionsData, setRegionsData] = useState([]);
  const GetRegions = (p = {}) => {
    loader(true);
    ApiClient.get(`postCountBylocation`, p).then((res) => {
      if (res.success) {
        setRegionsData(res.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetRegions();
  }, []);

  const [CategoryData, setCategoryData] = useState([]);
  const GetCategoryData = () => {
    ApiClient.get(`skill/types/frontend`).then((res) => {
      if (res.success) {
        setCategoryData(res.data);
        const newdata = res.data;
        const array = [];
        newdata.map((type) => {
          type.skilltype &&
            type.skilltype.map((item) => {
              array.push({ cat: type.name, key: item.name, value: item._id });
            });
        });
        setOptions(array);
      }
    });
  };
  useEffect(() => {
    GetCategoryData();
  }, []);

  // ################## For handling the Regions ##################
  const HandleRegionsFilter = (e) => {
    const { checked, value } = e.target;
    const array = Filters.country;
    if (checked) {
      array.push(value);
      setFilters({ ...Filters, country: array });
      GetAllprojects({ country: array });
    } else {
      const newarray = [];
      array.filter((item) => {
        if (item != value) {
          newarray.push(item);
        }
      });
      setFilters({ ...Filters, country: newarray });
      GetAllprojects({ country: newarray });
    }
  };

  const Pushuser = (url) => {
    history.push(`${url}`);
  };

  const HandleExperienceFilter = (e) => {
    const { checked, value } = e.target;
    const array = Filters.experience;
    if (checked) {
      array.push(value);
      setFilters({ ...Filters, experience: array });
      GetAllprojects({ experience: array });
    } else {
      const newarray = [];
      array.filter((item) => {
        if (item != value) {
          newarray.push(item);
        }
      });
      setFilters({ ...Filters, experience: newarray });
      GetAllprojects({ experience: newarray });
    }
  };

  //  For Handling the Number of Porposal
  const NumberOfPorposalHandle = (e) => {
    const { checked, value } = e.target;
    const array = Filters.numberofPorposal;
    if (checked) {
      array.push(value);
      setFilters({ ...Filters, numberofPorposal: array });
      GetAllprojects({ numberofPorposal: array });
    } else {
      const newarray = [];
      array.filter((item) => {
        if (item != value) {
          newarray.push(item);
        }
      });
      setFilters({ ...Filters, numberofPorposal: newarray });
      GetAllprojects({ numberofPorposal: newarray });
    }
  };

  const HandleShortlist = (e, id) => {
    loader(true);
    e.preventDefault();
    const payload = { projectId: id, type: "project" };
    ApiClient.post(`add_sortlist`, payload).then((res) => {
      if (res.success) {
        document.getElementById("closeJobDetails1").click();
        GetAllprojects();
      }
      loader(false);
    });
  };

  //  For Pagination We need this Function
  const pageChange = (e) => {
    setFilters({ ...Filters, page: e });
    GetAllprojects({ page: e });
  };

  useEffect(() => {
    GetAllprojects();
  }, []);

  const HandleCountrySearch = (e) => {
    e.preventDefault();
    GetRegions({ countryName: CountrySearch });
  };
  const HandleSearchFilter = (value) => {
    setFilters({ ...Filters, search: value });
    setTimeout(() => {
      GetAllprojects({ search: value });
    }, 2000);
  };
  const HandleSearchSubmit = (e, value = Filters.search) => {
    e.preventDefault();
    GetAllprojects({ search: value });
  };
  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };
  const HandleRemoveShortlist = (e, id) => {
    loader(true);
    e.preventDefault();
    const payload = { projectId: id };
    ApiClient.delete(`sortlist`, payload).then((res) => {
      if (res.success) {
        document.getElementById("closeJobDetails1").click();
        GetAllprojects();
      }
      loader(false);
    });
  };

  const HandleMultipleSelect = (e) => {
    // setfilters({ ...filters, skills: e.map((item) => item.value).toString() })
    // GetVirtualListing({ skills: e.map((item) => item.value).toString() })

    setSkills(e.target.value);
    setFilters({ ...Filters, skills: e.target.value });

    try {
      clearTimeout(TimeoutSearch);
    } catch {
      console.log("");
    }
    const TimeoutSearch = setTimeout(() => {
      GetAllprojects();
    }, 1000);
  };
  // / Here we need to do coding for the Getting the Right time
  const [PorposalData, setPorposalData] = useState([]);
  const GetPorposal = () => {
    loader(true);
    ApiClient.get(`noOfProposal`).then((res) => {
      if (res.success) {
        const newdata = res.data && res.data;
        setPorposalData(res.data);
      }
    });
  };
  useEffect(() => {
    GetPorposal();
  }, []);

  //   //  For Getting the Job Post Count according to the Job Posts
  //   const GetJobPostCount = ()=>{
  //     loader(true);
  //     ApiClient.get(`postCountBylocation`).then(res=>{
  //       if(res.success){
  // setRegionsData(res.data)
  //       }
  //       loader(false);
  //     })
  //   }

  //   useEffect(()=>{
  //    GetPorposal()
  //    GetJobPostCount()
  //   },[])

  return (
    <>
      <Header />

      <div className="vertual_container mt-5 mb-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3 col-md-4 stick-position">
              <div className="sticky">
                <h4 className="mt-4">Filter By</h4>
                {/* <RangeSlider
            min={5}
            step={1}
            max={100}
            // value={[1,50]}
            onInput={e=>{setFilters({...filters,hourlyRateStart:e[0],hourlyRateEnd:e[1]});GetAllProjects({hourlyRateStart:e[0],hourlyRateEnd:e[1]})}}
            />
            <p>${filters.hourlyRateStart} ${filters.hourlyRateEnd}</p> */}

                <div className="accordion mt-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <a
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <h4 className=" mb-0 headign_Cat">Category</h4>
                      </a>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {/* <Multiselect
                        displayValue="key"
                        groupBy="cat"
                        // onKeyPressFn={function noRefCheck(){}}
                        onRemove={(e) => HandleMultipleSelect(e)}
                        // onSearch={function noRefCheck(){}}
                        onSelect={(e) => HandleMultipleSelect(e)}
                        selectedValues={Skills}
                        options={Options}
                        showCheckbox
                      /> */}
                        <input
                          value={Skills}
                          onChange={(e) => HandleMultipleSelect(e)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <hr className="mt-3" />

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <a
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <h4 className=" mb-0 headign_Cat">Client Location</h4>
                      </a>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="input-group mb-1">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i
                                className="fas fa-search"
                                onClick={(e) => HandleCountrySearch(e)}
                              ></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            placeholder="Select client locations"
                            onKeyPress={(e) =>
                              e.key == "Enter" ? HandleCountrySearch(e) : null
                            }
                            onChange={(e) =>
                              e.target.value == ""
                                ? GetRegions({ search: "" })
                                : setCountrySearch(e.target.value)
                            }
                            className="form-control "
                            aria-label="Text input with checkbox"
                          />
                        </div>
                        <div className="mt-3">
                          {/* <p className='search_data'>Regions</p> */}

                          {RegionsData.map((item, indedx) => {
                            return item.country != "" ? (
                              <div className="input-group">
                                <div className="row w-100">
                                  <div className="col-12">
                                    <div className="d-flex align-items-center input-box mb-2">
                                      {" "}
                                      <input
                                        value={item.country}
                                        checked={Filters.country.includes(
                                          item.country
                                        )}
                                        onChange={(e) => HandleRegionsFilter(e)}
                                        type="checkbox"
                                        className=""
                                      />
                                      <label className="mb-0">
                                        {item.country} ({item.sum})
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null;
                          })}
                          {RegionsData.length == 0 ? (
                            <div className="text-danger">No Data Found</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-3" />
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <a
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <h4 className=" mb-0 headign_Cat">Hourly Rate</h4>
                      </a>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="d-flex align-items-center">
                          <div className="input-group mb-0">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                USD
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              value={Filters.hourlyRateStart}
                              placeholder="Min"
                              onChange={(e) =>
                                setFilters({
                                  ...Filters,
                                  hourlyRateStart: e.target.value,
                                })
                              }
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                          <span className="ml-1 mr-1">-</span>
                          <div className="input-group mb-0">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                USD
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              value={Filters.hourlyRateEnd}
                              min={Filters.hourlyRateStart}
                              placeholder="Max"
                              onChange={(e) =>
                                setFilters({
                                  ...Filters,
                                  hourlyRateEnd: e.target.value,
                                })
                              }
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <hr className="mt-3" /> */}

                  {/* <div  className="accordion-item">
                  <h2  className="accordion-header">
                    <a
                       className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <h4 className=" mb-0 headign_Cat">Experience</h4>
                    </a>
                  </h2>
                  <div
                    id="collapseFour"
                     className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div  className="accordion-body">
                      <div className="mt-2">
                        <div className="input-group">
                          <input
                            value="entryLevel"
                            // checked={Filters.experience.includes("entryLevel")}
                            onChange={(e) => HandleExperienceFilter(e)}
                            type="checkbox"
                            className="mr-2"
                          />
                          <label className="mb-1">EntryLevel</label>
                        </div>
                        <div className="input-group">
                          <input
                            onChange={(e) => HandleExperienceFilter(e)}
                            value="intermediate"
                            // checked={Filters.experience.includes(
                            //   "intermediate"
                            // )}
                            type="checkbox"
                            className="mr-2"
                          />
                          <label className="mb-1">Intermediate</label>
                        </div>
                        <div className="input-group">
                          <input
                            onChange={(e) => HandleExperienceFilter(e)}
                            value="advance"
                            // checked={Filters.experience.includes("advance")}
                            type="checkbox"
                            className="mr-2"
                          />
                          <label className="mb-1">Advance</label>
                        </div>
                        <div className="input-group">
                          <input
                            onChange={(e) => HandleExperienceFilter(e)}
                            value="expert"
                            // checked={Filters.experience.includes("expert")}
                            type="checkbox"
                            className="mr-2"
                          />
                          <label className="mb-1">Expert</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <hr className="mt-3" />
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <a
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <h4 className=" mb-0 headign_Cat">
                          Number of proposals
                        </h4>
                      </a>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="mt-2 mt-1">
                          <div className="input-group">
                            <div className="row w-100">
                              <div className="col-12">
                                <div className="d-flex align-items-center input-box mb-2">
                                  <input
                                    value="0-5"
                                    // checked={Filters.experience.includes("entryLevel")}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFilters({
                                          ...Filters,
                                          minProposal1: 0,
                                          maxProposal1: 5,
                                        });
                                        GetAllprojects({
                                          minProposal1: 0,
                                          maxProposal1: 5,
                                        });
                                      } else {
                                        setFilters({
                                          ...Filters,
                                          minProposal1: "",
                                          maxProposal1: "",
                                        });
                                        GetAllprojects({
                                          minProposal1: "",
                                          maxProposal1: "",
                                        });
                                      }
                                    }}
                                    type="checkbox"
                                    className=""
                                  />
                                  <label className="mb-0">
                                    Less than 5 (
                                    {PorposalData[0] &&
                                      PorposalData[0]["less than 5"]}
                                    )
                                  </label>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="input-group">
                            <div className="row w-100">
                              <div className="col-12">
                                <div className="d-flex align-items-center input-box mb-2" >
                                  {" "}
                                  <input
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFilters({
                                          ...Filters,
                                          minProposal2: 5,
                                          maxProposal2: 10,
                                        });
                                        GetAllprojects({
                                          minProposal2: 5,
                                          maxProposal2: 10,
                                        });
                                      } else {
                                        setFilters({
                                          ...Filters,
                                          minProposal2: "",
                                          maxProposal2: "",
                                        });
                                        GetAllprojects({
                                          minProposal2: "",
                                          maxProposal2: "",
                                        });
                                      }
                                    }}
                                    value="5-10"
                                    // checked={Filters.experience.includes(
                                    //   "intermediate"
                                    // )}
                                    type="checkbox"
                                    className=""
                                  />
                                  <label className="mb-0">
                                    5 to 10 (
                                    {PorposalData[1] &&
                                      PorposalData[1]["5 to 10"]}
                                    )
                                  </label>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="input-group">
                            <div className="row w-100">
                              <div className="col-12">
                                <div className="d-flex align-items-center input-box mb-2">
                                  <input
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFilters({
                                          ...Filters,
                                          minProposal3: 10,
                                          maxProposal3: 15,
                                        });
                                        GetAllprojects({
                                          minProposal3: 10,
                                          maxProposal3: 15,
                                        });
                                      } else {
                                        setFilters({
                                          ...Filters,
                                          minProposal3: "",
                                          maxProposal3: "",
                                        });
                                        GetAllprojects({
                                          minProposal3: "",
                                          maxProposal3: "",
                                        });
                                      }
                                    }}
                                    value="10-15"
                                    // checked={Filters.experience.includes("advance")}
                                    type="checkbox"
                                    className=""
                                  />


                                  <label className="mb-0">
                                    10 to 15 (
                                    {PorposalData[2] &&
                                      PorposalData[2]["10 to 15"]}
                                    )
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="input-group">
                            <div className="row w-100">
                              <div className="col-12">
                                <div className="d-flex align-items-center input-box">
                                  <input
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFilters({
                                          ...Filters,
                                          minProposal4: 15,
                                          maxProposal4: 20,
                                        });
                                        GetAllprojects({
                                          minProposal4: 15,
                                          maxProposal4: 20,
                                        });
                                      } else {
                                        setFilters({
                                          ...Filters,
                                          minProposal4: "",
                                          maxProposal4: "",
                                        });
                                        GetAllprojects({
                                          minProposal4: "",
                                          maxProposal4: "",
                                        });
                                      }
                                    }}
                                    value="15-20"
                                    // checked={Filters.experience.includes("expert")}
                                    type="checkbox"
                                    className=""
                                  />
                                  <label className="mb-0">
                                    15 to 20 (
                                    {PorposalData[3] &&
                                      PorposalData[3]["15 to 20"]}
                                    )
                                  </label>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3" />
                </div>

                {/* <span className='mt-2 mr-1' style={{fontSize:"20px"}}>$</span>
          <input type="number" placeholder='Min'  onChange={e=>setFilters({...filters,hourlyRateStart:e.target.value})}   className="form-control col-md-3 ml-2"  /> <span className='m-2'>-</span>
           <span className='mt-2' style={{fontSize:"20px"}}>$</span>
          <input type="number" placeholder='Max'  onChange={e=>setFilters({...filters,hourlyRateEnd:e.target.value})}   className="form-control col-md-3 ml-2" aria-label="Text input with checkbox" /><i style={{fontSize:"20px"}} onClick={e=>GetVirtualListing()} className='fa fa-search m-2'></i> */}
                <div className="text-right btn_virtual mt-4">
                  <button
                    className="btn btn-secondary mt-2 mr-2"
                    onClick={(e) => {
                      setFilters({
                        skills: "",
                        hourlyRateEnd: "",
                        hourlyRateStart: "",
                        search: "",
                        country: [],
                        experience: [],
                      });
                      GetAllprojects({
                        skills: "",
                        hourlyRateEnd: "",
                        hourlyRateStart: "",
                        search: "",
                        country: [],
                        experience: [],
                      });
                      setSkills("");
                    }}
                  >
                    Clear
                  </button>
                  <button
                    onClick={(e) => GetAllprojects()}
                    className="btn btn-primary mt-2"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-8 mb-4">
              <div className="maindiv_boder filter-bottom">
                {/* tabs */}
                <div className="tabscls p-3">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        data-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Search
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        data-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Saved Jobs{" "}
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="sticky_search mb-3 mt-3">
                        <form
                          className="job_search"
                          onSubmit={(e) => HandleSearchSubmit(e)}
                        >
                          <div className="input-group">
                            <input
                              type="search"
                              placeholder="Search for Job"
                              value={Filters.search}
                              onChange={(e) => {
                                if (e.target.value == "") {
                                  setFilters({ ...Filters, search: "" });
                                  HandleSearchSubmit(e, "");
                                } else {
                                  setFilters({
                                    ...Filters,
                                    search: e.target.value,
                                  });
                                }
                              }}
                              id="form1"
                              className="form-control d-flex-inline var_search pl-4"
                            />
                            <div className="input-group-append">
                              <button
                                type="submit"
                                className="btn btn-primary   pl-3 pr-3"
                              >
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="tab_div pl-4 pt-4 pb-0 border-bottom">
                        <h3 className="mb-4 Jobs_Jobs">Jobs you might like</h3>
                      </div>

                      {ProjectsData?.map((item, index) => (
                        <div className="col-md-12 border-bottom mt-3 pt-3 mb-3 pb-3 jobsearch_box">
                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex">
                              <div className="product_details">
                                <p className="mb-4">
                                  <b
                                    className="text-primary font-weight-bold text-capitalize pointer"
                                    onClick={(e) => setViewProjectData(item)}
                                  >
                                    <a
                                      data-bs-toggle="offcanvas"
                                      data-bs-target="#offcanvasRight1"
                                      aria-controls="offcanvasRight1"
                                    >
                                      {item.name}
                                    </a>
                                  </b>
                                </p>
                                {/* <p className="bold_hedding">{item.name}</p> */}
                                <p className="gay_title">
                                  {/* <span className="hourly_rate">
                                    Hourly -
                                    {item.addedByDetail &&
                                      item.addedByDetail.hourlyRate}
                                    USD
                                  </span> */}
                                  <span className="hourly_rate">
                                    Hourly - Approx {" "}{item.hourlyratestart}{" "}
                                    USD
                                    {/* - {item.hourlyrateEnd} USD */}
                                  </span>{" "}
                                  - posted {GetPostedTime(item.createdAt)}
                                </p>

                                <p
                                  className=" description_cls  mb-0"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                >
                                  {/* {item.description} */}
                                </p>


                                {/* slider */}
                                {/* <div id="carouselExampleControls"  className="carousel slide" data-ride="carousel">
  <div  className="carousel-inner">
    <div  className="carousel-item active">
    <div className="badges_project">
                    
                        {item.skills &&
                          item.skills.map((item, index) => (
                            <span>{item.name}</span>
                          ))}
                      </div>
    </div>
    <div  className="carousel-item">
      <img src="..."  className="d-block w-100" alt="..."/>
    </div>
    <div  className="carousel-item">
      <img src="..."  className="d-block w-100" alt="..."/>
    </div>
  </div>
 <button  className="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
    <span  className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span  className="sr-only">Previous</span>
  </button>
  <button  className="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
    <span  className="carousel-control-next-icon" aria-hidden="true"></span>
    <span  className="sr-only">Next</span>
  </button>
</div> */}

                                {/* end */}
                                <div className="badges_project">
                                  {item.skills &&
                                    item.skills.map((item, index) => (
                                      <span className="mt-0">{item.name}</span>
                                    ))}
                                </div>

                                <h3 className="lessthan_cls mt-3">
                                  <span className="Proposals_cls">
                                    Proposals:{" "}
                                  </span>
                                  {methodModel.GetNumberRange(
                                    item.totalJobApply
                                  )}
                                </h3>

                                <div>
                                  {/* {item?.addedByDetail?.isAchpaymentVerified ? (
                                    <i
                                      className="fa fa-check-circle mr-1"
                                      aria-hidden="true"
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-times-circle mr-1"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                  <span className=" ">
                                    Payment verified
                                  </span> */}
                                  {item?.addedByDetail?.isAchpaymentVerified ? (
                                    <i
                                      className="fa fa-check-circle mr-1"
                                      aria-hidden="true"
                                    ></i>
                                  ) : (
                                    // <i
                                    //   className="fa fa-times-circle"
                                    //   aria-hidden="true"
                                    // ></i>
                                    <></>
                                  )}
                                  {item?.addedByDetail?.isAchpaymentVerified ? <span className="ml-1">Payment verified</span> : ""}
                                  <div className="my-2">
                                    {methodModel.generateStarRating(
                                      Number(item?.addedByDetail?.totalRating)
                                    )}
                                  </div>
                                  <b>
                                    {/* {item.hourlyratestart} USD -{" "}
                                    {item.hourlyrateEnd} USD */}
                                    Approx{" "} {item.hourlyratestart}{" "} USD
                                    {/* - {item.hourlyrateEnd} USD */}
                                    {" "}
                                  </b>{" "}
                                  <span className="gray_cls">/hrs</span>
                                  {/* <span className="gray_cls ml-2"><i  className="fa fa-map-marker" aria-hidden="true"></i> location</span> */}
                                  {item.addedByDetail &&
                                    item.addedByDetail.country != "" ? (
                                    <span className="gray_cls ml-2">
                                      <span className="material-icons location">
                                        location_on
                                      </span>{" "}
                                      {item.addedByDetail.country}{" "}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              {/* <div className="d-flex">
                        <span className="mt-3 mr-5">
                          Price:{" "}
                          <b>
                            $
                            {item.addedByDetail &&
                              item.addedByDetail.hourlyRate}
                          </b>
                          <span className="gray_cls">/hrs</span>
                        </span>
                    </div> */}
                            </div>
                            <div className="post_btn d-flex">
                              <button className="btn btn-outline-primary header_like mr-2">
                                <span className="material-icons">
                                  thumb_down_off_alt
                                </span>
                              </button>
                              {/* <button className="btn btn-outline-primary header_like"> */}

                              <a
                                title={item.isShort ? "unfavorite" : "favorite"}
                                className="fav_icon_revealeant "
                                onClick={(e) => {
                                  item.isShort
                                    ? HandleRemoveShortlist(e, item.id)
                                    : HandleShortlist(e, item.id);
                                }}
                              >
                                <span className={`material-icons`}>
                                  {item.isShort
                                    ? "favorite"
                                    : "favorite_border"}
                                </span>
                              </a>
                              {/* </button> */}
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* {ProjectsData.length == 0 ? (
                      <div className="text-danger text-center mt-5">No Data</div>
                    ) : null} */}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <SavedJob />
                    </div>
                  </div>
                </div>

                {/* end */}
              </div>
            </div>
          </div>
          <div className="pagination-custom">
            {total > Filters.count ? (
              <Pagination
                activePage={Filters.page}
                itemClassPrev="back_page"
                itemClassNext="next_page"
                itemsCountPerPage={Filters.count}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={pageChange}
              />
            ) : null}
          </div>
          {/* offcanva */}
          <div
            className="offcanvas offcanvas-end space_canvas"
            tabIndex="-1"
            id="offcanvasRight1"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              {/* <h5  className="offcanvas-title" id="offcanvasRightLabel">
            Post Detail
          </h5> */}
              {/* <button
            type="button"
             className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          > </button>  */}
              <i
                className='fa fa-angle-left newback data-bs-dismiss="offcanvas"
            aria-label="Close"'
                type="button"
                id="closeJobDetails1"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></i>
            </div>

            <div className="offcanvas-body">
              <div className="card">
                <div className="row">
                  <div className="col-md-8 pr-0">
                    <div className="p-3">
                      <h3>{ViewProjectData.name}</h3>
                      <p className="text-primary mt-3">
                        {ViewProjectData.skillType &&
                          ViewProjectData.skillType.name}
                      </p>

                      <p>Posted {GetPostedTime(ViewProjectData.createdAt)}</p>
                    </div>
                    <hr />
                    <div
                      className="p-3"
                      dangerouslySetInnerHTML={{
                        __html: ViewProjectData.description,
                      }}
                    ></div>
                    <hr />
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="price_pro pl-4 d-flex">
                          <span className="material-icons price_local mt-1">
                            local_offer
                          </span>
                          <div className="ml-2">
                            <b>
                              {" "}
                              {ViewProjectData.hourlyratestart} USD -
                              {ViewProjectData.hourlyrateEnd} USD
                            </b>
                            <p>Budget</p>
                          </div>
                        </div>
                        <div className="price_pro d-flex pr-4">
                          <span className="material-icons price_locals">
                            manage_accounts
                          </span>
                          <div className="ml-2">
                            <b>Intermediate</b>
                            <p>
                              I am looking for a mix of <br /> experience and
                              value
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="badges_project mb-2">
                        <p className="text-bold d-inline skills_txt mr-1">
                          Skills:
                        </p>{" "}
                        {ViewProjectData.skills &&
                          ViewProjectData.skills.map((item, index) => (
                            <span>{item.name}</span>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4  pl-0 border-left">
                    <div className="p-3 text-center">
                      <button
                        className="btn btn-primary mb-3  appybtn"
                        disabled={
                          ViewProjectData.isJobApplied == true ? true : false
                        }
                        onClick={(e) => {
                          document.getElementById("closeJobDetails1").click();
                          Navigate.push(
                            `/perposal/${ViewProjectData._id}?apply=job`
                          );
                        }}
                      >
                        {!ViewProjectData.isJobApplied
                          ? "Apply Now"
                          : "Applied"}
                      </button>
                      <button
                        title={
                          ViewProjectData.isShort ? "unfavorite" : "favorite"
                        }
                        className="btn btn-outline-primary mb-3 appybtn"
                        onClick={(e) => {
                          ViewProjectData.isShort
                            ? HandleRemoveShortlist(e, ViewProjectData.id)
                            : HandleShortlist(e, ViewProjectData.id);
                        }}
                      >
                        {" "}
                        <span className={`material-icons`}>
                          {ViewProjectData.isShort
                            ? "favorite"
                            : "favorite_border"}
                        </span>
                        <span className=" ">Saved Job</span>
                      </button>
                      {/* <p>Send a proposal for:12 Connects</p> */}
                      {/* <p>Available Connects: 60</p> */}
                    </div>
                    <hr />
                    <div className="p-3">
                      <h6 className="mb-3">
                        <b>About the client</b>
                      </h6>
                      <h6 className="mb-3">
                        <b>
                          {ViewProjectData.addedByDetail &&
                            ViewProjectData.addedByDetail.companyName}
                        </b>
                      </h6>
                      <br />
                      <p className="mb-3 mt-2">
                        <b>
                          {ViewProjectData.addedByDetail &&
                            ViewProjectData.addedByDetail.city}
                          ,
                          {ViewProjectData.addedByDetail &&
                            ViewProjectData.addedByDetail.country}
                        </b>
                      </p>
                      <p className="mb-3">
                        {ViewProjectData.addedByDetail &&
                          ViewProjectData.addedByDetail.timeZone &&
                          ViewProjectData.addedByDetail.timeZone.label}
                      </p>
                      <b>8 Jobs Posted</b>
                      <p>25% hire rate, 4 open jobs</p>
                      <b>
                        USD
                        {ViewProjectData.addedByDetail &&
                          ViewProjectData.addedByDetail.hourlyRate}{" "}
                        /hr avg hourly rate paid
                      </b>
                      <p>56 hours</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
