import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./style.scss";
import { Link } from "react-router-dom";

const Forgotpassword = () => {
  const history = useHistory();

  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/dashboard");
    }
  }, []);

  const [form, setForm] = useState({ email: "" });

  const hendleSubmit = (e) => {
    e.preventDefault();
    loader(true);
    ApiClient.post("forgot/password/frontend", {
      ...form,
      role: "64b15102b14de6c28838f7d2",
    }).then((res) => {
      if (res.success) {
        history.push("/login?message=" + res.message);
      }
      loader(false);
    });
  };

  return (
    <>
      <div className="main_signup">
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-xl-4 col-lg-6 col-md-8">
        <div className="top_section top-sect-new">
              <div className="right_side pt-4">
                <div className="logo_image">
                  <img src="/assets/img/Logo_new.png" className="logo_name" />
                </div>
                <form className="centerLogin" onSubmit={hendleSubmit}>
                  <div className="text-center mb-4">
                    <h3 className="text-left lgtext">Forgot password?</h3>
                  </div>
                  <p className="para_forget">
                    "It's all good! Enter your email, and we'll send a reset
                    password link your way"
                  </p>
                  <div className="mb-3">
                    <div className="inputWrapper">
                      <input
                        type="email"
                        className="form-control  mb-0 bginput changes"
                        placeholder="Email*"
                        value={form.email}
                        required
                        onChange={(e) =>
                          setForm({ ...form, email: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="buttons">
                    <button
                      type="submit"
                      className="btn btn-primary loginclass mb-4"
                    >
                      Send Recovery Email
                    </button>
                  </div>

                  <p className="accopuntt">
                    {" "}
                    Just Remember?
                    <Link className="sign_up" to="/signup">
                      {" "}
                      Sign Up
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
