import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import methodModel from "../../../methods/methods";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import moment from "moment";
import { ConnectSocket, SocketURL } from "../SocketNetwork/ConnectSocket";
import axios from "axios";
import { MessageCount } from "../../../actions/search";
import environment from "../../../environment";
import { toast } from "react-toastify";

export default function HelpersHeader({
  isOpen,
  toggle,
  searchHandle,
  search,
  isOpen1,
  searchChange,
  clear,
  Logout,
  setrole,
}) {
  const MessageCounter = useSelector((state) => state.MessageCount);
  const dispatch = useDispatch();
  const pathname = location.pathname;
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();

  const [NotificationTotal, setNotificationTotal] = useState(0);
  const [Notifications, setNotifications] = useState([]);
  const [NotificationLoader, setNotificationLoader] = useState(false);
  const GetAllNotification = () => {
    setNotificationLoader(true);
    ApiClient.get(`notifications?page=1&count=5&status=unread`).then((res) => {
      if (res.success) {
        setNotificationTotal(res.total);
        setNotifications(res.data);
      }
      setNotificationLoader(false);
      loader(false);
    });
  };
  useEffect(() => {
    if (user.loggedIn) {
      GetAllNotification();
    }
  }, []);
  const GetPostedTime = (date) => {
    const result = moment(date).fromNow();
    if (result == "an hour ago") {
      return "1hr ago";
    } else {
      return result;
    }
  };

  const HandleReadNotification = (id) => {
    loader(true);
    ApiClient.put(`notification?id=${id}`, { notificationStatus: "read" }).then(
      (res) => {
        GetAllNotification();
        loader(false);
      }
    );
  };

  const [InviteRejectReason, setInviteRejectReason] = useState("");
  const HandePushNotification = (e, item) => {
    e.preventDefault();
    if (item.type == "applyjob") {
      Navigate.push(`/details/${item.applyJobId}`);
    } else {
      if (item.inviteId_details && item.inviteId_details.status == "rejected") {
        setInviteRejectReason(
          item.inviteId_details && item.inviteId_details.reason
        );
        document.getElementById("openreject").click();
      } else {
        Navigate.push(
          `/proposaldetail/${
            item?.inviteId_details && item.inviteId_details?._id
          }`
        );
      }
      if (
        item.type == "workSchedule" ||
        item.type == "workScheduleAccepted" ||
        item.type == "workScheduleRejected" ||
        item.type == "billingSchedule" ||
        item.type == "timeZone"
      ) {
        Navigate.push(`/dmcontracts`);
      }
      if (
        item.type == "contract" ||
        item.type == "contractAccepted" ||
        item.type == "contractRejected"
      ) {
        Navigate.push(`/offer/${item.contractId}`);
      }
    }
    HandleReadNotification(item._id);
  };

  useEffect(() => {
    ConnectSocket.emit(`user-online`, { user_id: user.id });
    return () => {
      // localStorage.removeItem("trackingid");
      ConnectSocket.emit(`user-offline`, { user_id: user.id });
    };
  }, []);

  const GetAllMessageUnreadCount = () => {
    axios
      .get(`${SocketURL}chat/user/unread-count/all?user_id=${user.id}`)
      .then((newres) => {
        if (newres) {
          const res = newres.data && newres.data.data;
          dispatch(MessageCount(res.unread_count ? res.unread_count : 0));
        }
      })
      .catch((err) => {
        toast.error("Error Occurred");
      });
  };
  useEffect(() => {
    if (user.loggedIn) {
      GetAllMessageUnreadCount();
    }
  }, []);

  useEffect(() => {
    ConnectSocket.on(`unread-noti-count`, (data) => {
      const newdata = data.data;
      if (newdata.user_id == user.id && localStorage.getItem("token")) {
        setNotificationTotal(newdata.unread_count);
      }
    });

   
  }, []);

  const HandlePushUser = (url) => {
    if (user.loggedIn) {
      Navigate.push(`${url}`);
    } else {
      Navigate.push("/login");
    }
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary d-none"
        id="openreject1"
        data-toggle="modal"
        data-target="#exampleModalCenter1"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Reason of Rejection
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{InviteRejectReason}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button"  className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>

      <nav
        component="header"
        className="  navbar navbar-expand-lg navbar-light bg-light"
      >
        <Link to="/">
          <img
            className="myvirtualpalLogo151Icon"
            alt=""
            src="/assets/img/myvirtualpallogo15-1@2x.png"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="header navbar-nav justify-content-center align-items-center">
            {user.role && user.role.id == environment.AccountManagerId ? (
              <li
                onClick={(e) => HandlePushUser("/work-diary")}
                className="nav-item nav-link heder_drop"
              >
                Account Transaction
              </li>
            ) : null}
            {user.role && user.role.id == environment.DirectManagerId ? (
              <li
                onClick={(e) => HandlePushUser("/dmcontracts")}
                className="nav-item nav-link heder_drop ml-3"
              >
                All Requests
              </li>
            ) : null}
            <li
              onClick={(e) => HandlePushUser("/blogs")}
              className="nav-item nav-link heder_drop"
            >
              Blogs
            </li>
            <Link to="/faq">
              <li className="nav-item nav-link heder_drop">FAQs</li>
            </Link>
          </ul>

          {user.loggedIn ? (
            <li className="noti_fication">
              <div className="dropdown">
                <a
                  className="dropdown-toggle position-relative remove_after"
                  onClick={(e) => GetAllNotification()}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="material-icons">notifications_none</span>
                  {NotificationTotal != 0 && user.loggedIn ? (
                    <span className="red_dot">{NotificationTotal}</span>
                  ) : null}
                </a>
                <ul className="dropdown-menu noti_dropdown pb-0 pt-0">
                  {NotificationLoader ? (
                    <div className="text-center">
                      <i className="fa fa-loader">Loading.....</i>
                    </div>
                  ) : (
                    Notifications.map((item, index) => (
                      <li className="border-bottom" key={index}>
                        <a
                          className="dropdown-item"
                          onClick={(e) => HandePushNotification(e, item)}
                        >
                          <div className="d-flex icon_bell">
                            <div className="img_msg">
                              <img
                                src={methodModel.userImg(
                                  item.addedBy_details &&
                                    item.addedBy_details.image
                                )}
                                className="small_user"
                                alt=""
                              />
                            </div>
                            <p className="ml-3 noti_data">
                              {item.title}
                              <br />
                              <small>{GetPostedTime(item.createdAt)}</small>
                            </p>
                          </div>
                        </a>
                      </li>
                    ))
                  )}
                  {!NotificationLoader && Notifications.length == 0 ? (
                    <div className="text-center text-danger">
                      <p className="pt-3">No Notification Here</p>
                    </div>
                  ) : null}

                  <li>
                    {NotificationTotal > 5 ? (
                      <Link
                        to="/notifications"
                        className="dropdown-item pb-3 see_All"
                      >
                        See all notification
                      </Link>
                    ) : null}
                  </li>
                </ul>
              </div>
            </li>
          ) : null}
        </div>
        {user.loggedIn ? (
          <>
            <div className="dropdown ml-auto headerleft">
              <a
                data-toggle="dropdown"
                className="nav-link dropdown-toggle nav-link-user text-dark d-flex align-items-center"
              >
                <img
                  alt="image"
                  src={methodModel.userImg(user.image)}
                  width={40}
                  height={40}
                  className="rounded-circle mr-1"
                />
              </a>
              <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover prodiv_cl">
                <div className="text-center">
                  <img
                    alt="image"
                    src={methodModel.userImg(user.image)}
                    width={50}
                    height={50}
                    className="rounded-circle drop_innerimg mb-2"
                  />
                  <div className="ml-1 ">
                    <b className="mt-2">{user.fullName}</b>
                    <p className="dropgray mb-0 text-capitalize mt-1 w-100">
                      {user.role?.name}
                    </p>
                  </div>
                </div>

                <Link to="/profile" className="dropdown-item has-icon">
                  <i className="far fa-user mr-1" /> Profile
                </Link>
                <Link to="/change" className="dropdown-item has-icon ">
                  <i className="fa fa-cog mr-1" aria-hidden="true"></i> Change
                  Password
                </Link>
                <a
                  id="WantLogout"
                  onClick={() => {
                    Logout();
                    setNotificationTotal(0);
                  }}
                  className="dropdown-item has-icon"
                >
                  <i className="fas fa-sign-out-alt mr-1" /> Logout
                </a>
              </div>
            </div>
          </>
        ) : null}
      </nav>
    </>
  );
}
