import { useEffect, useRef, useState } from 'react';
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import Header from '../../components/global/header';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select"
import methodModel from '../../methods/methods';
import { Editor } from '@tinymce/tinymce-react';
import environment from '../../environment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function AddEditProject() {
  const { id, duplicate } = useParams();
  const user = useSelector(state => state.user);
  const NewComeThrough = new URLSearchParams(window.location.search);
  const PlatformData = useSelector(state => state.PlatformfeeReducer);
  const AssumeCome = NewComeThrough.get('comethrough')
  const [Submitted, setSubmitted] = useState(false);
  const Navigate = useHistory();
  const [MyForm, setMyForm] = useState({ id: "", title: "", hourlyratestart: "", hourlyrateEnd: "", description: "", documents: [], skillType: [], softSkills: null })
  const [hardSkills, setHardSkills] = useState([])
  const [softSkills, setSoftSkills] = useState([])
  const [aiDescription, setAiDescription] = useState()
  const [ReleveantData, setReveleantData] = useState([]);
  const [GetProjectid, setProjectid] = useState("");

  const GetVirtualAssistantSkill = () => {
    loader(true);
    const skillTypeIds = MyForm.hardSkills?.map(itm => itm?.value);
    const payload = new URLSearchParams({
      skillType: skillTypeIds.join(','),
      employerId: user.id
    });
    ApiClient.get(`virtual/assistants?${payload.toString()}`)
      .then(res => {
        if (res.success) {
          setReveleantData(res.data);
        }
        loader(false);
      })
  }

  useEffect(() => {
    if (id) {
      ApiClient.get(`project?id=${id}`).then(res => {
        if (res.success) {
          const data = res.data;
          setMyForm({
            id: id,
            title: duplicate == "true" ? `Copy of ${data?.title}` : data?.title,
            hourlyratestart: data?.hourlyratestart,
            hourlyrateEnd: data?.hourlyrateEnd,
            description: data?.description,
            documents: data?.documents,
            skillType: data?.skillType?.map((item) => ({ id: item?.id, name: item?.name })),
            softSkills: data?.softSkills?.map((item) => ({ value: item?.id, label: item?.name })),
            hardSkills: data?.hardSkills?.map((item) => ({ value: item?.id, label: item?.name })),
          })
          setimages1(data?.documents)
        }
      })
    }
  }, [id]);

  const [SkillTypes, setSkillTypes] = useState([]);
  const SkillType = () => {
    loader(true);
    ApiClient.get(`skill/types?status=active`).then(res => {
      if (res.success) {
        let array = [];
        res?.data?.map((itm) => {
          array.push({ id: itm?.id || itm?._id, name: itm?.name });
        });
        setSkillTypes(array)
        // setSkillTypes(res.data);
      }
      loader(false);
    })
  }

  useEffect(() => {
    SkillType();
    getHardSkills()
    getSoftSkills()
    if (AssumeCome) {
      setMyForm({ ...MyForm, comethrough: AssumeCome })
    }
  }, []);

  const HandleNext = (e) => {
    e.preventDefault();
    document.getElementById("OpenPreviewModal").click()
  }

  const handleTechnicalSkills = (e) => {
    setMyForm({ ...MyForm, skillType: e, hardSkills: null })
    const ids = e?.map((item) => item?.id)
    getHardSkills(ids)
  }

  const getHardSkills = (professions = []) => {
    ApiClient.get(`skill/types?status=active&id=${String(professions)}`).then((res) => {
      if (res.success) {
        setHardSkills(res?.data?.map((item) => {
          return ({
            label: item?.name, options: item?.hardSkillDetails?.map((itm) => {
              return ({ value: itm?.id || itm?._id, label: itm?.name })
            })
          })
        }))
      }
    })
  }

  const getSoftSkills = () => {
    ApiClient.get(`skills?status=active`).then((res) => {
      if (res.success) {
        setSoftSkills(res?.data?.map((itm) => {
          return ({ value: itm?.id || itm?._id, label: itm?.name })
        }))
      }
    })
  }

  const handleChange = (selectedOptions) => {
    setMyForm({ ...MyForm, hardSkills: selectedOptions });
    if (selectedOptions?.length > 0) {
      setAiDescription(`Provide me a job description base on the following data for posting it on the platform where the others can see  on the behalf of these skills ${selectedOptions?.map((item) => item?.label.toString())}`)
    } else {
      setAiDescription("")
    }
  };

  // Description component
  const OpenDocumentRef = useRef();
  const [AILoader, setAILoader] = useState(false);
  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [images1, setimages1] = useState(MyForm.documents);
  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images1];
    array.splice(index, 1);
    setimages1(array);
    setMyForm({ ...MyForm, documents: array })
  };
  const imageResult = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      let newarray = images1;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        setDocumentUploadLoading(true);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          file: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            if (files.length == images1.length) {
              setDocumentUploadLoading(false)
            }
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });
        i++;
      }
      setimages1(newarray);
      setMyForm({ ...MyForm, documents: newarray })
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  const HandleAIFunction = async (e) => {
    e.preventDefault()
    setAILoader(true);
    const header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${'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'}`,
    }
    const payload = {
      messages: [
        { "role": "user", "content": `${aiDescription}` }
      ],
      max_tokens: 800,
      temperature: 1,
      model: 'gpt-3.5-turbo',
      n: 1,
      stop: null,
    }
    console.log(payload, '==payload');

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        payload,
        {
          headers: header,
        }
      );
      setAILoader(false)
      // Assuming the response format is the same as GPT-3, extract the text
      setMyForm({ ...MyForm, description: response.data.choices[0].message.content })
    } catch (error) {
      setAILoader(false)
      console.error('Error in GPT-4 request:', error);
      throw error;
    }
  }

  const handleSave = () => {
    setSubmitted(true);
    let method = "post";
    const payload = {
      ...MyForm,
      skillType: MyForm?.skillType?.map((itm) => itm?.id.toString()),
      hardSkills: MyForm?.hardSkills?.map((item) => item?.value),
      softSkills: MyForm?.softSkills?.map((item) => item?.value),
    }
    if (MyForm.id != "" && duplicate == "false") {
      method = "put"
    }
    else {
      delete payload?.id;
    }
    ApiClient.allApi("project", payload, method).then(Res => {
      if (Res.success) {
        GetVirtualAssistantSkill()
        toast.success(`Job  ${method == "post" ? "posted" : "updated"} successfully`)
        document.getElementById("ClosePreviewModal").click()
        if (id) {
          Navigate.push("/project")
        } else { document.getElementById("congratulationmodal").click(); }
        setProjectid(Res.data && Res.data.id);
      }
      loader(false);
    })
  }

  return (
    <div>
      <Header />
      <form onSubmit={HandleNext}>
        <div className="bg-white main_jobsara pt-4">
          <div className="container px-sm-5 mt-sm-5 mb-4  " >
            <div className="row">
              <div className="col-lg-12 fonttext jobmain mb-4">
                <label className='Job_titles' >Job Title<span className='text-danger'>*</span></label>
                <textarea placeholder='Write a title for your job post' type='text' value={MyForm.title} onChange={e => setMyForm({ ...MyForm, title: e.target.value })} className="form-control" rows={1} required />
                {Submitted && MyForm.title == "" ? <div className=' mt-3 text-center text-danger'>Job Title is Required</div> : null}
              </div>
              <div className='col-lg-12 fonttext jobmain mb-4'>
                <label className='Job_titles'>Professions<span className='text-danger'>*</span></label>
                <Multiselect
                  options={SkillTypes}
                  selectedValues={MyForm?.skillType}
                  onSelect={(e) => handleTechnicalSkills(e)}
                  onRemove={(e) => handleTechnicalSkills(e)}
                  placeholder={`${MyForm?.skillType == "" ? "Select Professions" : ""}`}
                  displayValue="name"
                />
              </div>
              <div className='col-lg-12 fonttext jobmain mb-4'>
                <label className='Job_titles'>Hard Skills<span className='text-danger'>*</span></label>
                <div className='d-flex softskillsdd mmddd_positon '  style={{zIndex:'20'}}>
                  <Select
                    placeholder="Select Hard Skills"
                    className="text-capitalize dddskills w-100"
                    value={MyForm?.hardSkills}
                    onChange={handleChange}
                    options={hardSkills}
                    isClearable
                    closeMenuOnSelect={false}
                    required
                    isMulti
                  />
                  {/* <i class="fa fa-sort-desc  hard_down " aria-hidden="true"></i> */}
                </div>
              </div>
              <div className='col-lg-12 fonttext jobmain mb-4'>
                <label className='Job_titles'>Soft Skills<span className='text-danger'>*</span></label>
                <div className='d-flex softskillsdd mmddd_positon' style={{zIndex:'10'}}>
                  <Select
                    placeholder="Choose Soft Skills"
                    className="text-capitalize dddskills w-100"
                    value={MyForm?.softSkills}
                    onChange={e => setMyForm((prev) => ({ ...prev, softSkills: e }))}
                    options={softSkills}
                    closeMenuOnSelect={false}
                    isClearable
                    required
                    isMulti
                  />
                  {/* <i class="fa fa-sort-desc  hard_down " aria-hidden="true"></i> */}
                </div>
              </div>
              <div className='col-lg-12 fonttext mb-4'>
                <div className=' main_rate-fomrs'>
                  <h5 className='mb-2 Hourlydd'>Hourly rate</h5>
                  <div className='row'>
                    <div className='col-md-6' >
                      <div className='full_div' >
                        <div className='d-flex align-items-center me-2'>
                          <input type='text' placeholder='From' value={MyForm?.hourlyratestart} maxLength={12} onChange={e => setMyForm({ ...MyForm, hourlyratestart: methodModel.isNumber(e) })} required className="form-control mt-1 mr-2" /> USD/hr
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='full_div' >
                        <div className='d-flex align-items-center'>
                          <input type='text' placeholder='To' value={MyForm?.hourlyrateEnd} maxLength={12} onChange={e => setMyForm({ ...MyForm, hourlyrateEnd: methodModel.isNumber(e) })} required className="form-control mt-1 mr-2" /> USD/hr
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className='mt-3 averagerate mb-0'>This is the average rate for similar projects.</p>
                {Submitted && MyForm.hourlyratestart == "" ? <div className='text-center text-danger'>This Field is Required</div> : null}
                {Submitted && MyForm.hourlyratestart != "" && MyForm.hourlyrateEnd == "" ? <div className='text-center text-danger'>This Field is Required</div> : null}
              </div>
              <div className='col-lg-12 fonttext mb-4'>
                <label className='Job_titles'>Job Description</label>
                <div className="mt-2" style={{zIndex:1}}>
                  <Editor
                    apiKey={environment?.tinyKey}
                    value={MyForm?.description}
                    onEditorChange={(newValue, editor) => {
                      setMyForm({
                        ...MyForm,
                        description: newValue,
                      })
                    }}
                  />
                </div>
                <div className='d-flex mt-3 justify-content-between mb-5 '>
                  <div><button type='button' className='attach_filess' onClick={e => OpenDocumentRef.current.click()}> <span className="material-icons me-2 attachment">attachment</span>Attach file</button>
                    <p className='my-0 text-danger fx-normal'>Max file size: 100 MB</p>
                  </div>
                  <div>
                    <button type='button' disabled={AILoader} className='btn btn-primary aibtn' onClick={e => { document.getElementById('OpenAiModal').click() }}>{AILoader ? <img src='/assets/img/loader.gif' className='loadgif' /> : "Use AI"}</button>
                    {Submitted == true && MyForm.description == "" ? <div className='text-danger text-center'>Please fill the Detail first</div> : null}
                  </div>
                </div>
                <div className="">
                  <div className="">
                    <div className={`profile_btn_portfolio d-none `}>
                      <label className="add_portfolio edit ml-3">
                        <input
                          id="bannerImage"
                          type="file"
                          multiple={true}
                          ref={OpenDocumentRef}
                          className="d-none"
                          onChange={(e) => imageResult(e)}
                        />
                        <span className="add_portfolio">
                          <i className="material-icons add_port">add</i>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="imagesRow d-flex flex-wrap">
                      {DoumentUploadLoading == true ? (
                        <div className="text-success">
                          Uploading...{" "}
                          <i className="fa fa-spinner fa-spin"></i>
                        </div>
                      ) : (
                        images1.map((item, index) => (
                          <div>
                            <p className="text-capitalize">
                              <img
                                style={{ cursor: "pointer" }}
                                width={40}
                                className="document_image"
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                onClick={(e) =>
                                  window.open(
                                    `${environment.api}images/document/${item}`
                                  )
                                }
                              />
                              <i
                                className="fa fa-trash text-danger shadow-danger delet_icon"
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  HanldDocumentDelete(e, index)
                                }
                              ></i>
                            </p>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type='button' className='d-none' id='opencategorymodal' data-toggle="modal" data-target="#categoryModal" ></button>
        <div className="footer_btn">
          <button type='button' className="btn btn-outline-primary" onClick={e => Navigate.push("/project")} >Back</button>
          <button type='submit' className="btn btn-primary">{id && duplicate == "false" ? "Update" : "Save"}</button>
        </div>
      </form>

      <button type="button" id='congratulationmodal' class="btn btn-primary d-none" data-toggle="modal" data-target="#congratulationsModal">
        Open Congratulations Modal
      </button>

      <div class="modal fade" data-keyboard="false" data-backdrop="static" id="congratulationsModal" tabindex="-1" role="dialog" aria-labelledby="congratulationsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">

              <button type="button" class="close" data-dismiss="modal" onClick={e => Navigate.push('/project')} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className='text-center congo-modal'>
                <img src="/assets/img/tick-grey.png" />
                <h5 class="modal-title pt-3 pb-0" id="congratulationsModalLabel">Congratulations!</h5>
                <p className='mb-0 mt-1'> Your job has been successfully posted.</p>
              </div>
            </div>
            <div class=" text-center mx-auto mb-5 mt-3">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={e => document.getElementById("openreveleant").click()}>Explore relevant Virtual Assistant</button>
            </div>
          </div>
        </div>
      </div>

      <button type="button" className="btn btn-primary d-none" id='openreveleant' data-toggle="modal" data-target="#OpenModalOf">
        Launch demo modal
      </button>
      <div className="modal fade" data-keyboard="false" data-backdrop="static" id="OpenModalOf" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Relevant Virtual Assistant</h5>
              {/* <button type="button"  data-dismiss="modal" } >Skip</button> */}

              <button type="button" className=" btn btn-secondary" data-dismiss="modal" onClick={e => Navigate.push("/project")} aria-label="Close">
                Skip
              </button>
            </div>
            <div className="modal-body">
              {ReleveantData.map((item) => (
                <div>
                  <div className='d-flex justify-content-between mb-3'>
                    <div className='d-flex align-items-center'>
                      <img src={methodModel.userImg(item.image)} className='reveleant_user' />
                      <div className='ml-3'>
                        <a className='font-weight-bold text-capitalize'>{item.fullName}</a>
                        <p className='mt-2 mb-0'>
                          <b>{item.country}</b>
                        </p>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <a onClick={e => { item.isShortListed ? HandleRemoveShortlist(e, item.id) : HandleShortlist(e, item.id) }} className='fav_icon_revealeant'><span className={`material-icons `}>{item.isShortListed ? "favorite" : "favorite_border"}</span></a>
                      <button className='btn btn-primary ml-3' data-dismiss="modal" aria-label="Close" onClick={e => Navigate.push(`/virtualdetails/${item.id}?projectid=${GetProjectid}`)}>View Details</button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p>{parseFloat(methodModel.CalculatePercentAmount(item.hourlyRate, PlatformData?.platformFee) + item.hourlyRate).toFixed(2)} USD/hr</p>
                    <p>8k+ USD earned</p>
                    <p className='font-weight-bold'><span className='material-icons shield_icon'>shield</span>{item.skillType && item.skillType.name}</p>
                  </div>
                  <p>{item.description}</p>
                  <p className='border-top py-2 mt-4'></p>
                  <b>Skills</b>
                  <div className='d-flex flex-wrap skills-set'>
                    {item.hardSkill_detail && item.hardSkill_detail.map((itm, index) => (<span className='gray_badges'>{itm.name}</span>))}
                  </div>


                  <hr />
                </div>

              ))}
              {ReleveantData.length == 0 ? <div className='text-danger text-center mt-4 mb-4'>No Relevant Vistual Assistant</div> : null}
            </div>

          </div>
        </div>
      </div>

      {/* AI Description Modal */}
      <button type="button" id="OpenAiModal" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">Launch demo modal</button>
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <form onSubmit={HandleAIFunction}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">AI Description</h1>
              </div>
              <div className="modal-body">
                <textarea type='text' value={aiDescription} onChange={e => setAiDescription(e.target.value)} className='form-control' placeholder='Enter Description...' rows={5} required />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" id="CloseAiModal" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Generate</button>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Preview Modal */}
      <button type="button" id="OpenPreviewModal" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#PreviewModal">Launch demo modal</button>
      <div className="modal fade perviwbx" id="PreviewModal" tabindex="-1" aria-labelledby="PreviewModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom">
              <div className='d-flex justify-content-between w-100'>
                <h1 className="modal-title fs-5" id="PreviewModalLabel">Preview Job Post</h1>
                <button type="button" className="btn btn-secondary" id="ClosePreviewModal" data-bs-dismiss="modal"> <i class="fa fa-pencil-square-o mr-2" aria-hidden="true"></i>
                Edit</button>
              </div>
            </div>
            <div className="modal-body">
              <div className='form-group'>
                <label className="fw-medium" >Job Title: </label>
                <p className=' mb-0 p_titles'> {MyForm?.title}</p>
              </div>
              <div className='form-group'>
                <label className="fw-medium" >Professions: </label>
                <p className='mb-0 d-flex gap-2 align-items-center  flex-wrap set_jobss p_titles' >
                {MyForm?.skillType?.map((item) => {
                  return <span className=' mb-0 btn btn-primary '> {item?.name}</span>
                })} 
                </p>
               
              </div>
              <div className='form-group'>
                <label className="fw-medium" >Hard Skills: </label>
                <p className='mb-0 d-flex gap-2 align-items-center  flex-wrap set_jobss p_titles' > 
                {MyForm?.hardSkills?.map((item) => {
                  return <span className=' mb-0 btn btn-primary '> {item?.label}</span>
                })}
                </p>
               
              </div>
              <div className='form-group'>
                <label className="fw-medium" >Soft Skills: </label>
                <p className='mb-0 d-flex gap-2 align-items-center  flex-wrap set_jobss p_titles' >
                {MyForm?.softSkills?.map((item) => {
                  return <span className=' mb-0 btn btn-primary '> {item?.label}</span>
                })}
                   </p>
            
              </div>
              <div className='form-group'>
                <label className="fw-medium" >Hourly rate: </label>
                <p className=' mb-0 p_titles'>{MyForm?.hourlyratestart} - {MyForm?.hourlyrateEnd}</p>
              </div>
              <div className='form-group'>
                <label className="fw-medium" >Job Description: </label>
                <p className=' mb-0 p_titles' dangerouslySetInnerHTML={{ __html: MyForm?.description }}></p>
              </div>
              <div className='form-group'>
                <label className="fw-medium" >Document: </label>
                {MyForm?.documents?.map((item) => {
                  return <p className=' mb-0 p_titles'>
                    <img style={{ cursor: "pointer" }} width={40} className="document_image" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU" />
                  </p>
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={e => handleSave()} className="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
