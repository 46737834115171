import { useEffect, useRef, useState } from "react";
import AddEditNotice from "./AddEditNotice";
import Header from "../../components/global/header";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import environment from "../../environment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import ViewNotice from "./ViewNotice";
import methodModel from "../../methods/methods";

export default function NoticeList() {
  const user = useSelector((state) => state.user);
  const ModalCloserRefView = useRef();
  const ModalCloserRef = useRef();
  const [entries, setentries] = useState([]);
  const [data, setdata] = useState({});
  const [view, setview] = useState(false);
  const [StaticFilters, setStaticFilters] = useState({
    type:
      user.role && user.role.id == environment.EmployerId
        ? "employer"
        : "virtual",
  });

  const [total, settotal] = useState(0);
  const GetData = (p = {}) => {
    loader(true);
    const newfilters = { ...StaticFilters, ...p };
    ApiClient.get(`notice/list`, newfilters).then((res) => {
      if (res.success) {
        setentries(res.data);
        settotal(res?.total);
      }
      loader(false);
    });
  };
  const HandleDeleteFunction = (id) => {
    loader(true);
    ApiClient.delete(`?id=${id}`).then((res) => {
      if (res.success) {
        GetData();
      }
      loader(false);
    });
  };
  const [Loader, setLoader] = useState(false);
  const CommonModalOpener = (data, type = "Add") => {
    if (type == "Add") {
      setLoader(new Date());
      setdata({ ...{} });
      setview(false);
      document.getElementById("modalopener").click();
    } else if (type == "Edit") {
      setdata(data);
      setview(false);
      document.getElementById("modalopener").click();
    } else if (type == "View") {
      setdata(data);
      setview(true);
      document.getElementById("exampleModalNoticeView").click();
    } else {
    }
  };

  useEffect(() => {
    GetData();
    // Get contractId from the params if get open the add notice modal
    const contractId = methodModel.getPrams('contract')
    if(contractId){
      CommonModalOpener({}, "Add")
    }
  }, []);

  const AcceptRejectorHandler = (id, status = "accepted") => {
    ApiClient.put(`notice/acceptReject`, { id: id, status: status }).then(
      (res) => {
        if (res.success) {
          GetData();
          toast.success(`Notice ${status} successfully`);
        }
      }
    );
  };
  const [IsView, setIsView] = useState("my");

  const ViewOptionsHandler = (type = "my") => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 2000);
  };
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className=" gapmob mb-3">
          {" "}
          <div className="">
            <button
              className={`btn btn-${
                IsView == "my" ? "primary" : "secondary"
              } mr-2`}
              onClick={(e) => {
                setIsView("my");
                GetData({
                  type:
                    user.role && user.role.id == environment.EmployerId
                      ? "employer"
                      : "virtual",
                });
                ViewOptionsHandler("my");
              }}
            >
              My Notice
            </button>
            <button
              className={`btn btn-${IsView == "my" ? "secondary" : "primary"}`}
              onClick={(e) => {
                setIsView("recieved");
                GetData({
                  type:
                    user.role && user.role.id == environment.EmployerId
                      ? "virtual"
                      : "employer",
                });
                ViewOptionsHandler("rec");
              }}
            >
              Recieved Notice
            </button>
          </div>
          <span>
            <button
              className="btn btn-primary mb-3"
              onClick={(e) => CommonModalOpener({}, "Add")}
            >
              Add Notice
            </button>
          </span>
        </div>
        <div className={`table-responsive ${total > 10 ? "new-Tbl" : ""} `}>
          {" "}
          <table className="table table-hover table-bordered  main_new ">
            <thead>
              <tr>
                <th className="th_w_limit_cont">Contract</th>
                <th>
                  {user?.role?.id == environment.VirtualId
                    ? "Employer Name"
                    : "VA Name"}
                </th>
                <th className="th_w_limit">Subject</th>
                <th className="th_w_limit">
                  <span className="dcs">Message</span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => (
                <tr key={index}>
                  <td>
                    {" "}
                    <span className="not_tab_elipsis">
                      {entry?.projectDetail?.name}
                    </span>
                  </td>
                  <td>
                    {" "}
                    <span className="not_tab_elipsis">
                      {user?.role?.id == environment.VirtualId
                        ? entry?.employerDetail?.fullName
                        : entry?.virtualDetail?.fullName}
                    </span>{" "}
                  </td>
                  <td>
                    {" "}
                    <span className="not_tab_elipsis">{entry.title}</span>{" "}
                  </td>
                  <td className="text-truncate">
                    {" "}
                    <span className="not_tab_elipsis">
                      {parse(`${entry.description}`)}
                    </span>
                  </td>
                  <td>
                    {entry.status == "accepted" ||
                    entry.status == "rejected" ? (
                      <button
                        className="btn btn-success text-capitalize"
                        disabled
                      >
                        {entry.status}
                      </button>
                    ) : (
                      <>
                        {/* <i className='fa fa-pen text-info' onClick={e=>CommonModalOpener(entry,"Edit")}></i>| */}
                        <i
                          className="fa fa-eye text-success"
                          onClick={(e) => CommonModalOpener(entry, "View")}
                        ></i>

                        {IsView == "recieved" &&
                        entry.status == "pending" &&
                        user?.role?.id != environment.VirtualId ? (
                          <>
                            <i
                              onClick={(e) =>
                                AcceptRejectorHandler(entry.id, "accepted")
                              }
                              className="fa fa-check mx-2 text-success"
                            ></i>

                            <i
                              onClick={(e) =>
                                AcceptRejectorHandler(entry.id, "rejected")
                              }
                              className="fa mx-2 fa-times text-danger"
                            ></i>
                          </>
                        ) : null}
                      </>
                    )}
                  </td>
                </tr>
              ))}
                {entries && entries?.length == 0 ? (
         <tr>
          <td colSpan="5">
          <div className="text-center text-danger w-100  mx-auto d-flex justify-content-center">No Data</div>
          </td>
         </tr>
        ) : null}
            </tbody>
          
          </table>
        </div>
      
      </div>

      <button
        type="button"
        id="modalopener"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModalNotice"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="exampleModalNotice"
        tabindex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalNotice"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {data.id && data.id != "" ? "Edit" : "Add"} Notice
              </h5>
              <button
                type="button"
                ref={ModalCloserRef}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setdata({});
                  setview(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AddEditNotice
                data={data}
                setdata={setdata}
                GetData={GetData}
                Loader={Loader}
                ModalCloserRef={ModalCloserRef}
                id={data?.id}
                view={view}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="exampleModalNoticeView"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModalNoticeView1"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="exampleModalNoticeView1"
        tabindex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalNoticeView1"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                View Notice
              </h5>
              <button
                type="button"
                ref={ModalCloserRefView}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setdata({});
                  setview(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <ViewNotice
                data={data}
                setdata={setdata}
                ModalCloserRef={ModalCloserRefView}
                id={data?.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
