/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-01-30 12:56:21
 * @Last Modified by: Someone
 * @Last Modified time: 2024-03-14 18:22:20
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/pages/Profile/index.js
 */

import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./profile.scss";
import { useDispatch, useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import AddEditEducation from "../../pages/Education/AddEditEducation";
import EducationDetail from "../Education/Educationdetail";
import EmploymentDetail from "../Employment/Employmentdetail";
import AddEditEmployment from "../Employment/AddEditEmployment";
import moment from "moment";
import { toast } from "react-toastify";
import environment from "../../environment";
import AddEditSkills from "../SkillsType/AddEditSkills";
import Portfolio from "../Portfolio";
import Resume from "../Resume";
import parse from "html-react-parser";
import CompanyDetail from "../CompanyDetail";
import VideoModal from "./VideoIntroduction/VideoModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { login_success } from "../../actions/user";
import Header from "../../components/global/header";
import CommonDelete from "../CommonDeleteActiveModal/CommonDelete";
const Profile = () => {
  const [ShowDeleteModal, setShowDeleteModal] = useState("none");
  const [ShowEmployementDelete, setShowEmployemnentDelete] = useState("none");
  const [DeleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();
  let userdata = useSelector((state) => state.user);
  const [user, setuser] = useState({});
  // console.log(user,'=userdata');
  const [SkillsEditID, setSkillsEditID] = useState(null);
  const [UpdateState, setUpdateState] = useState("");
  const [PortfolioEditID, setPortfolioEditID] = useState(null);
  const [ResumeEditID, setResumeEditID] = useState(null);
  const [data, setData] = useState("");
  const [MyResume, setMyResume] = useState("");
  const [time, settime] = useState(new Date());
  const Documentref = useRef();

  // useEffect(()=>{
  //  user=useSelector(state=>state.user)
  // },[time])
  const Navigate = useHistory();
  const gallaryData = () => {
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        setData(res.data);
        loader(true);
        const data = res.data;
        const newdata = {
          ...user,
          ...data,
        };
        dispatch(login_success(newdata));
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      gallaryData();
    }
  }, []);

  useEffect(()=>{
    setuser(userdata)
  },[userdata])

  const [EducationData, setEducationData] = useState([]);
  const [EducationId, setEducationId] = useState(null);

  const [EmploymentData, setEmploymentData] = useState([]);
  const [EmploymentId, setEmploymentId] = useState(null);
  const [EmployeementViewId, setEmployeementViewId] = useState(null);

  const [SkillData, setSkillData] = useState([]);
  const [SkillId, setSkillId] = useState(null);
  const [SkillViewId, setSkillViewId] = useState(null);

  const [EducationViewId, setEducationViewId] = useState(null);

  const GetEducationData = () => {
    loader(true);
    ApiClient.get(`educations`).then((res) => {
      if (res.success) {
        setEducationData(res.data);
        loader(false);
      }
    });
  };

  const GetEmployementData = () => {
    loader(true);
    ApiClient.get(`employments`).then((res) => {
      if (res.success) {
        setEmploymentData(res.data);
        loader(false);
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      GetEducationData();
      GetEmployementData();
    }
    // ViewEducationData()
  }, []);

  const EducationDelete = () => {
    // if (window.confirm("Do you want to delete this")) {
    loader(true);
    ApiClient.delete(`delete?model=education&id=${DeleteId}`).then(
      (response) => {
        if (response.success) {
          toast.success(response.message);
          setShowDeleteModal("none");
          GetEducationData();
        }
      }
    );
    // }
  };

  const EmploymentnDelete = () => {
    // if (window.confirm("Do you want to delete this")) {
    loader(true);
    ApiClient.delete(`delete?model=employment&id=${DeleteId}`).then(
      (response) => {
        if (response.success) {
          setShowEmployemnentDelete("none");
          toast.success(response.message);
          GetEmployementData();
        }
      }
    );
    // }
  };

  //  FOr Closing the modal of Skills
  const CloseSkillModal = () => {
    document.getElementById("closeskillModal").click();
  };

  const ClosePortfolioModal = () => {
    document.getElementById("closeskillModal").click();
    setUpdateState(user);
    setData(user);
  };

  const CloseResumeModal = () => {
    document.getElementById("resumekillModal").click();
  };

  //  for Document
  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [images, setimages] = useState([]);
  const [ProtofolioError, setProtofolioError] = useState(false);
  const [documents, setdocuments] = useState([]);

  //  For Deleting the Document
  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images];
    Documentref.current.value = "";
    array.splice(index, 1);
    setimages(array);
  };

  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        setDocumentUploadLoading(true);
        ApiClient.multiImageUpload("single/documents?modelName=users", {
          file: file,
        }).then((res) => {
          if (res.success) {
            setDocumentUploadLoading(false);
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            setdocuments(new Date());
            // if (files.length == images.length) {
            //   setDocumentUploadLoading(false)
            // }
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };

  const HandleDocumentSubmit = (e) => {
    e.preventDefault();
    if (images.length != 0) {
      loader(true);
      ApiClient.put("edit/profile", { document: images, id: user.id }).then(
        (res) => {
          if (res.success) {
            const data = { ...user, document: images };
            dispatch(login_success(data));
            document.getElementById("closeDocumnet").click();
            toast.success("Documents Submitted Successfully");
          }
          loader(false);
        }
      );
    } else {
      toast.error("Please Upload Documents");
    }
  };

  const ReturnValidYouTube = (url) => {
    // var url = $('#youTubeUrl').val();
    if (!url) return;
    if (url != undefined || url != "") {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        return "https://www.youtube.com/embed/" + match[2];
      } else {
        return "";
        // Do anything for not being valid
      }
    } else {
      return false;
    }
  };

  const [AssessmentData, setAssessmentData] = useState([]);
  const GetAssessmentData = () => {
    loader(true);
    ApiClient.get(`user/assessments/list?userId=${user.id}`).then(
      (response) => {
        if (response.success) {
          setAssessmentData(response.data);
        }
        loader(false);
      }
    );
  };
  useEffect(() => {
    if (user.role && user.role.id == environment.VirtualId) {
      GetAssessmentData();
    }
  }, []);

  const EmployerAddHelper = (rolid, type = "") => {
    if (type == "") {
      //  Here the Direct manager roleid
      if (rolid == environment.DirectManagerId) {
        return !user.directManager_created ||
          user.directManager_created == false
          ? true
          : false;
      }
      //  Here is the Accounting Manager Id
      else if (rolid == environment.AccountManagerId) {
        return !user.accountManager_created ||
          user.accountManager_created == false
          ? true
          : false;
      }
    }
    //  Here we check
    else {
      return !user.accountManager_created ||
        user.accountManager_created == false ||
        !user.directManager_created ||
        user.directManager_created == false
        ? true
        : false;
    }
  };

  useEffect(() => {
    if (
      user &&
      user.loggedIn &&
      user.role &&
      user.role.id == environment.EmployerId &&
      user.lastLogin != "logggedIn"
    ) {
      document.getElementById("openAddManager").click();
    }
  }, []);

  const FirsttimeLoginChanger = (e, type = "") => {
    e.preventDefault();
    const Payload = {
      id: user.id,
      lastLogin: "logggedIn",
      staffIntreset: type == "yes" ? true : false,
    };
    ApiClient.put("edit/profile", Payload).then((res) => {
      if (res.success && type == "yes") {
        const data = {
          ...user,
          lastLogin: "logggedIn",
          staffIntreset: Payload.staffIntreset,
        };
        dispatch(login_success(data));

        Navigate.push("/staff/add");
      }
    });
  };
  return (
    <>
      <Header />
      <CommonDelete
        setShow={setShowDeleteModal}
        show={ShowDeleteModal}
        confirm={EducationDelete}
      />
      <CommonDelete
        setShow={setShowEmployemnentDelete}
        show={ShowEmployementDelete}
        confirm={EmploymentnDelete}
      />
      <div className="">
        <div className="container ">
          {/* <div className="form-group text-center text-info">
      <label for="paymentMethod" >Which Payment method do you want to Choose?</label>
      <div>
      <a className="mr-2">ACH Payment ? </a> <a >Debit/Credit Cards ?</a>
      </div>
      
    </div> */}

          <div className="main_profile_page mt-5 edit-profile-page">
          <div className="row">
              <div className="col-lg-8 mx-auto">
                {(user.role &&
                  user.role.id == environment.EmployerId &&
                  user.document == null &&
                  user.isVerifiedDocument == "pending") ||
                (user.role &&
                  user.role.id == environment.EmployerId &&
                  user.isVerifiedDocument == "pending" &&
                  user.document &&
                  user.document?.length == 0) ? (
                  <p className="text-danger text-center mt-2 ">
                    Please upload the verification document to get started with
                    the process of verification.{" "}
                  </p>
                ) : null}
                {user?.document &&
                user.document?.length > 0 &&
                user.role &&
                user.role.id == environment.EmployerId &&
                user.isVerifiedDocument != "accepted" &&
                user.isVerifiedDocument == "pending" ? (
                  <p className="text-danger text-center mt-2">
                    Thanks for uploading the document, Your account is under
                    review and verification process will be completed shortly.
                  </p>
                ) : null}

                {(user.role &&
                  user.role.id == environment.VirtualId &&
                  user.document == null &&
                  user.isVerifiedDocument == "pending") ||
                (user.role &&
                  user.role.id == environment.VirtualId &&
                  user.isVerifiedDocument == "pending" &&
                  user.document &&
                  user.document?.length == 0) ? (
                  <p className="text-danger text-center mt-2">
                    Please upload the verification document to get started with
                    the process of verification.{" "}
                  </p>
                ) : null}
                {user?.document &&
                user.document?.length > 0 &&
                user.role &&
                user.role.id == environment.VirtualId &&
                user.isVerifiedDocument != "accepted" &&
                user.isVerifiedDocument == "pending" ? (
                  <p className="text-danger text-center mt-2 ">
                    Thanks for uploading the document, Your account is under
                    review and verification process will be completed shortly.
                  </p>
                ) : null}
                {/* This is For Recruiter */}
                {user.role &&
                user.role.id == environment.RecruiterId &&
                user.isVerifiedDocument == "pending" ? (
                  <p className="text-danger text-center mt-2">
                    Your profile is under Review,Wait for some time.{" "}
                  </p>
                ) : null}

                {user.isVerifiedDocument == "rejected" ? (
                  <div className="text-center text-danger">
                    <p style={{ fontSize: "17px" }}>
                      {" "}
                      Reason for Rejection: {user.reason}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <h3 className="ViewUser mb-4">Basic Information</h3>
              </div>
            </div>
            <div className="row mb-4">
              {user.role && user.role.id == environment.VirtualId ? (
                <div className="col-md-4">
                  <div className="education">
                    <div className="education_name">
                      <div className="mb-2">
                        <h5 className="education_heading">
                          <span className="span_changes">Upload Documents</span>
                        </h5>
                      </div>
                      <div className="d-flex ">
                        <div
                          className="add_icon"
                          data-toggle="modal"
                          data-target="#documents"
                        >
                          <i className="material-icons new_icons">mode_edit</i>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {user.document &&
                        user.document.map((item, index) => (
                          <div key={item} className="col-4">
                            <p className="text-capitalize d-flex">
                              <img
                                style={{ cursor: "pointer" }}
                                className="document_image w-100 img-new-u"
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                onClick={(e) =>
                                  window.open(
                                    `${environment.api}images/users/${item}`
                                  )
                                }
                              />
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                  <hr />
                  <div className="education_name">
                    <div className="mb-2">
                      <h5 className="education_heading">
                        <span className="span_changes">Skills</span>
                      </h5>
                    </div>

                    <div className="d-flex ">
                      {user.hardSkills && user.hardSkills.length == 0 ? (
                        <div
                          className="add_icon"
                          onClick={(e) => setSkillsEditID(null)}
                          data-toggle="modal"
                          data-target="#skillsAdd"
                        >
                          <i className="material-icons new_icons">
                            add_circle{" "}
                          </i>
                        </div>
                      ) : (
                        <div
                          className="add_icon"
                          data-toggle="modal"
                          data-target="#skillsAdd"
                          onClick={(e) => setSkillsEditID(new Date())}
                        >
                          <i className="material-icons new_icons">mode_edit</i>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="multi_skills">
                    {user.hardSkills &&
                      user.hardSkills.map((item, index) => (
                        <div key={item.label} className="education_name_news">
                          <div>
                            <div className="headings">
                              <h5 className="tital_heading text-capitalize">{item?.label || item?.name}</h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    <div className="text-right">
                      {user.hardSkills &&
                      user.hardSkills.length > 0 &&
                      user.role &&
                      user.role.id == environment.VirtualId ? (
                        <button
                          onClick={(e) => Navigate.push("/assessments")}
                          className="btn btn-primary startTest p-1 pl-2 pr-2 mb-1"
                          style={{ cursor: "pointer" }}
                        >
                          Start Test
                        </button>
                      ) : null}
                    </div>
                    <hr />
                    <div className="mb-3">
                      <h5 className="education_heading">
                        <span className="span_changes"> Soft Skills</span>
                      </h5>
                    </div>
                    {user.softSkills &&
                      user.softSkills.map((item, index) => (
                        <div key={item} className="education_name_news">
                          <div>
                            <div className="headings">
                              <h5 className="tital_heading text-capitalize">{item?.label || item?.name}</h5>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <hr />
                  {user.role && user.role.id == environment.VirtualId ? (
                    <div className=" ">
                      <div>
                        <div className="d-flex mb-2 align-items-center">
                          <h5 className="education_heading">
                            <span className="span_changes mb-0">Education</span>
                          </h5>
                          <div
                            className="add_icon"
                            onClick={(e) => setEducationId(null)}
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            <i className="material-icons new_icons_add">
                              add_circle
                            </i>
                          </div>
                        </div>
                        <div className="multi_skills">
                          {EducationData.length > 0 &&
                            EducationData.map((item, index) => (
                              <div
                                key={item.id}
                                className="education scroll_clas"
                              >
                                <div className="education_name">
                                  <div></div>
                                  <div></div>
                                  <div className="d-flex "></div>
                                </div>
                                <div className="education_name_new">
                                  <div className="data_edit">
                                    <div>
                                      <h5 className="education_heading">
                                        {item.degree}
                                      </h5>
                                    </div>
                                    <div className="edit_studies">
                                      <a
                                        className="  dropdown-toggle afterCls"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className="material-icons">
                                          more_horiz
                                        </span>
                                      </a>
                                      <div className="dropdown-menu">
                                        <div
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#exampleModal"
                                          onClick={(e) =>
                                            setEducationId(item.id)
                                          }
                                        >
                                          <i className="fa fa-edit eye"></i>{" "}
                                          Edit
                                        </div>
                                        <div
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            setDeleteId(item.id);
                                            setShowDeleteModal("block");
                                          }}
                                        >
                                          <i className="fa fa-trash eye"> </i>{" "}
                                          Delete
                                        </div>
                                        <div
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#educationView"
                                          onClick={(e) =>
                                            setEducationViewId(item.id)
                                          }
                                        >
                                          <i
                                            className="fa fa-eye eye"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          View
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {/* <h5 className="education_heading">{item.degree}</h5> */}
                                    <p className="about_school mb-2">
                                      {item.school}
                                    </p>
                                    <p className="about_school text-truncate">
                                      {item.description}
                                    </p>
                                    <p className="about_school_year mt-2">
                                      {moment(item.startDate).format("YYYY")}-
                                      {moment(item.endDateDate).format("YYYY")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <hr />
                        <div className="education">
                          <div className="education_name">
                            <div>
                              <h5 className="education_heading">
                                <span className="span_changes">
                                  Employment History
                                </span>
                              </h5>
                            </div>
                            <div className="d-flex ">
                              <div
                                className="add_icon"
                                onClick={(e) => setEmploymentId(null)}
                                data-toggle="modal"
                                data-target="#employmentAdd"
                              >
                                <i className="material-icons new_icons_add">
                                  add_circle{" "}
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="multi_skills">
                            {EmploymentData?.length > 0 &&
                              EmploymentData.map((item, index) => (
                                <div
                                  key={item.id}
                                  className="education_name_new"
                                >
                                  <div className="data_edit">
                                    <div className="headings">
                                      <h5 className="education_heading">
                                        {item.company}
                                      </h5>
                                      <h5 className="education_heading">
                                        {item.country}
                                      </h5>
                                    </div>
                                    <div className="edit_studies">
                                      <a
                                        className="  dropdown-toggle afterCls"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className="material-icons">
                                          more_horiz
                                        </span>
                                      </a>
                                      <div className="dropdown-menu">
                                        <div
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#employmentAdd"
                                          onClick={(e) =>
                                            setEmploymentId(item.id)
                                          }
                                        >
                                          <i className="fa fa-edit eye"></i>{" "}
                                          Edit
                                        </div>
                                        <div
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            setDeleteId(item.id);
                                            setShowEmployemnentDelete("block");
                                          }}
                                        >
                                          <i className="fa fa-trash eye"> </i>{" "}
                                          Delete
                                        </div>
                                        <div
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#employmentView"
                                          onClick={(e) =>
                                            setEmployeementViewId(item.id)
                                          }
                                        >
                                          <i
                                            className="fa fa-eye eye"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          View
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="about_school_year">
                                    {moment(item.startDate).format("YYYY")}-
                                    {item.currentlyWorking
                                      ? "---"
                                      : moment(item?.endDateDate).format(
                                          "YYYY"
                                        )}
                                  </p>
                                  <p className="about_school text-truncate">
                                    {item.description}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                        <hr />
                        <div className="education ">
                          <div className="education_name">
                            <div>
                              <h5 className="education_heading">
                                <span className="span_changes">
                                  Video Introduction
                                </span>
                              </h5>
                            </div>
                            <div className="d-flex ">
                              <div
                                className="add_icon"
                                data-toggle="modal"
                                data-target="#videoAdd"
                              >
                                {user.introLink == "" &&
                                user.introVideo == "" ? (
                                  <i
                                    onClick={(e) => setUpdateState(new Date())}
                                    className="material-icons new_icons_add"
                                  >
                                    add_circle{" "}
                                  </i>
                                ) : (
                                  <i className="material-icons new_icons">
                                    mode_edit
                                  </i>
                                )}
                              </div>
                            </div>
                          </div>
                          {user.introVideo == "" &&
                          user.introLink == "" ? null : (
                            <div className="multi_skills">
                              <div className="">
                                <div className="">
                                  <div className="about_school">
                                    <label className="form-label mt-3">
                                      Video Url
                                    </label>
                                    <div>
                                      {!user.introLink ||
                                      user.introLink == "" ? null : (
                                        <iframe
                                          width="343"
                                          height="130"
                                          src={`${ReturnValidYouTube(
                                            user.introLink
                                          )}`}
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen={true}
                                        ></iframe>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mt-3">
                                    <label>Video</label>
                                    <div>
                                      {!user.introVideo ||
                                      user.introVideo == "" ? null : (
                                        <video
                                          className="video_style w-100"
                                          width={300}
                                          height={120}
                                          src={
                                            environment.api +
                                            "images/videos/" +
                                            user.introVideo
                                          }
                                          controls
                                        />
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <hr />
                        <div className="education mt-4">
                          <div className="education_name">
                            <div>
                              <h5 className="education_heading">
                                <span className="span_changes">
                                  Assessment Results
                                </span>
                              </h5>
                            </div>
                          </div>
                          {AssessmentData.map((item, index) => (
                            <div className="d-flex justify-content-between align-items-center border-bottom mb-2 pb-2">
                              <span>
                                {item.assesmentDetail &&
                                  item.assesmentDetail.name}{" "}
                              </span>
                              <span>
                                Score <b>{Math.floor(item.percentage)}%</b>
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="col-md-8 mx-auto">
                <div className="image-flex">
                  <img
                    src={methodModel.userImg(data && data.image)}
                    className="profileImage"
                  />
                  <Link
                    to="/profile/edit"
                    className="btn btn-primary profiles_edit"
                    title="Edit Profile"
                  >
                    Edit Profile
                  </Link>
                </div>
                <div className="right_profile mt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="label_profile">Name</label>
                      <div>
                        <p className="profile_data">{data && data.fullName}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="label_profile">Email</label>
                      <div>
                        <p className="profile_data">{data && data.email}</p>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <label className="label_profile">Role</label>
                      <div>
                        <p className="profile_data">{data && data.role.name}</p>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <label className="label_profile">TimeZone</label>
                      <div>
                        <p className="profile_data">
                          {(data && data.timeZone && data.timeZone.label) || "---"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <label className="label_profile">Address</label>
                      <div>
                        <p className="profile_data">{(data && data.address) || "---"}</p>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1"> 
                      <label className="label_profile">Country </label>
                      <div>
                        <p className="profile_data">
                          {(data && data.country) || "---"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <label className="label_profile">Mobile No</label>
                      <div>
                        <p className="profile_data">{(data && data.mobileNo) || "---"}</p>
                      </div>
                    </div>
                    {user.role &&
                    user.role.id != environment.VirtualId ? null : (
                      <>
                        <div className="col-md-6 mt-1">
                          <label className="label_profile">Experience</label>
                          <div>
                            <p className="profile_data text-capitalize">
                              {data && data.experience}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 mt-1">
                          <label className="label_profile">HourlyRate</label>
                          <div>
                            <p className="profile_data">
                              {data && data.hourlyRate} USD
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {user.role && user.role.id == environment.VirtualId ? (
                      <>
                        {" "}
                        <div className="col-md-12 mt-1">
                          <label className="label_profile">Description</label>
                          <div>
                            <p className="profile_data">
                              {data && data.description}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                {user.role && user.role.id == environment.VirtualId ? (
                  <>
                    <div className="right_profile mt-3">
                      <div className="row">
                        <div className="col-md-12 text-right position-relative pr-4">
                          <div
                            className=""
                            data-toggle="modal"
                            data-target="#Portfoliomodal"
                          >
                            <i className="material-icons new_icons position-absolute">
                              mode_edit
                            </i>
                          </div>
                        </div>
                        <h5 className="education_heading border-bottom pb-3 ">
                          Portfolio
                        </h5>

                        {user.portfolioUrl != "" ? (
                          <div className="col-md-12 mt-2">
                            <label className="label_profile">
                              PortfolioUrl
                            </label>
                            <div>
                              <p className="profile_data">
                                {user && user.portfolioUrl}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {user.portfolioImage &&
                        user.portfolioImage.length != 0 ? (
                          <div className="col-md-12 mt-1">
                            <label className="label_profile">
                              {" "}
                              Portfolio Documents
                            </label>
                            <div>
                              <p className="profile_data">
                                {user.portfolioImage != "" &&
                                  user.portfolioImage.map((item, index) => (
                                    <img
                                      key={item}
                                      style={{ cursor: "pointer" }}
                                      width={40}
                                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                      onClick={(e) =>
                                        window.open(
                                          `${environment.api}images/document/${item}`
                                        )
                                      }
                                    />
                                  ))}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="right_profile mt-3">
                      <div className="row">
                        <div className="col-md-12 text-right position-relative pr-4">
                          <div
                            className=""
                            data-toggle="modal"
                            data-target="#resumemodal"
                          >
                            <i className="material-icons new_icons position-absolute">
                              mode_edit
                            </i>
                          </div>
                        </div>
                        <h5 className="education_heading border-bottom pb-3">
                          Resume
                        </h5>

                        {user.resume != "" ? (
                          <div className="col-md-12 mt-3">
                            <div>
                              <p className="profile_data">
                                {parse(`${user && user.resume}`)}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {user.resumeDocument != "" ? (
                          <div className="col-md-12 mt-1">
                            <label className="label_profile">
                              Resume Document
                            </label>
                            <div>
                              <p className="profile_data">
                                <img
                                  style={{ cursor: "pointer" }}
                                  width={40}
                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                  onClick={(e) =>
                                    window.open(
                                      `${environment.api}images/document/${user.resumeDocument}`
                                    )
                                  }
                                />
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : user.role && user.role.id == environment.EmployerId ? (
                  <>
                    <CompanyDetail />
                  </>
                ) : null}
              </div>
            </div>

          
          </div>
        </div>
      </div>

      {/* modal delete EducationId modal*/}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom d-flex align-items-center">
              <h5 className="modal-title ">
                {" "}
                {EducationId ? "Edit" : "Add"} Education
              </h5>
              <button
                type="button"
                className="close closeicon position-static"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AddEditEducation
                id={EducationId}
                GetEducationData={GetEducationData}
              />
            </div>
          </div>
        </div>
      </div>
      {/* end delete */}

      {/* --------------------------------------------------- ------*/}

      {/* modal delete EmploymentId modal*/}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 pt-0 pb-0">
              <button
                type="button"
                className="close closeicon"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AddEditEducation
                id={EmploymentId}
                GetEmployementData={GetEmployementData}
              />
            </div>
          </div>
        </div>
      </div>
      {/* end delete EmploymentId*/}

      {/* --------------------------------------------------- ------*/}

      {/* modal education addedit*/}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 pt-0 pb-0">
              <button
                type="button"
                className="close closeicon"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AddEditEducation
                id={EducationId}
                GetEducationData={GetEducationData}
              />
            </div>
          </div>
        </div>
      </div>
      {/* end education */}

      {/* --------------------------------------------------- ------*/}

      {/* modal education view*/}

      <div
        className="modal fade edu-modal"
        id="educationView"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="educationViewLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg " role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h3 className="modal-title ">Education</h3>
              <button
                id="closeEducationmodal"
                type="button"
                className="close "
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <EducationDetail Viewid={EducationViewId} />
            </div>
          </div>
        </div>
      </div>

      {/* end educationView */}

      {/* --------------------------------------------------- ------*/}

      {/* modal employment add*/}

      <div
        className="modal fade"
        id="employmentAdd"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="employmentAddLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom">
              <h5 className="modal-title">
                {EmploymentId ? "Edit" : "Add"} Employment{" "}
              </h5>
              <button
                type="button"
                id="closeEmploymentmodal"
                className="close closeicon position-static"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AddEditEmployment
                id={EmploymentId}
                GetEmployementData={GetEmployementData}
              />
            </div>
          </div>
        </div>
      </div>

      {/* end education*/}

      {/* --------------------------------------------------- ------*/}

      {/* end employmentView */}

      <div
        className="modal fade edu-modal"
        id="employmentView"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="employmentViewLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h3 className="modal-title">Employeement Detail</h3>
              <button
                type="button"
                className="close "
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <EmploymentDetail ViewId={EmployeementViewId} />
            </div>
          </div>
        </div>
      </div>

      {/* end employmentView*/}

      {/* --------------------------------------------------- ------*/}

      {/* modal skills add*/}
      <div
        className="modal fade"
        id="skillsAdd"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="skillsAddLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom ">
              <h5 className="modal-title ">
                {SkillsEditID ? "Edit" : "Add"} Skill
              </h5>

              <button
                type="button"
                id="closeskillModal"
                className="close closeicon position-static"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AddEditSkills
                skillsEdit={SkillsEditID}
                CloseSkillModal={CloseSkillModal}
              />
            </div>
          </div>
        </div>
      </div>
      {/* end skillsadd*/}

      {/* --------------------------------------------------- ------*/}

      {/* modal Portfolio add*/}
      <div
        className="modal fade edu-modal"
        id="Portfoliomodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="PortfoliomodalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md " role="document">
          <div className="modal-content">
            <div className="modal-header  border-bottom">
              
                <h3 className="modal-title mb-0">Portfolio</h3>
              
              <button
                type="button"
                id="closePortfolio"
                className="close "
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Portfolio
                portfolioEditID={PortfolioEditID}
                ClosePortfolioModal={ClosePortfolioModal}
              />
            </div>
          </div>
        </div>
      </div>
      {/* end Portfolio*/}

      {/* ---------------------------------------------------------*/}

      <div
        className="modal  fade  upload_doc "
        id="documents"
        tabIndex="-1" 
        role="dialog"
        aria-labelledby="ResumemodalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom  d-flex">
              <h5 className="modal-title" id="PortfoliomodalLabel">
                Upload Document
              </h5>
              <button
                type="button"
                id="closeDocumnet"
                className="close  "
                data-dismiss="modal"
                aria-label="Close"
              >
                

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="">
                  <label className="mb-0" >
                    {user?.role?.id === environment?.EmployerId
                      ? "Company Document"
                      : "National Id"}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className={`profile_btn_portfolio mt-2 `}>
                    <label className="add_portfolio edit ml-3">
                      <input
                        ref={Documentref}
                        id="bannerImage"
                        type="file"
                        multiple={true}
                        className="d-none"
                        // accept="image/*"
                        onChange={(e) => imageResult(e)}
                      />
                      <span className="add_portfolio">
                        <i className="material-icons add_port">add</i>
                      </span>
                    </label>
                  </div>
                </div>
                <div>
                 
                  {/* <div className="mt-1">
                    <p>Example: {user.role && user.role.id == environment.EmployerId ? "(W-8, W-9 forms)" : "(INE, CARNET, PASSPORT, ETC)"}</p>
                  </div> */}

                  <div className="imagesRow ml-3">
                    {DoumentUploadLoading == true ? (
                      <div className="text-success  d-flex justify-content-end w-100 mb-4">
                        Uploading... <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      images?.map((item, index) => (
                        <div key={index}>
                          <p className="text-capitalize position-relative mb-0 ">
                            <img
                              style={{ cursor: "pointer" }}
                              width={40}
                              className="document_image"
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                              onClick={(e) =>
                                window.open(
                                  `${environment.api}images/users/${item}`
                                )
                              }
                            />
                            <i
                              className="fa fa-times-circle text-danger close_doc"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => HanldDocumentDelete(e, index)}
                            ></i>


                          </p>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                {ProtofolioError ? (
                  <div className="text-danger text-center mt-3 ml-5">
                    Please enter Url or Upload Documents{" "}
                  </div>
                ) : null}
              </div>
             
            </div>
            <div className="text-right  modal-footer border-top ">
                <button
                  className="btn btn-primary"
                  disabled={DoumentUploadLoading == true}
                  onClick={(e) => HandleDocumentSubmit(e)}
                >
                  Submit
                </button>
              </div>
          </div>
        </div>
      </div>
      {/* modal resume add*/}
      <div
        className="modal fade edu-modal"
        id="resumemodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ResumemodalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex border-bottom align-items-center  ">
              <h3 className="modal-title mb-0">Resume </h3>

              <button
                type="button"
                id="closeResume"
                className="close "
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Resume
                setResume={setMyResume}
                Resume={MyResume}
                ResumeEditID={ResumeEditID}
                settime={settime}
                CloseResumeModal={CloseResumeModal}
              />
            </div>
          </div>
        </div>
      </div>
      {/* end resume*/}

      {/* -------------------------------------------------- */}
      <div
        className="modal fade video_intro"
        id="videoAdd"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header border-bottom">
              <div className="ViewUser mb-0" id="exampleModalLabel">
                {" "}
                Video Introduction
              </div>
              <button
                type="button"
                className="close closeicon position-static"
                id="closeVideomodal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <VideoModal date={UpdateState} />
          </div>
        </div>
      </div>

      {/* delete modale */}

      <button
        type="button"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">Do you want to delete this</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary d-none"
        id="openAddManager"
        data-toggle="modal"
        data-target="#exampleModalAddmanager"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade staff-modal"
        id="exampleModalAddmanager"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <img src="assets/img/staff.png" />
              <h5 className="text-center"> Do you Want to Add Your Staff? </h5>
              <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={(e) => FirsttimeLoginChanger(e)}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  data-dismiss="modal"
                  onClick={(e) => FirsttimeLoginChanger(e, "yes")}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
