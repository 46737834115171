import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ApiClient from '../../../methods/api/apiClient';
import environment from '../../../environment';
import moment from 'moment';
import { toast } from 'react-toastify';
import convertTimezone from '../../../components/common/TimeZoneConvertor/TimeZoneConvert';
import TextArea from 'antd/es/input/TextArea';
import methodModel from '../../../methods/methods';
import Select from "react-select";
import dayjs from 'dayjs';
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import Pagination from "react-js-pagination";

const TrackingTable = forwardRef(({ id, data, EmployerData, VAData, ConfirmFunction, contractData, Hidebuttons = false, childRef = null }) => {
  const history = useHistory()
  const user = useSelector(state => state.user);
  const urlPath = window.location.pathname
  // const [TimeTableSchedule, setTimeTableSchedule] = useState(data);
  // const [editableItems, setEditableItems] = useState([]);
  // const [editedStartTime, setEditedStartTime] = useState('');
  // const [editedEndTime, setEditedEndTime] = useState('');

  const [contracts, setContracts] = useState([])
  const [total, setTotal] = useState(0)

  const { startDate, endDate } = methodModel.getCurrentWeekDates();
  const today = endDate; // Get today's date
  const tomorrow = startDate; // Get tomorrow's date
  const [SelectedDateRange, setSelectedDateRange] = useState(null);
  const [filters, setFilters] = useState({ page: 1, count: 10, status: { value: "pending", label: "Requested" }, contractId: id })

  const requestStatus = [
    { value: "pending", label: "Requested" },
    { value: "accepted", label: "Approved" },
    { value: "rejected", label: "Declined" },
  ]

  // useEffect(() => {
  //   setTimeTableSchedule(data);
  // }, [data])

  // useEffect(() => {
  //   if (Hidebuttons) {
  //     ApiClient.get('billing/list', { contractId: id }).then(res => {
  //       setTimeTableSchedule(res.data)
  //     })
  //   }
  // }, [Hidebuttons])

  // const userid = user.role && user.role.id == environment.EmployerId ? user.id : user.addedBy
  // const [BillingData, setBillingData] = useState([]);
  // const [TotalLength, setTotalLength] = useState(0);

  const [NewChanegsData, setNewChangesData] = useState([]);
  const ChangesScheduleGet = (p = {}) => {
    const userid = user.role.id == environment.EmployerId ? user.id : user.addedBy
    const filter = { ...filters, ...p, employerId: userid }
    ApiClient.get(`billing_schedules`, filter).then(res => {
      if (res.success) {
        setNewChangesData(res.data)
        setTotal(res?.total)
      }
    })
  }

  useEffect(() => {
    getAllContracts()
  }, [])

  const getAllContracts = () => {
    const employerId = user?.role?.id == environment.EmployerId ? user?.id : ""
    const directmanagerId = user?.role?.id == environment.DirectManagerId ? user?.id : ""
    ApiClient.get(`contracts?status=accepted&addedBy=${employerId}&directmanagerId=${directmanagerId}`).then(res => {
      if (res.success) {
        setContracts(res?.data?.map((item) => {
          return ({ value: item?.id || item?._id, label: item?.contractName || item?.project_details?.name || item?.project_details?.title })
        }))
      }
    })
  }

  useEffect(() => {
    if (user.role && user.role.id == environment.EmployerId || user.role.id == environment.DirectManagerId) {
      ChangesScheduleGet({ ...filters, status: filters?.status?.value || "", contractId: id ? filters?.contractId : filters?.contractId?.value || "" })
    }
  }, [filters])

  // const [Tab, setTab] = useState('Ch')
  // useEffect(() => {
  //   document.getElementById(`menu1`).click()
  // }, [])
  // const [changedData, setChangedData] = useState([])
  // const handleEdit = (index) => {
  //   setEditedStartTime(BillingData[index].startTime);
  //   setEditedEndTime(BillingData[index].endTime || '');
  //   setEditableItems((prev) => [...prev, index]);
  // };
  // const handleSave = (index) => {
  //   // Handle saving the edited start and end times to your data source or state
  //   // In this example, I'm logging the edited times to the console


  //   // Check if the data is already in the changedData array
  //   const existingIndex = changedData.findIndex((item) => item.id === BillingData[index].id);

  //   // If not, push the changed data to the new array
  //   if (existingIndex === -1) {
  //     setChangedData((prev) => [...prev, BillingData[index]]);
  //   } else {
  //     // If exists, update the data in the array
  //     setChangedData((prev) => {
  //       const updatedData = [...prev];
  //       updatedData[existingIndex] = BillingData[index];
  //       return updatedData;
  //     });
  //   }

  //   // After saving, remove the item from editableItems
  //   setEditableItems((prev) => prev.filter((item) => item !== index));
  // };
  useImperativeHandle(childRef, () => ({
    ChildAcceptReject: (e, status) => {
      HandleAcceptReject(e, status)
    }
  }))

  const HandleAcceptReject = (e, itm, status = "accepted") => {
    e.preventDefault()
    const payload = [{ id: itm?.id || itm?._id, status: status }]
    // const payload = NewChanegsData.map((item) => ({ id: item?._id, status: status }))
    ApiClient.put(`billing_schedule/acceptReject`, { data: payload }).then(res => {
      if (res.success) {
        ApiClient.put(`contract`, { id: itm?.contractId, billingRequest: false }).then(res1 => {
          // ConfirmFunction()
        })
        ChangesScheduleGet()

        if (status == 'accepted') {
          const newpayload = NewChanegsData.map((item) => ({
            "id": item?.billingDetail?._id || item?.billingDetail?.id,
            "memo": item?.billingData?.memo,
            "startTime": item?.billingData?.startTime,
            "endTime": item?.billingData?.endTime
          }))
          ApiClient.put(`updateBilling`, { data: newpayload }).then(Res => {
            if (Res.success) {
              // ConfirmFunction()
            }
          })
        }
        else {
          // ConfirmFunction()
        }

        toast.success(`Billing Time ${status == "accepted" ? "Accepted" : "Rejected"} successfully`)
      }
    })
  }

  const calculateDuration = (startDateStr, endDateStr) => {
    if (startDateStr && endDateStr) {
      // Parse the input date-time strings to Date objects
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      // Calculate the difference in milliseconds
      const differenceInMillis = endDate - startDate;

      // Convert milliseconds to minutes
      const differenceInMinutes = Math.floor(differenceInMillis / 60000);

      // Calculate hours and minutes
      const hours = Math.floor(differenceInMinutes / 60);
      const minutes = differenceInMinutes % 60;

      // Format the output as HH:MM
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    } else {
      return "00:00"
    }
  }

  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    setFilters((prev) => ({ ...prev, startDate: dates[0] || "", endDate: dates[1] || "" }))
  };
  const HandleApporoveReject = (e, itm, status = "accepted") => {
    e.preventDefault()
    const payload = [{ id: itm?.id || itm?._id, status: status }]
    // const payload = NewChanegsData.map((item) => ({ id: item?._id, status: status }))
    ApiClient.put(`billing_schedule/acceptReject`, { data: payload }).then(res => {
      if (res.success) {
        ApiClient.put(`contract`, { id: itm?.contractId, billingRequest: false }).then(res1 => {
          // ConfirmFunction()
        })
        ChangesScheduleGet()

        if (status == 'accepted') {
          const newpayload = NewChanegsData.map((item) => ({
            "id": item?.billingDetail?._id || item?.billingDetail?.id,
            "memo": item?.billingData?.memo,
            "startTime": item?.billingData?.startTime,
            "endTime": item?.billingData?.endTime
          }))
          ApiClient.put(`updateBilling`, { data: newpayload }).then(Res => {
            if (Res.success) {
              // ConfirmFunction()
            }
          })
        }
        else {
          // ConfirmFunction()
        }

        toast.success(`Billing Time ${status == "accepted" ? "Accepted" : "Rejected"} successfully`)
      }
    })
  }


  return (
    <div>
      {/* <div className="container">
        <ul className="nav nav-tabs main_tabs my-5 ">
          <li className='mr-3' onClick={e => setTab('Pr')} ><a className={`text-${Tab == "Pr" ? "" : "dark"} text_box`} data-toggle="tab" href={`#home${id}`}>Previous</a></li>
          <li onClick={e => setTab('Ch')}><a className={`text-${Tab == "Ch" ? "info" : "dark "} text_greens`} data-toggle="tab" id={`previoustab${id}`} href={`#menu1${id}`}>New Changes</a></li>
        </ul>

      </div> */}
      <div className='container'>
        <div className="tab-content">
          <div>
            {/* <div id={`home${id}`} className="tab-pane fade in active">
            <h4 className='text-center mb-4'>Previous Clock entry</h4>
            <div className='tbsectionsd mai_text'>
              <div className='table-resposnive'>
                <table className="table table-striped table-bordered">
                  <thead className='table_head table-primary'>
                    <tr className='heading_row'>
                      <th scope="col" className='table_data'>Memo</th>
                      <th scope="col" className='table_data'>Start Date&Time</th>
                      <th scope="col" className='table_data'>End Date&Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TimeTableSchedule?.map((item, index) => (
                      <tr className='data_row' key={index} >
                        <td className='table_dats'>{item.memo}</td>
                        <td className='table_dats'>
                          {moment(new Date(user.role == environment.EmployerId ? convertTimezone(item?.startTime, contractData?.timeZone?.value, user?.timeZone?.value) : item.startTime)).format('DD MMM, YYYY hh:mm a')}
                        </td>
                        <td className='table_dats'>
                          {item.endTime && item.endTime !== '' ? moment(new Date(user.role == environment.EmployerId ? convertTimezone(item.endTime, contractData?.timeZone?.value, user?.timeZone?.value) : item.endTime)).format('DD MMM, YYYY hh:mm a') : '----'}
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>


          </div> */}
          </div>
          <div>
            <h4 className='text-center mb-4 mt-4'>Modifications In Time Clock Entry</h4>
            <div className='d-flex'>
              <div className='mr-4'>
                <Select
                  value={filters?.status}
                  onChange={e => setFilters((prev) => ({ ...prev, status: e }))}
                  options={requestStatus}
                />
              </div>
              {urlPath == "/timeclock/requests" &&
                <>
                  <div className='ml-4'>
                    <RangePicker
                      placeholder={["Start Date", "End Date"]}
                      format="MM-DD-YYYY"
                      onChange={handleDateChange}
                      value={SelectedDateRange}
                      className="p-2"
                    />
                  </div>
                  <div className='ml-4'>
                    <Select
                      value={filters?.contractId}
                      onChange={e => setFilters((prev) => ({ ...prev, contractId: e }))}
                      options={contracts}
                      placeholder="Select Contract"
                      className='text-capitalize'
                      isClearable={true}
                    />
                  </div>
                </>}
            </div>
            <div>
              {/* <div className='tbsectionsd mai_text'>
              <div className='table-responsive'>
                <table className="table table-hover  table-bordered">
                  <thead className='table_head table-primary'>
                    <tr className='heading_row'>
                      <th scope="col" className='table_data'>Reason</th>
                      <th scope="col" className='table_data'>Start Date&Time</th>
                      <th scope="col" className='table_data'>End Date&Time</th>
                      <th scope='col' className='table_data'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {NewChanegsData.map((item, index) => (
                      <tr className='data_row' key={index} >
                        <td className='table_dats'>{item?.billingData?.reason || "--"}</td>
                        <td className='table_dats'>
                          {moment(new Date(item?.billingData.startTime)).format('DD MMM, YYYY hh:mm a')}
                        </td>
                        <td className='table_dats'>
                          {item?.billingData.endTime && item?.billingData.endTime !== '' ? moment(new Date(item?.billingData.endTime)).format('DD MMM, YYYY hh:mm a') : '----'}
                        </td>
                        <td className='table_dats'>
                          {Tab == "Ch" && !Hidebuttons ? <div className=''>
                            <div style={{ float: "right" }} className=''>
                              <button className='btn btn-danger mr-3' onClick={e => HandleAcceptReject(e, item, "rejected")}>Reject</button>
                              <button className='btn btn-primary' onClick={e => HandleAcceptReject(e, item, "accepted")}>Accept</button>
                            </div>
                          </div> : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div> */}
            </div>
            {NewChanegsData?.length == 0 && <div className='text-danger text-center mt-4'>No Records Found</div>}

            {NewChanegsData && NewChanegsData?.map((item, index) => {
              return <section className='time_entry pt-4 mt-4' key={index}>
                <div className='form-row align-items-center'>
                  <div className='col-lg-6 pr-3'>
                    <div className='form-row'>
                      <div className='col-lg-4'>
                        <div className='d-flex gap-2 flex-column justify-content-center  align-items-center'>
                          {item?.virtualDetail?.image ?
                            <img src={methodModel.userImg(item?.virtualDetail?.image)} className='rounded-circle mr-1' width={50} height={50} alt="image" />
                            :
                            <div className='img_name'>{item?.virtualDetail?.fullName || item?.virtualDetail?.firstName}</div>
                          }
                          <h3 className='userNnames'>{item?.virtualDetail?.fullName || item?.virtualDetail?.firstName}</h3>
                          {item?.isAdd ? null : <h3 className='edit_shift'>Edit Shift</h3>}
                          {/* <h3 className='user_chat'><i class="fa fa-commenting  messagess" aria-hidden="true"></i>
                          Chat with user</h3> */}
                        </div>
                      </div>
                      {item?.isAdd ? null :
                        <div className='col-lg-8'>
                          <h5 className='deletion_requst mb-3'>Original Shift:</h5>
                          <div className='ddddetailstme'>
                            <div className='ddtimes'>
                              <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                                <span>{item?.billingDetail?.startTime ? methodModel.convertDate(item?.billingDetail?.startTime) : "--"}</span>
                                <span>{item?.billingDetail?.endTime ? methodModel.convertDate(item?.billingDetail?.endTime) : "--"}</span>
                              </div>
                              <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                                <span className='redtime'>{item?.billingDetail?.startTime ? methodModel.ConvertTime(item?.billingDetail?.startTime) : "--"}</span>
                                <span className=''><i class="fa fa-arrow-right" aria-hidden="true"></i>
                                </span>
                                <span className='redtime' >{item?.billingDetail?.endTime ? methodModel.ConvertTime(item?.billingDetail?.endTime) : "--"}</span>
                              </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center gap-3 set_timess' >
                              <span className='small_redtime'>({calculateDuration(item?.billingDetail?.startTime || "", item?.billingDetail?.endTime || "")} hours)</span>
                              <span className='ligt_texttime'>{item?.billingDetail?.memo || "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='col-lg-6 text-end  border-left ps-3'>
                    <div className='d-flex justify-content-between gap-3  all_greenbx'>
                      <div className=''>
                        <h5 className='deletion_requst mb-3'>{item?.isAdd ? "Added Manual" : "Edit requested"}:</h5>
                        <div className='ddddetailstme'>
                          <div className='ddtimes'>
                            <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                              <span>{item?.billingData?.startTime ? methodModel.convertDate(item?.billingData?.startTime) : "--"}</span>
                              <span>{item?.billingData?.endTime ? methodModel.convertDate(item?.billingData?.endTime) : "--"}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                              <span className='redtime'>{item?.billingData?.startTime ? methodModel.ConvertTime(item?.billingData?.startTime) : "--"}</span>
                              <span className=''><i class="fa fa-arrow-right" aria-hidden="true"></i>
                              </span>
                              <span className='redtime'>{item?.billingData?.endTime ? methodModel.ConvertTime(item?.billingData?.endTime) : "--"}</span>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess' >
                            <span className='small_redtime'>({calculateDuration(item?.billingData?.startTime || "", item?.billingData?.endTime || "")} hours)</span>
                            <span className='ligt_texttime'>{item?.billingData?.memo || "--"}
                            </span>
                          </div>
                          <div className='d-flex flex-column gap-2 rests text-left'>
                            <h3 className='userNnames my-0'>Reason</h3>
                            <h3 className='userNnames my-0 clocktime'>{item?.billingData?.reason || "--"}</h3>
                          </div>
                          <div className='d-flex flex-column gap-2 rests text-left'>
                            <h3 className='userNnames my-0'>Contract Name</h3>
                            <h3 className='userNnames my-0 clocktime'>{item?.contractDetail?.contractName || item?.contractDetail?.title || item?.projectDetail?.title || "--"}</h3>
                          </div>
                        </div>
                      </div>
                      <div className='btn_aproves'>
                        {item?.status == "pending" ?
                          <>
                            <button className='aprove' onClick={e => HandleAcceptReject(e, item, "accepted")}><i class="fa fa-check-circle colorcheck" aria-hidden="true"></i>Approve </button>
                            <button className='Decline' onClick={e => HandleAcceptReject(e, item, "rejected")}><i class="fa fa-times-circle colorwrong" aria-hidden="true"></i>Decline </button>
                          </>
                          :
                          <div className='text-capitalize'>Status: <b>{item?.status == "accepted" ? "Approved" : "Declined"}</b></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            })}

            {total > filters.count ? (
              <Pagination
                activePage={filters.page}
                totalItemsCount={total}
                itemsCountPerPage={filters?.count}
                hideFirstLastPages
                onChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
              />
            ) : null}
            <div>
              {/* <section className='time_entry pt-4   mt-4 '>
              <div className='form-row align-items-center'>
                <div className='col-lg-6 pr-3'>
                  <div className='form-row'>
                    <div className='col-lg-4'>
                      <div className='d-flex gap-2 flex-column justify-content-center  align-items-center'>
                        <div className='img_name'>AC</div>
                        <h3 className='userNnames' >Vishal</h3>
                        <h3 className='edit_shift' >Shift Deletation</h3>
                        <h3 className='user_chat' > <i class="fa fa-commenting  messagess" aria-hidden="true"></i>
                          Chat with user</h3>

                      </div>
                    </div>
                    <div className='col-lg-8'>
                      <h5 className='deletion_requst mb-3' >Original Shift</h5>
                      <div className='ddddetailstme'>
                        <div className='ddtimes'>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                            <span className='' >08/04</span>
                            <span className='' >08/04</span>


                          </div>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                            <span className='redtime' >06:40 PM</span>
                            <span className='' > <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <span className='redtime' >06:40 PM</span>

                          </div>
                        </div>

                        <div className='d-flex justify-content-between align-items-center gap-3 set_timess' >
                          <span className='small_redtime' >(00:35 hours)</span>
                          <span className='ligt_texttime' >Vancanza Stays
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 text-end  border-left ps-3'>
                  <div className='d-flex justify-content-between gap-3  all_greenbx'>
                    <div className=''>
                      <h5 className='deletion_requst mb-3' >Edit requested:</h5>
                      <div className='ddddetailstme'>
                        <div className='ddtimes'>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                            <span className='' >08/04</span>
                            <span className='' >08/04</span>


                          </div>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                            <span className='redtime' >06:40 PM</span>
                            <span className='' > <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <span className='redtime' >06:40 PM</span>

                          </div>
                        </div>

                        <div className='d-flex justify-content-between align-items-center gap-3 set_timess' >
                          <span className='small_redtime' >(00:35 hours)</span>
                          <span className='ligt_texttime' >Vancanza Stays
                          </span>
                        </div>
                        <div className='d-flex flex-column gap-2 rests text-left' >
                          <h3 className='userNnames my-0' >Reason</h3>
                          <h3 className='userNnames my-0 clocktime' >text time clock</h3>
                        </div>

                      </div>
                    </div>
                    <div className=' btn_aproves'>
                      <button className='aprove'> <i class="fa fa-check-circle colorcheck" aria-hidden="true"></i>
                        Approve </button>
                      <button className='Decline'> <i class="fa fa-times-circle colorwrong " aria-hidden="true"></i>
                        Decline </button>
                    </div>

                  </div>
                </div>
              </div>

            </section>

            <section className='time_entry pt-4   mt-4 border-top '>
              <div className='form-row align-items-center'>
                <div className='col-lg-6'>
                  <div className='form-row'>
                    <div className='col-lg-4'>
                      <div className='d-flex gap-2 flex-column justify-content-center  align-items-center'>
                        <div className='img_name'>AC</div>
                        <h3 className='userNnames' >Vishal</h3>
                        <h3 className='edit_shift' >Shift Deletation</h3>
                        <h3 className='user_chat' > <i class="fa fa-commenting  messagess" aria-hidden="true"></i>
                          Chat with user</h3>

                      </div>
                    </div>
                    <div className='col-lg-8 border-right pe-3'>
                      <h5 className='deletion_requst mb-3' >Original Shift</h5>
                      <div className='ddddetailstme'>
                        <div className='ddtimes'>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                            <span className='' >08/04</span>
                            <span className='' >08/04</span>


                          </div>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                            <span className='redtime' >06:40 PM</span>
                            <span className='' > <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <span className='redtime' >06:40 PM</span>

                          </div>
                        </div>

                        <div className='d-flex justify-content-between align-items-center gap-3 set_timess' >
                          <span className='small_redtime' >(00:35 hours)</span>
                          <span className='ligt_texttime' >Vancanza Stays
                          </span>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 text-end  ps-3'>
                  <div className='d-flex justify-content-between gap-3  all_greenbx'>
                    <div className=''>
                      <h5 className='deletion_requst mb-3' >Edit requested:</h5>
                      <div className='ddddetailstme'>
                        <div className='ddtimes'>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                            <span className='' >08/04</span>
                            <span className='' >08/04</span>


                          </div>
                          <div className='d-flex justify-content-between align-items-center gap-3 set_timess'>
                            <span className='redtime' >06:40 PM</span>
                            <span className='' > <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <span className='redtime' >06:40 PM</span>

                          </div>
                        </div>

                        <div className='d-flex justify-content-between align-items-center gap-3 set_timess' >
                          <span className='small_redtime' >(00:35 hours)</span>
                          <span className='ligt_texttime' >Vancanza Stays
                          </span>
                        </div>

                        <div className='d-flex flex-column gap-2 rests text-left' >
                          <h3 className='userNnames my-0' >Reason</h3>
                          <h3 className='userNnames my-0 clocktime' >text time clock</h3>
                        </div>
                      </div>
                    </div>
                    <div className=' btn_aproves'>
                      <button className='aprove'> <i class="fa fa-check-circle colorcheck" aria-hidden="true"></i>
                        Approve </button>
                      <button className='Decline'> <i class="fa fa-times-circle colorwrong " aria-hidden="true"></i>
                        Decline </button>
                    </div>

                  </div>
                </div>
              </div>

            </section> */}
            </div>
            {/* <div className="table-responsive mt-5 pt-md-3">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="h3fontit text-center">Manual Added Time Clock Entries</h3>
              </div>

              <div className="table_section mt-1">

                <table className="table table-striped">
                  <thead className="table_head">
                    <tr className="heading_row">
                      <th scope="col" className="table_data">
                        Memo
                      </th>
                      <th scope="col" className="table_data">
                        Start Date & Time
                      </th>
                      <th scope="col" className="table_data">
                        End Date & Time
                      </th>
                      <th scope="col" className="table_data">
                        Action
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr className="data_row">
                      <td className="table_dats limit_dats">
                        Test
                      </td>
                      <td className="table_dats">
                        01 Aug, 2024 09:30 am
                      </td>
                      <td className="table_dats">
                        01 Aug, 2024 10:00 am
                      </td>
                      <td className="table_dats">
                        <div className='btn_aproves row'>
                          <button className='aprove' onClick={e => HandleApporoveReject(e, item, "accepted")}> <i class="fa fa-check-circle colorcheck" aria-hidden="true"></i>
                            Approve </button>
                          <button className='Decline' onClick={e => HandleApporoveReject(e, item, "rejected")}> <i class="fa fa-times-circle colorwrong " aria-hidden="true"></i>
                            Decline </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div> */}

          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  )
})

export default TrackingTable;