import { useState, useRef } from 'react';
import ApiClient from '../../methods/api/apiClient';
import './style.scss';
import environment from '../../environment';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';

const Describe = ({ setCount, setMyForm, MyForm }) => {
  const [Submitted, setSubmitted] = useState(false);
  const OpenDocumentRef = useRef();
  const [AILoader, setAILoader] = useState(false);
  //  for Document 
  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [images1, setimages1] = useState(MyForm.documents);
  const [ProtofolioError1, setProtofolioError1] = useState(false);
  // const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  //  For Deleting the Document
  const HanldDocumentDelete = (e, index) => {
    e.preventDefault();
    const array = [...images1];
    array.splice(index, 1);
    setimages1(array);
    setMyForm({ ...MyForm, documents: array })

  };

  const imageResult = (e) => {
    if (e.target.files && e.target.files.length > 0) {

      const files = e.target.files;
      const newdata = new FormData();
      let newarray = images1;
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        setDocumentUploadLoading(true);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          file: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            newarray.push(path);
            original.push(path);
            setdocuments(new Date())
            if (files.length == images1.length) {
              setDocumentUploadLoading(false)
            }
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;

      }
      setProtofolioError1(false);
      setdocuments(newarray);
      setimages1(newarray);
      setMyForm({ ...MyForm, documents: newarray })
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }

  };
  const HandleNextPage = (e) => {
    setSubmitted(true);
    e.preventDefault()
    if (MyForm.description == "") return false;
    setCount(4)

  }

  const HandleAIFunction = async () => {
    setAILoader(true);
    const header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${'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'}`,
    }
    const payload = {
      messages: [
        { "role": "user", "content": `Provide me a job description base on the following data for posting it on the platform where the others can see  on the behalf of these skills ${MyForm?.skillsName.toString()}` }
      ],
      max_tokens: 800,
      temperature: 1,
      model: 'gpt-3.5-turbo',
      n: 1,
      stop: null,
    }

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        payload,
        {
          headers: header,
        }
      );
      setAILoader(false)
      // Assuming the response format is the same as GPT-3, extract the text
      setMyForm({ ...MyForm, description: response.data.choices[0].message.content })
    } catch (error) {
      setAILoader(false)
      console.error('Error in GPT-4 request:', error);
      throw error;
    }
  }
  return (
    <>
      <div className="bg-white pt-4 overflow-set ">
        <div className="container pl-5 pr-5 mt-5">
          <div className="row ">
            <div className="col-lg-5">
              <p>Job Post</p>
              <h2>Start the conversation.</h2>
              <p>Talent are looking for:</p>
              <ul className='pl-3'>
                <li className='mb-1'>Clear expectations about your task or deliverables</li>
                <li className='mb-1'>The skills required for your work</li>
                <li className='mb-1'>Good communication</li>
                <li className='mb-1'>Details about how you or your team like to wrok</li>
              </ul>
            </div>
            <div className="col-lg-7 fonttext">
              <b>Describe what you need</b>
              {/* <textarea  cols='4' rows='8' value={MyForm.description} onChange={e=>setMyForm({...MyForm,description:e.target.value})} className='form-control mt-2 mb-4 '></textarea> */}
              <div className="mt-2">
                <Editor
                  apiKey={environment.tinyKey}
                  value={MyForm.description}
                  onEditorChange={(newValue, editor) => {
                    setMyForm({
                      ...MyForm,
                      description: newValue,
                    })
                  }}


                />
              </div>
              <div className='d-flex mt-3 justify-content-between'>
                <div><button className='btn  btn-primary d-flex align-items-center border-0 text-black' onClick={e => OpenDocumentRef.current.click()}> <span className="material-icons me-2 attachment">attachment</span>Attach file</button>
                <p className='mt-2 text-danger fx-normal'>Max file size: 100 MB</p>
                </div>
                <div>
                  <button disabled={AILoader} className='btn btn-primary aibtn' onClick={e => HandleAIFunction()}>{AILoader ? <img src='/assets/img/loader.gif' className='loadgif' /> : "Use AI"}</button>
                  {Submitted == true && MyForm.description == "" ? <div className='text-danger text-center'>Please fill the Detail first</div> : null}

                </div>

              </div>
              <div className="">
                <div className="">
                  <div className={`profile_btn_portfolio d-none `}>
                    <label className="add_portfolio edit ml-3">
                      <input
                        id="bannerImage"
                        type="file"
                        multiple={true}
                        ref={OpenDocumentRef}
                        className="d-none"
                        // accept="image/*"
                        onChange={(e) => imageResult(e)}
                      />
                      <span className="add_portfolio">
                        <i className="material-icons add_port">add</i>
                      </span>
                    </label>
                  </div>
                </div>


                <div>
                 
                  <div className="imagesRow d-flex flex-wrap">
                    {DoumentUploadLoading == true ? (
                      <div className="text-success">
                        Uploading...{" "}
                        <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      images1.map((item, index) => (
                        <div>
                          <p className="text-capitalize">
                            <img
                              style={{ cursor: "pointer" }}
                              width={40}
                              className="document_image"
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                              onClick={(e) =>
                                window.open(
                                  `${environment.api}images/document/${item}`
                                )
                              }
                            />
                            <i
                              className="fa fa-trash text-danger shadow-danger delet_icon"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                HanldDocumentDelete(e, index)
                              }
                            ></i>
                          </p>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              {/* {Submitted==true&&MyForm.documents.length<=0?<div className='text-danger text-center'>Document is Required</div>:null} */}
            
            </div>
          </div>
        </div>
      </div>
      <div className="footer_btn">
        <button className="btn btn-outline-primary" onClick={e => setCount(2)}>Back</button>
        <button className="btn btn-primary" onClick={e => HandleNextPage(e)} >Review Job Post</button>
      </div>

    </>
  );
};

export default Describe;