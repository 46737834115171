import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login_success } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import { toast } from "react-toastify";
import environment from "../../environment";
import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from 'react-facebook-login';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const history = useHistory();
  const [SignUpRole, setSignUpRole] = useState("employer");
  const user = useSelector((state) => state.user);
  const [SocialRole, setSocialRole] = useState("employer");
  const [captcha, setcaptcha] = useState(false);

  const onChange = (value) => {
    if (value) {
      setcaptcha(true);
    } else {
      return false;
    }
  };
  // useEffect(() => {
  //   if (localStorage.getItem('token')) {
  //     user.role && user.role.id == environment.EmployerId ? history.push('/virtual') : history.push("/profile")
  //   }
  // }, [])

  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");

  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 500);

    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }
  }, []);

  const hendleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      password,
    };
    if (captcha != true) {
      return toast.error("Please verify Captcha");
    }

    loader(true);

    ApiClient.post("user/signin", data).then((res) => {
      loader(false);
      if (res.success) {
        if (remember) {
          localStorage.setItem("remember", JSON.stringify(data));
        } else {
          localStorage.removeItem("remember");
        }

        localStorage.setItem("token", res.data.access_token);
        dispatch(login_success(res.data));
        const newdata = res.data;
        localStorage.setItem("userid", newdata.id);
        if (
          newdata.role &&
          newdata.role.id == environment.VirtualId &&
          newdata.isVerifiedDocument == "accepted"
        ) {
          history.push("/projectslist");
        } else {
          if (newdata.role && newdata.role.id == environment.EmployerId) {
            history.push("/virtual");
          } else {
            history.push("/profile");
          }
        }
      }
    });
  };

  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  useEffect(() => {
    if (id) {
      loader(true);
      localStorage.clear();
      ApiClient.post("autoLogin", { id: id }).then((response) => {
        if (response.success) {
          localStorage.setItem("token", response.data.access_token);
          dispatch(login_success(response.data));
          toast.success(response.message);
          const newdata = response.data;
          // if (newdata.role && newdata.role.id == environment.VirtualId && newdata.isVerifiedDocument == "accepted") {
          history.push("/set/password");
          // }
          // else {
          //   history.push('/set/password');
          // }
        }
        loader(false);
      });
    }
  }, []);

  const HandleGoogleLogin = useGoogleLogin({
    onSuccess: (e) => {
      fetchUserInfo(e.access_token);
    },
  });
  const [userId, setUserId] = useState("");

  const fetchUserInfo = async (accessToken) => {
    // Assuming you have an access token in the response
    // const accessToken = response.tokens.access_token;
    // Use the access token to fetch user information
    const userInfoResponse = await fetch(
      "https://www.googleapis.com/oauth2/v2/userinfo",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const userInfo = await userInfoResponse.json();
    const payload = {
      firstName: userInfo.given_name,
      lastName: userInfo.family_name,
      email: userInfo.email,
    };
    HandleSocialSignup(payload);
  };

  const HandleSocialSignup = (payload) => {
    ApiClient.post("login/signup/socialMedia", payload).then((res) => {
      if (res.success) {
        localStorage.setItem("token", res.data.access_token);
        setUserId(res.data);

        if (!res.data.role || !res.data.alreadyRegistered) {
          document.getElementById("openrole").click();
        } else {
          dispatch(login_success(res.data));
          document.getElementById("closeit").click();
          history.push("/profile");
        }
      }
    });
  };
  const FinalSetup = (e) => {
    e.preventDefault();
    ApiClient.put(`edit/profile`, { id: userId.id, role: SocialRole }).then(
      (res) => {
        if (res.success) {
          dispatch(login_success(res.data));
          document.getElementById("closeit").click();

          history.push("/profile");
        }
      }
    );
  };
  return (
    <>
      <Link className="right_home" to="/">
        <span className="material-icons arrow_right">arrow_back</span> Home
      </Link>
      <div className="main_signup">
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-xl-4 col-lg-6 col-md-8">
        <div className="top_section top-sect-new">
              <div className="right_side pt-4">
                <Link to="/">
                  <div className="logo_image mb-0">
                    <img
                      src="/assets/img/Logo_new.png"
                      loading="lazy"
                      width={"100%"}
                      height={`100%`}
                      className="logo_name mb-2"
                    />
                  </div>
                </Link>

                <div className="text-center mb-2">
                  <h3 className="text-cenetr lgtext">Sign in</h3>
                </div>
                <p className="accopunt text-center">
                  Don’t have an account?{" "}
                  <Link
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="sign_up"
                    to="/signup"
                  >
                    {" "}
                    Sign Up
                  </Link>
                </p>
                {/* <div class="dropdown mt-2 text-center">
                  <button class="btn btn-secondary dropdown-toggle asbt m-auto d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Sign in as
                  </button>
                  <ul class="dropdown-menu asbtshow">
                    <li><a class="dropdown-item" href="#">Employer</a></li>
                    <li><a class="dropdown-item" href="#">Virtual Assistant </a></li>
                    <li><a class="dropdown-item" href="#">Recruiter Sign In</a></li>
                  </ul>
                </div> */}
                <form className="centerLogin mt-3" onSubmit={hendleSubmit}>
                  <div className="mb-3">
                    <label className="ml-1">
                      {" "}
                      Email <span className="red">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control mb-0 bginput changes"
                      placeholder="Email address"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>

                 
                  <div className="mb-3 mt-4">
                  <label className="ml-1">
                    {" "}
                    Password <span className="red">*</span>
                  </label>
                    <div className="inputWrapper mt-0">
                      <input
                        type={eyes.password ? "text" : "password"}
                        className="form-control mb-0 bginput changes padding_lgin"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                      />
                      <i
                        className={
                          eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={() =>
                          setEyes({ ...eyes, password: !eyes.password })
                        }
                      ></i>
                    </div>
                  </div>

                  <div className="forget text-right">
                    <Link to="/forgotpassword" className="">
                      Forgot Password?
                    </Link>
                  </div>

                  <div className="text-center logcaptacha d-flex justify-content-center">
                    <ReCAPTCHA
                      sitekey={environment.Captchasitekey}
                      onChange={onChange}
                    />
                  </div>
                  <div className="mt-0">
                    <button
                      type="submit"
                      className="btn btn-primary loginclass mt-2"
                    >
                      Sign in
                    </button>
                  </div>
                  <div className="borderCls mt-3">
                    <span className="or">or</span>
                  </div>
                  <div className="text-center d-flex justify-content-center mt-4 ">
                    <a
                      className="btn btn-outline google_id "
                      onClick={(e) => HandleGoogleLogin()}
                    >
                      <img
                        src="/assets/img/gogle.png"
                        className="img-fluid google_img"
                        alt=""
                        loading="lazy"
                        width={`100%`}
                        height={`100%`}
                      />
                      <span className="google_heading mx-auto">
                        {" "}
                        Continue with google
                      </span>
                    </a>
                    {/* <a className='btn btn-outline google_id'>
                  <img src='/assets/img/facebooklogo.png' className='img-fluid' alt='' height={`100%`} width={`100%`} />
                  <span className='google_heading'></span>
                </a> */}
                    {/* <FacebookLogin
    appId="1038449510798048"
    // autoLoad={true}
  buttonStyle={{overflow:"hidden",borderRadius:"50%",height:"50px",width:"50px",backgroundColor:"white",padding:"0px",border:"none"}}
  textButton=''
  containerStyle={{objectFit:"cover"}}
    fields="name,email,picture"
    callback={e=>console.log(e,"This is hte facebook response here we get ")} 
    icon={<img src='/assets/img/facebooklogo.png' alt='' height={`100%`} width={`100%`} /> }
  /> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg signup-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Sign up{" "}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-5">
              <div className="d-flex justify-content-center flex-custom">
                <div className="form-check">
                <input
                    className="form-check-input"
                    checked={SignUpRole == "virtual"}
                    onChange={(e) => setSignUpRole("virtual")}
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                <img src = 'assets/img/talent-search.svg' />
                 <div className="d-flex align-items-center justify-content-center">
               
                  <label className="form-check-label" for="flexRadioDefault1">
                    Sign up as Virtual Assistant
                  </label>
                 </div>
                </div>
                <div className="form-check ml-4">
                <img src = 'assets/img/employe-card.svg' />
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    className="form-check-input"
                    checked={SignUpRole == "employer"}
                    type="radio"
                    onChange={(e) => setSignUpRole("employer")}
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Sign up as employer
                  </label>
                 </div>
                </div>
                <div className="form-check ml-4">
                <img src = 'assets/img/male.svg' />
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    className="form-check-input"
                    checked={SignUpRole == "recruiter"}
                    type="radio"
                    onChange={(e) => setSignUpRole("recruiter")}
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Sign up as Recruiter
                  </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                id="closesignupmodal"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  history.push(`/signup?role=${SignUpRole}`);
                  document.getElementById("closesignupmodal").click();
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="openrole"
        className="btn btn-primary d-none "
        data-toggle="modal"
        data-target="#staticBackdrop"
      >
        Launch static backdrop modal
      </button>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                How you will Signup?
              </h5>
              <button
                type="button"
                className="close d-none"
                id="closeit"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    checked={SocialRole == environment.VirtualId}
                    onChange={(e) => setSocialRole(environment.VirtualId)}
                    type="radio"
                    name="role"
                    id="virtual"
                  />
                  <label className="form-check-label" for="virtual">
                    Sign up as Virtual Assistant
                  </label>
                </div>
                <div className="form-check ml-4">
                  <img src = 'assets/img/employe-card.svg' />
                  <input
                    className="form-check-input"
                    checked={SocialRole == environment.EmployerId}
                    type="radio"
                    onChange={(e) => setSocialRole(environment.EmployerId)}
                    name="role"
                    id="employer"
                  />
                  <label className="form-check-label" for="employer">
                    Sign up as employer
                  </label>
                </div>
                <div className="form-check ml-4">
                  <input
                    className="form-check-input"
                    checked={SocialRole == environment.RecruiterId}
                    type="radio"
                    onChange={(e) => setSocialRole(environment.RecruiterId)}
                    name="role"
                    id="recruiter"
                  />
                  <label className="form-check-label" for="recruiter">
                    Sign up as Recruiter
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => FinalSetup(e)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
