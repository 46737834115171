import { useEffect, useState } from "react";
import Header from "../../components/global/header";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import { useSelector } from "react-redux";

export default function ShortList() {
  const Navigate = useHistory();
  const PlatformData = useSelector((state) => state.PlatformfeeReducer);
  //  For Getting All Shortlisted Data
  const [Data, setData] = useState([]);
  const GetData = () => {
    loader(true);
    ApiClient.get(`sortlist/list?type=virtualAssisstant`).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetData();
  }, []);

  //  For Removing the Shortlisted Virtual Assistant
  const HandleRemoveFromShortlist = (e, id) => {
    e.preventDefault();
    loader(true);
    ApiClient.delete(`sortlist?virtualAssisstantId=${id}`).then((res) => {
      if (res.success) {
        GetData();
      }
      loader(false);
    });
  };
  return (
    <div>
      <Header />
      <div className="container mt-4">
        <div className="card">
          <div className="card-header bg-white">
            <h2 className="best_match mb-0">Short List</h2>
          </div>
          {Data.map((item, index) => (
            <div key={item.id} className="col-md-12 mt-5 ">
              <div>
                <div className="d-flex justify-content-between mb-3 flex-lg-col  w-100">
                  <div className="d-flex align-items-center mt-2">
                    <img
                      src={methodModel.userImg(
                        item.virtualAssisstant && item.virtualAssisstant.image
                      )}
                      className="reveleant_user"
                    />
                    <div className="ml-3">
                      <a className="font-weight-bold text-capitalize">
                        {item.virtualAssisstant &&
                          item.virtualAssisstant.fullName}
                        {/* Name */}
                      </a>
                      {/* <p className='mb-1'></p> */}
                      <p className="mt-2 mb-0">
                        <b>
                          {item.virtualAssisstant &&
                            item.virtualAssisstant.country}
                          {/* Country */}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <a
                      className="fav_icon_revealeant"
                      onClick={(e) =>
                        HandleRemoveFromShortlist(e, item.virtualAssisstantId)
                      }
                    >
                      <span className="material-icons fav_fill">favorite</span>
                    </a>
                    <button
                      className="btn btn-primary ml-3"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) =>
                        Navigate.push(
                          `/virtualdetails/${item.virtualAssisstantId}`
                        )
                      }
                    >
                      Invite for a Job
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2 flex-lg-col">
                  <p>
                    {methodModel.CalculatePercentAmount(
                      item.virtualAssisstant &&
                        item.virtualAssisstant.hourlyRate,
                      PlatformData.platformFee
                    ) + item?.virtualAssisstant?.hourlyRate}
                    USD/hr
                  </p>
                  <p>USD 8k+ earned</p>
                  <p className="font-weight-bold">
                    <span className="material-icons shield_icon">shield</span>
                    {item.virtualAssisstantSkilltypeDetail &&
                      item.virtualAssisstantSkilltypeDetail.name}
                  </p>
                </div>
                <p>
                  {item.virtualAssisstant && item.virtualAssisstant.description}
                </p>
                <b> Skills</b>
                <div className="d-flex flex-wrap">
                  {/* <span className='gray_badges'>Good</span> */}
                  {item.virtualAssisstant &&
                    item.virtualAssisstant.skills &&
                    item.virtualAssisstant.skills.map((itm, index) => (
                      <span key={item.label} className="gray_badges">
                        {itm.label}
                      </span>
                    ))}
                </div>

                <hr />
              </div>
            </div>
          ))}
          {Data.length == 0 ? (
            <div className="col-md-12 mt-5 mb-4 pl-5 pr-5 text-danger text-center">
              No Data
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
